/// <reference path="../../stylesheets/main.scss" />

@import 'mainstyles';

.errorDetailTabs {
    height: 100% !important;
    border-style: solid;
    border-width: 0.5px;
    border-color: #999999;
    border-radius: 3px;

    :global {
        .ant-tabs {
            height: 200px;
            width: 100%;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            .ant-tabs-nav {
                background-color: #f0f0f0;

                .ant-tabs-nav-wrap {
                    .ant-tabs-nav-list {
                        [class$="ant-tabs-tab"] {
                            margin: 0px;
                            padding: 10px 20px 10px 20px;

                            .ant-tabs-tab-btn {
                                color: #8c8c8c;
                            }
                        }

                        [class$="ant-tabs-tab ant-tabs-tab-active"] {
                            margin: 0px;
                            padding: 10px 20px 10px 20px;

                            .ant-tabs-tab-btn {
                                color: unset;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }
        }
    }

    .errorTree {
        height: 100%;

        [class*="treeViewNodes"] {
            padding: 0px;
            .popoverTree {
                display: inline-block;
                :global {
                    .ant-tree-list-holder-inner {
                        .ant-tree-treenode {
                            height: 35px;
                            &:hover {
                                background-color: #fff1f0 !important;
                            }
                            .ant-tree-switcher {
                                background-color: unset;
                                margin-top: -2px;
                            }
                            .ant-tree-node-content-wrapper {
                                margin-left: 1px;
                            }
                        }
                    }

                    .ant-tree-list-holder {
                        .ant-tree-list-holder-inner {
                            overflow: hidden;
                            [class*="ant-tree-treenode ant-tree-treenode-switcher-close ant-tree-treenode-leaf-last"],
                            [class*="ant-tree-treenode ant-tree-treenode-switcher-open ant-tree-treenode-leaf-last"] {
                                .ant-tree-node-content-wrapper {
                                    margin-left: 10px;
                                    margin-top: 1px;
                                    font-size: 13px;
                                    transition: unset;
                                    transition: 0;
                                }
                            }
                            [class*="ant-tree-treenode ant-tree-treenode-leaf-last"] {
                                .ant-tree-node-content-wrapper {
                                    margin-left: 10px;
                                    margin-top: 1px;
                                    font-size: 13px;
                                    transition: unset;
                                    transition: 0;
                                }
                            }
                            [class*="ant-tree-treenode ant-tree-treenode-switcher-open ant-tree-treenode-selected ant-tree-treenode-leaf-last"]:nth-of-type(1) {
                                background-color: #fff1f0 !important;

                                .ant-tree-indent {
                                    background-color: #a8071a;
                                    width: 3px;
                                    height: 35px;
                                    margin-top: -4px;
                                    margin-left: -10px;
                                }
                                .ant-tree-node-content-wrapper {
                                    margin-left: 18px;
                                    margin-top: 1px;
                                    font-size: 13px;
                                    color: #a8071a;
                                    font-weight: 600;
                                    transition: unset;
                                    transition: 0;
                                }
                            }

                            [class*="ant-tree-treenode ant-tree-treenode-selected ant-tree-treenode-leaf-last"],
                            [class*="ant-tree-treenode ant-tree-treenode-switcher-open ant-tree-treenode-selected ant-tree-treenode-leaf-last"] {
                                background-color: #fff1f0 !important;

                                .ant-tree-indent {
                                    .ant-tree-indent-unit:nth-of-type(1) {
                                        background-color: #a8071a;
                                        width: 3px;
                                        height: 35px;
                                        margin-top: -4px;
                                        margin-left: -10px;
                                    }
                                }

                                .ant-tree-node-content-wrapper {
                                    margin-left: 34px;
                                    margin-top: 1px;
                                    font-size: 13px;
                                    color: #a8071a;
                                    font-weight: 600;
                                    transition: unset;
                                    transition: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.treeScrollbar {
    [class*="scrollbarTrack_horizontal"] {
        width: 99% !important;
        margin-bottom: -4px;
    }
    [class*="scrollbarTrack_vertical"] {
        margin-top: -4px;
        height: 92%;
    }
}

.treeTextContainer {
    display: flex;
    align-items: center;

    .codeViewPopover {
        padding-right: 0px;

        :global {
            .bcIcon {
                position: relative !important;
                margin-top: -2px;
                margin-left: 2px;
            }
        }
    }

    .treeItemText {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.errorDetailPopover {
    :global {
        .ant-popover-content {
            .ant-popover-inner {
                .ant-popover-title {
                    border-radius: unset !important;
                }
                .ant-popover-inner-content {
                    border: unset !important;
                }
            }
        }
    }

    .popoverTitle {
        width: 100%;
        border-bottom: none;
        font-weight: 600;
        font-size: 16px;
    }

    .popoverContent {
        width: 564px;
        height: 350px;
        :global {
            .bcScrollableArea {
                width: 103% !important;

                .scrollbarTrack_horizontal {
                    display: none !important;
                }
            }
        }
    }
}

.codeViewContainer {
    height: 100%;
    padding: 10px 0px 0px 13px;

    :global {
        .bcScrollableArea {
            .scrollbarTrack_horizontal {
                display: none !important;
            }
        }
    }
}

.codeViewContent {
    margin-right: 20px !important;
    width: 97% !important;

    .popoverLabelTitle {
        font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
        font-weight: 600;
        font-size: 12px;
    }
    .popoverLabelContent {
        font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
        font-size: 12px;
        word-wrap: break-word;
        opacity: 0.7;


        &.stackTraceContent {
            color: #004d8c;

            &.inOrAtContentColor {
                color: #0b6a0b;
            }
            
        }
    }
}