.mobilePickerStyles {
    width: calc(100% - 32px) !important;
}

.buttonGroup {
    width: 100% !important;
}

.suffixButton {
    width: 32px !important;
}