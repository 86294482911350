/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

.rulesCollapsePanel {
    margin: 5px;

    .ruleItem {
        width: 100%;
        float: left;
        transition: all 200ms ease;
        background-color: #f8f9fa;
        border: solid 1px transparent;

        &:hover {
            background-color: #fff;

            .ruleHeader {
                .collapseDeleteIcon {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &.selected {
            background-color: var(--primary-color-o-1) !important;
            border-color: var(--primary-color-6);
        }
    }

    .ruleHeader {
        width: 100%;
        user-select: none;
        float: left;
        padding-right: 4px;

        p {
            font-size: 13px;
            float: left;
            line-height: 40px;
            width: calc(100% - 130px);
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            color: #25241f;
        }

        .ruleSwitch {
            zoom: 0.5;
            transform: rotate(90deg);
            margin: 30px 10px 0 10px;
            float: left;
        }

        .collapseDeleteIcon {
            transform: rotate(0) !important;
            width: 32px;
            height: 32px;
            float: right;
            transition: all 200ms ease;
            margin-top: 4px;
            opacity: 0;
            visibility: hidden;

            &:first-child {
                margin-right: 4px;
            }

            &:hover {
                background-color: #f0f1f2;
            }

            svg {
                width: 16px;
                height: 16px;
                margin: 8px;
            }
        }
    }

    :global {
        .ant-collapse-header {
            padding: 0 !important;
            width: 100%;
            overflow: hidden;
            background: transparent;
            box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);

            &:hover {
                background: transparent;
            }

            &:active {
                background: transparent !important;
            }
        }
    }

    .collapseIcon {
        width: 15px;
        height: 15px;
        transition: all 100ms ease;
        float: left;
        margin: 21.5px 0 9.5px 15px;

        @include themify($themes) {
            color: themed("collapseHeaderTextColor");
        }

        svg {
            vertical-align: top;
        }
    }
}

.editContainer {
    padding: 10px;
    float: left;
    width: 100%;
}

.actionListHeader {
    margin-bottom: 10px;
    width: 100%;
    text-align: right;
}

.actionsCollapsePanel {
    :global {
        .ant-collapse-item {
            margin-bottom: 0;

            .ant-collapse-header {
                background: #f8f9fa;
                border-bottom: solid 1px #ececec;
                height: 40px;

                .bcIcon {
                    transform: rotate(0) !important;
                }
            }

            &.ant-collapse-item-active {
                .ant-collapse-header {
                    .bcIcon {
                        transform: rotate(90deg) !important;
                    }

                    .collapseDeleteIcon {
                        transform: rotate(0) !important;
                    }

                    p {
                        color: var(--primary-color-6);
                    }
                }
            }
        }
    }

    .actionListItem {
        width: 100%;
        float: left;
        transition: all 200ms ease;
        border-left: solid 5px transparent;
        height: 40px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);

            .actionHeader {
                .collapseDeleteIcon {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }

        &:active {
            background-color: rgba(0, 0, 0, 0.1);
        }

        /*&.validation {
            border-left: solid 5px #EB3941;
        }*/
    }

    .actionHeader {
        width: calc(100% - 35px);
        padding: 0 0 0 10px !important;
        user-select: none;
        float: left;
        line-height: 40px !important;
        border-radius: 10px;

        span {
            font-size: 12px;
            color: #555;
            opacity: 0.7;
            font-weight: lighter;
            line-height: 40px;
            margin-left: 10px;
        }

        :global {
            .ant-input-affix-wrapper {
                width: 160px;
                height: 34px;
                border: none;
                outline: none;

                .ant-input {
                    background: transparent;
                    outline: none;
                    border: none !important;
                    font-size: 12px;

                    @include themify($themes) {
                        color: themed("collapseHeaderTextColor");
                    }
                }
            }
        }

        p {
            font-size: 12px;
            font-weight: 600;
            float: left;
            line-height: 40px;
            max-width: 60%;
            overflow: hidden;
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin: 0;
            color: #25241f;
        }

        .collapseDeleteIcon {
            transform: rotate(0) !important;
            width: 16px;
            height: 16px;
            float: right;
            transition: all 100ms ease;
            margin-top: 12px;
            opacity: 0;
            visibility: hidden;
            margin-left: 5px;

            @include themify($themes) {
                color: themed("collapseHeaderTextColor");
            }

            &:hover {
                color: var(--primary-color-6);
            }

            svg {
                vertical-align: top;
            }
        }
    }

    :global {
        .ant-collapse-header {
            padding: 0 !important;
            width: 100%;
            overflow: hidden;
        }
    }

    .collapseIcon {
        width: 10px;
        height: 40px;
        transition: all 100ms ease;
        float: left;
        margin: 0 0 0 5px;

        @include themify($themes) {
            color: themed("collapseHeaderTextColor");
        }

        svg {
            vertical-align: top;
        }
    }

    :global {
        .label {
            font-weight: normal;
            color: #25241f;
            line-height: 30px;
        }
    }
}

.actionTreeView {
    width: 100%;
    overflow: hidden;
    margin: 5px 5px 15px 5px;

    .actionItem {
        padding-left: 30px;
        width: 100%;
        float: left;
        position: relative;
        margin-top: 5px;

        &:before {
            content: "";
            position: absolute;
            top: -5px;
            left: 15px;
            height: 100%;
            border-left: solid 1px #ddd;
        }

        .property {
            span {
                color: #999;
                display: inline-block;
                line-height: 26px;
                font-style: italic;

                b {
                    font-weight: bold;
                    color: #777;
                    font-style: normal;
                }
            }

            &>b {
                color: var(--primary-color-6);
                font-weight: normal;
                display: inline-block;
                line-height: 26px;
            }

            .deleteIcon {
                float: right;
                cursor: pointer;
                opacity: 0;
                visibility: hidden;
                transition: all 100ms ease;

                :global {
                    .bcIcon {
                        width: 26px;
                        height: 26px;
                    }
                }
            }

            .arrowIcon {
                transform: rotate(180deg);
                width: 14px;
                height: 26px;
                display: inline-block;
                margin: 0 10px;
                color: #777;
            }
        }

        .control {
            color: #999;
            font-weight: bold;
            line-height: 26px;
        }
    }

    .actionItemContent {
        margin-bottom: 5px;
        width: 100%;
        float: left;
        transition: all 100ms ease;
        padding: 5px;
        border-radius: 5px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            background: var(--primary-color-1);

            .deleteIcon {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.addActionButton {
    width: 100%;
    margin: 0 5px;
}

.newRuleButton {
    margin-bottom: 10px;
    padding: 0 18px;
    overflow: hidden;
    background: #f6f6f6;
    border-top: solid 1px #eee;

    h3 {
        font-size: 14px;
        color: #555;
        line-height: 40px;
        display: inline-block;
        font-weight: bold;
        margin: 0;
    }

    :global {
        .ant-btn {
            float: right;
            margin-top: 8px;
        }
    }
}

.editPanelContent {
    width: 100%;
    height: calc(100% - 92px);
}

.editPanelCollapse {
    height: 100%;

    &> :global(.ant-collapse-item) {
        height: 40px;
    }

    :global {
        .ant-collapse-item {
            transition: height 200ms ease;

            .bcIcon {

                &.expandBtn,
                &.collapseBtn {
                    width: 15px;
                    height: 15px;
                    float: left;
                    transition: all 100ms ease;
                    margin-top: 12.5px;
                    margin-left: 12.5px;

                    svg {
                        vertical-align: top;
                    }
                }

                &.expandBtn {
                    display: block;
                    opacity: 0.5;
                }

                &.collapseBtn {
                    display: none;
                    opacity: 0.5;
                }
            }

            .ant-collapse-content {
                height: calc(100% - 40px);

                .ant-collapse-content-box {
                    height: 100%;
                    padding: 0;
                }
            }
        }
    }

    &.conditionsCollapsed {
        :global {
            .ant-collapse-item:last-child {
                height: calc(100% - 40px);
            }
        }
    }

    &.actionsCollapsed {
        :global {
            .ant-collapse-item:first-child {
                height: calc(100% - 40px);
            }
        }
    }

    &.bothExpanded {
        :global {
            .ant-collapse-item {
                height: 50%;
            }
        }
    }
}

.doneButton {
    border-right: solid 1px #ddd !important;
    padding-right: 15px;
    display: flex;
    align-items: center;
}

.editPanelFooter {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-rows: 1fr;
    grid-row-gap: 5px;
    align-items: center;
    padding: 0px 15px;
    width: 100%;
    background: #f6f6f6;
    z-index: 1;
    position: relative;
    border-top: solid 1px #ececec;
    border-bottom: solid 2px #ececec;

    .label {
        line-height: 40px;
        display: inline-block;
        font-size: 12px;
        font-weight: normal;
        color: #25241f;
        grid-area: 1 / 1 / 2 / 2;
    }

    .cascader {
        grid-area: 1 / 2 / 2 / 3;
    }
}

.clearButton {
    width: 35px;
    font-weight: 900;
    font-size: 14px;
    float: left;
    position: absolute;
}

.emptyTemplateDesc {
    padding: 20px;
    font-size: 12px !important;
}

.lastPropertyKey {
    font-weight: bold;
}

.stackBarContentViewerHeader {
    width: 100%;
    padding-left: 16px;
    height: 50px;
    display: flex;
    align-items: center;

    @include themify($themes) {
        border-bottom: solid 1px themed("collapseHeaderBorderColor");
    }

    .stackBarHeaderCommandButtonsContainer {
        margin: 0 25px 0 auto;
        float: right;

        .stackBarButton {
            display: block;
            float: left;
            width: 16px;
            height: 16px;
            cursor: pointer;
            transition: all 100ms ease-out;

            :global {
                .bcIcon {
                    transition: color 200ms ease;

                    @include themify($themes) {
                        color: themed("dockerHeaderControlColor");
                    }

                    svg {
                        vertical-align: top;
                    }
                }
            }

            &:hover {
                :global {
                    .bcIcon {
                        color: var(--primary-color-6);
                    }
                }
            }

            &.pin {
                &.pinned {
                    transform-origin: center;
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .contentEditableTitle {
        background: transparent;
        height: 50px;
        padding: 0;
        margin: 0;
        border: none !important;
        outline: none !important;
        box-shadow: none;
        font-size: 12px;
        width: calc(100% - 145px);
        font-weight: bold;

        @include themify($themes) {
            color: themed("dockerTabTextColor");
        }
    }
}

.addButton {
    border: none;
    box-shadow: none !important;
    width: 24px;
    height: 26px !important;
    padding: 0 2px;
    float: left;

    :global {
        .bcIcon {
            height: 26px;
        }
    }
}

.addButton {
    :global {
        .bcIcon {
            width: 15px;
        }
    }
}

.collapseHeader {
    width: 100%;
    height: 40px;
    user-select: none;
    background-color: #f6f6f6;

    :global {
        .ant-btn {
            margin-top: 8px;
            margin-right: 15px;
            float: right;
        }
    }

    p {
        float: left;
        max-width: 80%;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #25241f;
        margin-left: 17px;
        margin-top: 12px;

        @include themify($themes) {
            color: themed("collapseHeaderTextColor");
        }
    }
}

.selectConfirmActions {
    width: 100%;
}

.conditionValueInput {
    width: calc(100% - 30px);
    float: left;
}

.conditionValueFormulaBtn {
    width: 30px;
    float: left;
}

.labelCaptionStyles {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.eventListTooltipElementStyle {
    margin: 0px;
}

.selectedFieldDivider {
    margin: 0 !important;
}

.selectedTagSpan {
    overflow: unset !important;
}

.overlayRuleManager {
    filter: opacity(0.5);
    pointer-events: none;
}