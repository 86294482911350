.errorBoundary {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .somethingsWentWrongContainerStyle {
        height: 135px;
        .somethingsWentWrongStyle {
            width: 150px;
        }
    }
    .collapse {
        width: 100%;
        max-width: 700px;
        padding: 15px;
        .scrollbars {
            width: 100%;
            height: 300px !important;
            .details {
                white-space: pre-wrap;
            }
        }
    }
}
