.OverflowButton {
}

.ButtonBarPopoverMenu {
    :global {
        .ant-popover-content {
            .ant-popover-inner {
                .ant-popover-inner-content {
                    display: table-caption;
                    padding: 12px 0px;
                    .ant-btn {
                        width: 100%;
                        text-align: left;
                        padding-left: 16px;
                        padding-right: 16px;
                        display: inline-flex;
                        vertical-align: middle;
                        align-items: center;
                        &:hover {
                            background-color: #efefef !important;
                        }
                        .bcIcon {
                            margin-right: 13px;
                        }
                    }
                    .ant-divider-horizontal {
                        margin: 12px 0;
                    }
                }
            }
        }
    }
}

.ButtonBarVerticalDivider {
    height: 34px;
    margin: 0 6px 0 6px;
}

.ButtonBarItems,
.ButtonBarFarItems {
    display: flex;
}

.ButtonBarIcon {
    :global {
        .bcIcon {
            padding: 2px !important;
            margin: 0px !important;
        }
    }
    &.TextExist {
        padding-top: 4.5px;
        color: rgb(37, 36, 31);
    }
}
