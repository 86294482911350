/// <reference path="../../stylesheets/main.scss" />

@import "mainstyles";

ul.paginationContainer {
  width: 100%;
  // margin: 17px 0;
  padding: 0;
  float: left;
  overflow: hidden;
  height: 46px;
  height: 100%;
  margin: 0px;
  display: flex;
  align-items: center;

  li.paginationItem {
    list-style-type: none;
    float: left;
    user-select: none;
    // border-radius: 4px;
    display: flex;

    @include breakpoint(sm) {
      color: unset !important;
    }

    &:last-of-type {
      i.arrow {
        // display: none;
        visibility: hidden;
      }
    }

    span {
      background-color: transparent !important;
      float: left;
      font-weight: 600;
      font-size: 12px;
      // border-radius: 8px;
      line-height: 46px;
      height: 46px;
      padding: 0 6px;
      cursor: pointer;
      transition: all 100ms $transition-timing-function;
      overflow: hidden;
      max-width: 120px;
      text-overflow: ellipsis;
      white-space: nowrap;
      opacity: 0.6;

      @include breakpoint(sm) {
        color: unset !important;
      }

      @include themify($themes) {
        // color: themed('paginationItemColor');
        color: rgba(95, 94, 90, 1);
      }

      &:hover {
        background-color: #e0dfdd !important;
      }

      &:active {
        transform: scale(0.9);
        color: var(--primary-color-6) !important;
      }
    }

    .closeIcon {
      width: 28px;
      height: 46px;
      display: flex;
      visibility: hidden;
      opacity: 0;
      transition: visibility 0.1s, opacity 0.1s linear;
      margin-left: auto;
      right: 0;
      padding: 0 10px;
      position: absolute;

      &:hover {
        color: var(--primary-color-6);
        cursor: pointer;
        background-color: #fff !important;
      }
      &:active {
        background-color: #ccc !important;
      }
    }

    &.hasCloseIcon {
      transition: all 0.15s;
      width: 136px;
      // border-radius: 8px;
      margin-right: 5px;

      span {
        width: 108px;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.3) !important;
        width: 136px;

        .arrow {
          visibility: hidden;
        }

        span {
          box-shadow: none !important;
          background-color: transparent !important;
        }

        .closeIcon {
          visibility: visible;
          opacity: 1;
        }
      }

      &:active {
        background-color: #d5d5d5 !important;
      }
    }

    &.isActive,
    &.isOnTheScreen,
    &.isPinned {
      @include breakpoint(sm) {
        box-shadow: 0 0 20px 0 rgba(56, 63, 71, 0.08);
      }
    }

    &.isOnTheScreen {
      span {
        opacity: 1;
      }
    }

    &.isActive {
      span {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }

      @include breakpoint(sm) {
        background-color: #fff !important;
        color: var(--primary-color-6) !important;
        opacity: 1;
      }
    }

    &.isPinned {
      span {
        opacity: 1;
      }
    }

    i.arrow {
      width: 28px;
      margin-left: 5px;
      line-height: 46px;
      transition: visibility 0.1s;
      height: 46px;
      display: flex;
      justify-content: center;
      align-items: center;

      :global {
        .bcIcon {
          color: #b4b4b4;
          vertical-align: middle;

          svg {
            vertical-align: top;
          }
        }
      }
    }

    .overflowBtn {
      border: none;
      height: 46px;
      // border-radius: 8px;
      float: left;
    }
  }
}
