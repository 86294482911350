﻿:global {
    .ant-btn {
        padding-left: 7px;
        padding-right: 7px;
        position: relative;

        &[disabled] {
            color: rgba(0, 0, 0, 0.6) !important;

            .bcIcon {
                opacity: 0.5;
                filter: grayscale(100%);
            }
        }

        .bcIcon {
            vertical-align: top;
        }

        &:not(.ant-btn-sm):not(.ant-btn-lg) {
            .bcIcon {
                width: 24px;
            }
        }

        &.ant-btn-sm {
            .bcIcon {
                width: 12px;
            }
        }

        &.ant-btn-lg {
            .bcIcon {
                width: 22px;
            }
        }

        &.ant-btn-primary,
        &.ant-btn-danger {
            .bcIcon {
                --primary-color-6: #fff;
                --icon-secondary-color: #fff;
            }
        }

        &.ant-btn-icon-only {
            padding: 0;
            .bcIcon {
                width: 100% !important;
                max-width: 32px;
            }
        }

        &.ant-btn-text,
        &.ant-btn-link {
            &:focus:after{
                content: none;
            }
            &:not(.ant-btn-sm):not(.ant-btn-lg) {
                .bcIcon {
                    height: 32px;
                }
            }

            &.ant-btn-sm {
                .bcIcon {
                    height: 24px;
                }
            }

            &.ant-btn-lg {
                .bcIcon {
                    height: 40px;
                }
            }
        }
    }

    a.ant-btn{
        &:focus:after {
            position: absolute;
            inset: 0;
            display: block;
            border-radius: inherit;
            box-shadow: 0 0 0 0 var(--primary-color-6);
            box-shadow: 0 0 0 0 var(--icon-secondary-color);
            opacity: 0.2;
            -webkit-animation: fadeEffect 3s cubic-bezier(0.08, 0.82, 0.17, 1),
                waveEffect 0.5s cubic-bezier(0.08, 0.82, 0.17, 1);
            animation: fadeEffect 3s cubic-bezier(0.08, 0.82, 0.17, 1),
                waveEffect 0.5s cubic-bezier(0.08, 0.82, 0.17, 1);
            -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
            content: "";
            pointer-events: none;
        }

        @-webkit-keyframes waveEffect {
            to {
                box-shadow: 0 0 0 var(--primary-color-6);
                box-shadow: 0 0 0 6px var(--icon-secondary-color);
            }
        }
        @keyframes waveEffect {
            to {
                box-shadow: 0 0 0 var(--primary-color-6);
                box-shadow: 0 0 0 6px var(--icon-secondary-color);
            }
        }
        @-webkit-keyframes fadeEffect {
            to {
                opacity: 0;
            }
        }
        @keyframes fadeEffect {
            to {
                opacity: 0;
            }
        }
    }

    .ant-btn:empty {
        visibility: visible;
        width: 60px;
    }
}

.iconWithText {
    :global {
        .bcIcon {
            margin-right: 5px;
        }
    }
}
