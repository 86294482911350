.dashboardContainer {
    display: flex;
    flex-direction: column;
    width: calc(100% - 10px);
    margin: auto;
    padding: 0px 80px;

    .greetingTitle {
        font-size: 24px;
        font-weight: 500;
        color: #25241f;
    }

    .refreshIcon {
        line-height: 20px;
        margin-right: 16px;
        width: 20px;
        cursor: pointer;
        transition: all 200ms ease;

        &:hover {
            transform: rotate(359deg);
        }
    }

    .tileCardListContainer {
        height: 130px;

        .tileCardList {
            display: flex;
            column-gap: 20px;
            flex-wrap: nowrap;
            justify-content: flex-start;
            width: 100%;
            height: 110px;

            .tileCard {
                width: 230px;
                height: 110px;
                border-radius: 4px;
                box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);

                &.selected {
                    :global {
                        .ant-card-body {
                            background-color: #ffffff;
                            margin-left: 6px;

                        }
                    }

                    .tileHeader,
                    .tileCount {
                        font-weight: 400;
                    }
                }

                &:not(.selected) {
                    cursor: pointer;
                }

                &:not(.selected) {
                    &:hover {
                        :global {
                            .ant-card-body {
                                background-color: #e5e5e5;
                            }
                        }
                    }

                    &:active {
                        :global {
                            .ant-card-body {
                                background-color: #eee;
                            }
                        }
                    }
                }

                :global {
                    .ant-card-body {
                        background-color: #f0f0ef;
                        width: calc(100% - 4px);
                        height: 100%;
                        margin-left: 4px;
                        display: flex;
                        flex-direction: column;
                        padding: 0 30px;
                        border-radius: 0 4px 4px 0;
                        transition: background-color 200ms ease;
                    }
                }

                .tileHeader {
                    font-size: 14px;
                    color: #393939;
                    font-weight: 300;
                    padding-top: 20px;
                }

                .tileCount {
                    font-size: 40px;
                    line-height: 47px;
                    font-weight: 300;
                    color: #252420bf;
                }
            }
        }
    }

    .recentAppsContainer {
        height: 165px;

        .recentAppCardList {
            display: flex;
            column-gap: 20px;
            flex-wrap: nowrap;
            justify-content: flex-start;

            .recentAppCard {
                width: 106px;
                height: 106px;
                background-color: #faf9f8;
                box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
                transition: all 200ms ease;
                cursor: pointer;

                &:hover {
                    background-color: #ffffff;
                    box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.1);
                }

                :global {
                    .ant-card-body {
                        display: flex;
                        flex-direction: column;
                        flex-wrap: nowrap;
                        row-gap: 20px;
                        align-items: center;
                        padding: unset;
                    }
                }

                .recentAppIcon {
                    &.customAvatar {
                        width: 32px;
                        height: 32px;
                        color: white;
                        line-height: 32px;
                        text-align: center;
                        border-radius: 4px;
                    }
                }

                .recentAppCaption {
                    font-size: 11px;
                    line-height: 13px;
                    text-align: center;
                    overflow: hidden;
                    width: 80%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    .emptyView {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        margin: auto;

        &.inCard {
            justify-content: center;
            height: 345px;

            &.hasDefaultPadding {
                height: 300px;
            }
        }

        .emptyViewImg {
            height: 135px;
            padding: 15px;
        }

        .emptyViewTitle {
            font-size: 14px;
            font-weight: 600;
        }

        .emptyViewDesc {
            font-size: 12px;
            font-weight: 300;
        }
    }

    .processDetailsContainer {
        height: 390px;
        background: rgba(0, 0, 0, 0);
        min-width: 390px;

        :global {
            .ant-card-head {
                padding: 0px;
                border-bottom: none;

                .ant-card-head-title {
                    padding: 0px;
                }
            }

            .ant-card-body {
                background-color: #ffffff;
                height: 345px;
                padding: 10px 0px;
                border-radius: 4px;
            }

            .dx-datagrid .dx-row>td>span {
                pointer-events: none !important;
                user-select: none !important;
                -moz-user-select: none !important;
                -webkit-user-select: none !important;
                -ms-user-select: none !important;
            }

            .dx-datagrid .dx-row>.dx-command-adaptive>span {
                pointer-events: fill !important;
                user-select: auto !important;
                -moz-user-select: auto !important;
                -webkit-user-select: auto !important;
                -ms-user-select: auto !important;
            }
        }
    }

    .recentFilesContainer,
    .announcementsContainer {
        height: 390px;
        background: rgba(0, 0, 0, 0);

        .recentFilesHeader,
        .announcementsHeader {
            display: flex;
            justify-content: space-between;

            .recentFilesShowAll,
            .announcementsShowAll {
                color: var(--primary-color);
                font-weight: 600;
                font-size: 13px;
                display: flex;
                align-items: center;
                column-gap: 6px;
                cursor: pointer;
                transition: color 200ms ease;
                height: 25px;
                line-height: 25px;

                :global {
                    .bcIcon {
                        transition: all 200ms ease;
                    }
                }

                &:hover {
                    color: var(--primary-color-7);

                    :global {
                        .bcIcon {
                            margin-left: 5px;
                        }
                    }
                }
            }
        }

        :global {
            .ant-card-head {
                padding: 0px;
                border-bottom: none;

                .ant-card-head-title {
                    padding: 0px;
                }
            }

            .ant-card-body {
                padding: 0px;
                height: 345px;
                background-color: #fff;
                border-radius: 4px;

                .ant-list-empty-text {
                    background-color: #ffffff;
                    height: 345px;
                    display: flex;
                }
            }
        }

        .recentFilesList,
        .announcementsList {
            :global {
                .ant-list-items {
                    background-color: #fff;
                    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
                    border-radius: 4px;

                    .ant-list-item {
                        padding: 6px 0px;
                        cursor: pointer;
                        border-bottom: 1px solid rgba(0, 0, 0, .1);
                        transition: all 200ms ease;

                        &:hover {
                            background-color: #f0f0ef;

                            [class*="recentFileItemName"],
                            [class*="announcementItemName"] {
                                color: #00174f;
                            }

                            .bcIcon[class*="iconHover"] {
                                &>div {
                                    display: flex;
                                }
                            }
                        }

                        &:active {
                            background-color: #e5e5e5;
                        }
                    }
                }
            }

            .recentFileItemContainer,
            .announcementItemContainer {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                height: 56px;

                .recentFileItem,
                .announcementItem {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    column-gap: 13px;
                    width: 100%;

                    .recentFileItemIcon,
                    .announcementItemIcon {
                        padding-left: 17px;
                        height: 28px;
                    }

                    .recentFileItemText {
                        overflow: hidden;
                        display: flex;
                        flex-direction: column;
                        color: #25241f;

                        .recentFileItemName {
                            font-size: 13px;
                            text-overflow: ellipsis;
                            white-space: pre;
                            overflow: hidden;
                        }

                        .recentFileItemCreateInfo {
                            font-weight: 300;
                            font-size: 12px;
                        }
                    }
                }

                .iconHover {
                    width: 57px;

                    &>div {
                        display: none;
                    }
                }
            }
        }
    }

    .announcementsContainer {
        .announcementsList {
            .announcementItem {
                .announcementItemText {
                    display: flex;
                    flex-direction: row;
                    color: #25241f;
                    justify-content: space-between;
                    width: 100%;

                    .announcementItemName {
                        font-size: 14px;
                        font-weight: 300;

                        &.unRead {
                            font-weight: 500;
                        }
                    }

                    .announcementItemCreateInfo {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .processesContainer {
        height: 390px;
        background-color: rgba(0, 0, 0, 0);
        min-width: 390px;

        :global {
            .ant-card-body {
                background: linear-gradient(to right, #f0f0ef calc(50% + 1px), #fff 50%);
                height: 345px;
                padding: 10px 0px;
                border-radius: 4px;

                .dx-datagrid-headers {
                    display: none;
                }

                .dx-scrollable-wrapper {
                    background: linear-gradient(to right, #f0f0ef calc(50% + 1px), #fff 50%);
                }

                .dx-datagrid-rowsview {
                    border: unset;

                    .dx-datagrid-content {

                        .dx-row:not(.dx-datagrid-group-row):not(.dx-freespace-row) {
                            td {
                                transition: background-color 200ms ease;
                            }

                            &:first-child {
                                transition: background-color 200ms ease;
                            }

                            transition: background-color 200ms ease;

                            &:hover {
                                td {
                                    background-color: #F2F2F2 !important;

                                    &:first-child {
                                        background-color: #EAEAE9 !important;
                                    }
                                }

                                background-color: #eaeae9 !important;
                            }

                            &.dx-selection {
                                td {
                                    background-color: #fff !important;

                                    &:first-child {
                                        background-color: #f0f0ef !important;
                                    }
                                }
                            }

                            td {
                                padding: 0;
                                cursor: pointer;
                                border-bottom: 1px solid rgb(163 148 148 / 10%) !important;

                                &:first-child {
                                    background-color: #f0f0ef !important;
                                }
                            }
                        }
                    }
                }
            }

            .ant-card-head {
                padding: 0px;
                border-bottom: none;

                .ant-card-head-title {
                    padding: 0px;
                }
            }
        }

        .processCaptionCell {
            font-size: 13px;
            display: flex;
            padding: 0 20px;
            width: 100%;
            height: 40px;


            .processCaptionCellLabel {
                color: #25241f;
                max-width: calc(100% - 52px);
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .processCaptionCellCount {
                color: #5f5e59;
                max-width: 50px;
            }

            &.selected {
                background-color: #D5D5D5;
            }
        }

        .processCountCell {
            padding: 0 20px;
            height: 40px;
            display: flex;
            align-items: center;

            .processCountCellProgress {
                height: 16px;
                background-color: #69797E;
            }

            &.selected {
                background-color: #E2E2E2;
            }
        }
    }

    .widgetHeader {
        font-size: 16px;
        font-weight: 600;
        color: #25241f;
        display: block;
        margin-bottom: 16px;
        background-color: rgba(0, 0, 0, 0);
    }
}

.processDetailPopoverContainer {
    .popoverContentCaption {
        font-size: 12px;
        color: #25241f;
    }

    .popoverContentValue {
        font-size: 12px;
        font-weight: 300;
        color: #393939;
    }
}