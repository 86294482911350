/// <reference path="../../stylesheets/main.scss"/>

@import 'mainstyles';

.empty {
    text-align: center;
    padding: 50px 10px;

    p {
        font-size: 14px;

        @include themify($themes) {
            color: themed('emptyTextColor');
        }
    }
}

:global {
    .darkEmptyBox, .lightEmptyBox {
        width: 62px;
        height: 44px;
        margin-bottom: 10px;
        display: none;
    }

    .themeDark {
        .darkEmptyBox {
            display: inline-block;
        }
    }

    .themeLight {
        .lightEmptyBox {
            display: inline-block;
        }
    }
}
