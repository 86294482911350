.IconSelect {
    :global {
        .ant-select-selector {
            width: 100% !important;
            margin-bottom: 2px !important;
        }

        .dx-datagrid .dx-row {
            >td {
                .ant-select {
                    display: flex !important;
                }
            }
        }
    }
}

.IconSelectDropdown {
    width: 600px !important;

    .IconSelectContainer {
        padding: 24px;
    }
}