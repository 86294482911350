.MessageBoxContainer {
    ::-webkit-scrollbar {
        width: unset !important;
        height: unset !important;
    }

    .leftContent {
        width: 40px;
        height: 40px;
        float: left;
    }

    .myCustomContent {
        width: 100%;
        overflow: hidden;

        :global {
            .ant-collapse {
                > .ant-collapse-item {
                    > .ant-collapse-header {
                        line-height: 22px;
                        padding: 0px !important;
                    }
                }
            }

            .ant-collapse-item {
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .modalContentWrapperFixHeight {
        max-height: 285px;
        &.exceptionViewFixHeight {
            max-height: 240px;
            height: 240px;
        }
        &.undefinedErrorFixHeight {
            max-height: 300px;
            height: 300px;
            padding: 0px !important;
        }
        display: flex;
        flex-direction: column;
        transition-property: all;
        transition-duration: 0.25s;
        transition-timing-function: ease-in-out;

        &.expandHeightTransition {
            transition-duration: 0.5s;
            display: inline-block;
            width: 100%;
        }

        &.collapsedForExceptionView {
            max-height: 450px;
            height: 450px;
        }
        &.collapsed {
            max-height: 385px;
            height: 385px;
        }
    }

    .attentionMessageDetailContent {
        :global {
            .ant-collapse {
                margin-left: 10px;
            }

            .ant-collapse-header {
                background-color: transparent;
                .ant-collapse-header-text {
                    [class*="header"] {
                        background-color: unset;
                        p {
                            font-weight: 600 !important;
                            color: #333;
                        }
                    }
                    div:nth-of-type(1) {
                        p:nth-of-type(1) {
                            font-weight: 600 !important;
                            color: #333;
                        }
                    }
                }
            }

            .bcIcon {
                position: absolute;
                left: 0px;
                color: #333;
            }
        }
        &.detailsExceptionActive {
            :global {
                .ant-collapse-item {
                    .ant-collapse-header {
                        .ant-collapse-arrow {
                            color: var(--primary-color-6);
                        }
                        .ant-collapse-header-text {
                            div:nth-of-type(1) {
                                p:nth-of-type(1) {
                                    color: var(--primary-color-6);
                                }
                            }
                        }
                    }
                }
            }
        }
        &.detailsTransition {
            :global {
                .ant-collapse-item {
                    .ant-collapse-header {
                        background-color: transparent;
                        .ant-collapse-arrow {
                            position: inherit;
                            left: 0;
                            width: 15px;
                            height: 20px;
                            top: 0;
                            margin-top: 15px;
                            cursor: pointer;
                        }
                        .ant-collapse-header-text {
                            float: left;
                            position: relative;
                            [class*="header"] {
                                background-color: unset;
                                p {
                                    font-weight: 600 !important;
                                    margin-left: 0px !important;
                                }
                            }
                        }
                    }

                    .ant-collapse-content {
                        transition-property: all !important;
                        transition-duration: 0.5s !important;
                        transition-timing-function: ease-in-out !important;
                    }
                }
            }
        }
    }

    :global {
        .myCustomClose-icon {
            position: absolute;
            right: 30px !important;
            top: 30px !important;
            cursor: pointer;
        }

        .ant-modal-footer {
            padding: 15px 30px 15px 30px !important;
        }

        .ant-modal-content {
            position: relative !important;
            min-height: unset !important;

            .overflow-content {
                &.overflowContentError {
                    margin-top: 24px;
                    font-weight: 600;
                    color: black;
                    max-height: 65px;
                    min-height: 45px;
                }
                &.isValidation {
                    margin-top: 7px;
                    max-height: 90px;
                    min-height: 90px;
                    [class*="bcScrollableArea"] {
                        min-height: 80px !important;
                        div {
                            overflow-x: unset !important;
                        }
                    }
                }
            }

            .ant-modal-header {
                margin-bottom: 0 !important;
            }

            .ant-modal-close-x {
                font-size: 20px !important;

                .ant-modal-close-icon {
                    color: unset !important;
                }
            }
        }
    }
}

.notInCloseIcon {
    :global {
        .ant-modal-content {
            position: unset !important;
            .ant-modal-close {
                display: none !important;
                .ant-modal-close-x {
                    font-size: unset !important;

                    .ant-modal-close-icon {
                        color: white !important;
                    }
                }
            }
        }
    }
}

:global {
    .myCustomContainer {
        display: flex;
        height: calc(100% - 32px);
        padding-top: 6px;
        .myCustom-icon {
            width: 108px;
            justify-content: center;
            display: flex;
            .bcIcon {
                width: 68px;
            }
        }

        .myCustomRightContent {
            width: calc(100% - 108px);
            display: flex;
            flex-direction: column;
            justify-content: center;

            .myCustom-title {
                display: block;
                width: 100%;
                font-size: 20px;
                font-weight: normal;
                margin-bottom: 30px;
                color: #5f5e59;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .myCustomModal-footer {
        position: relative;
        text-align: right;
        margin-top: 20px;

        a {
            margin-right: 10px;
            min-width: 80px;
            float: right;
        }

        button {
            margin-right: 10px;
            min-width: 80px;
        }

        &.hasError {
            justify-content: space-between;
        }

        .myCustom-ErrorTitle,
        .myCustom-Error-footprint {
            font-size: 9px;
            font-weight: 600;
            color: var(--icon-secondary-color);
            opacity: 0.5;
            max-width: 300px;
        }
        .myCustom-Error-footprint {
            bottom: 0px !important;
        }

        &.exceptionExist {
            width: 100px;
            float: right;
        }
    }
}

.myCustomModal {
    width: 100%;
    margin-top: -3px;
}

.modalClass {
    transition: width 0.25s;
}


.collapseHeader {
    width: 100%;
    height: 40px;
    user-select: none;
}

.errorIconConfirm {
    width: unset;
    height: unset;
    margin-right: 60px;
}

.detailsDivider {
    opacity: 0.2;
    margin-top: -1.5px;
}

.myCustomErrorTitleExceptionOverride {
    font-weight: unset !important;
    position: relative !important;
    font-size: 13px !important;
    opacity: 0.4 !important;
    max-width: unset;
}

.copyToClipboardStyle {
    color: var(--primary-color-6);
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
}
.reportButtonStyle {
    color: var(--primary-color-6);
    text-decoration: underline;
    font-weight: 600;
    margin-left: 20px;
    cursor: pointer;
}

.copyToClipboardContainer {
    margin-top: 20px;
}

.buttonStyle {
    float: right;
    margin-top: -8px;
    :global {
        .ant-btn {
            width: 83px;
        }
    }
}

.validationTitle {
    margin-top: 20px;
    font-weight: 600;
    color: black;
    max-height: 40px;
    min-height: 40px;
    span {
        color: var(--primary-color-6);
    }
}

.validationContent {
    margin: 0px;
    span {
        font-weight: 600;
    }
}

.CollapseHeaderStyle {
    display: flex;
    [class*="bcIcon"] {
        width: 16px;
        height: 16px;
        position: relative !important;
        margin-top: 10px;
    }
}

.undefinedErrorContainer {
    text-align: center;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 0;

    .SomethingsWentWrongContainerStyle {
        height: 135px;
        .SomethingsWentWrongStyle {
            width: 150px;
        }
    }
    .UndefinedErrorMessageContainerStyle {
        .UndefinedErrorMessageFirstStyle {
            font-size: 25px;
            font-weight: 600;
            color: black;
            margin-bottom: auto;
        }
        .UndefinedErrorMessageSecondStyle {
            font-size: 15px;
            color: black;
        }
    }
}
