.UITourContainer {
    :global {
        [data-tour-elem="right-arrow"],
        [data-tour-elem="left-arrow"] {
            outline: none;
        }
        .reactour__dot {
            cursor: default;
            outline: none;
        }
        .reactour__dot--is-active {
            height: 12px;
            width: 12px;
            border-radius: 50%;
        }
        .reactour__dot {
            &.reactour__dot--is-active {
                &:before {
                    bottom: calc(100% + 1em) !important;
                    opacity: 1 !important;
                }
                &:hover {
                    &:before {
                        left: 0% !important;
                        transform: translate(19%,13px) !important;
                    }
                }
            }
            &:not(.reactour__dot--is-active) {
                &:hover {
                    &:before {
                        opacity: 0 !important;
                    }
                }
            }
        }
    }
    .TourHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        :global {
            .bcIcon {
                cursor: pointer;
            }
        }
        .TourTitle {
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: calc(100% - 25px);

            .titleText {
                font-family: Segoe WP;
                font-size: 14px;
                font-weight: 600;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: calc(100%);
                padding: 0 10px;
            }
            :global {
                .ant-badge-count {
                    height: 24px;
                    width: 24px;
                    line-height: 24px;
                    border-radius: 0;
                    font-size: 14px;
                    font-weight: 600;
                    font-family: Segoe WP;
                }
            }
        }
    }
}

.tourCloseModalWrap {
    z-index: 1000000 !important;

    :global {
        .ant-modal-content {
            min-width: unset !important;
            min-height: unset !important;
        }
    }
}
