/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

:global {
    @include themify($themes) {
        .ant-popover {
            .ant-popover-arrow {
                border-color: themed("inputBackgroundColor");
                background-color: themed("inputBackgroundColor");
                display: none;
            }

            .ant-popover-content {
                .ant-popover-inner {
                    box-shadow: 0 6px 16px 0 themed("popoverShadowColor");
                    background: transparent;

                    .ant-popover-title {
                        border-color: themed("popoverHeaderBorderColor");
                        color: themed("inputColor");
                        border-radius: 0;
                    }

                    .ant-popover-inner-content {
                        border-radius: 0;
                    }

                    .ant-popover-title,
                    .ant-popover-inner-content {
                        background-color: themed("inputBackgroundColor");
                    }
                }
            }
        }
    }
}