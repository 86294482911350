.drawerFormHeader {
    display: flex;
    justify-content: space-between;

    .drawerFormTitle {
        font-size: 16px;
        font-weight: 700;
        line-height: 32px;
    }
    
    .drawerHeaderButtons {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-end;
        flex-direction: row;

        .drawerHeaderDivider {
            height: 32px;
        }

        .closePanel {
            cursor: pointer;
        }
    }
}

.emptyHoldingImg {
    width: 15%;
}

.emptyHoldingTitle {
    font-size: 14px;
    font-weight: 600;
}

.emptyHoldingDesc {
    font-size: 12px;
    font-weight: 300;
}

.emptyHoldingButton {
    margin-top: 20px;
    width: 138px;
    height: 32px;

    &[disabled] {
        background-color: #cecdcb !important;
        color: #ffffff !important;
    }

    .emptyHoldingButtonIcon {
        width: 20px !important;
        height: 16px !important;
        color: #ffffff !important;
        vertical-align: middle;
    }
}