.ProfileFormContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 0;
    flex-wrap: wrap;

    .fileList {
        flex: 1;
        min-width: 300px;
    }
    .formTabs {
        flex: 1;
        border-left: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        padding: 10px 20px;
        min-width: 400px;

        .formTabsContent {
            height: calc(100% - 50px);
            width: 100%;

            > div {
                height: 100%;
                :global {
                    .ant-tabs {
                        height: 100%;
                    }
                }
            }
        }

        .folderOptions {
            height: 130px;
            .folderStatusIcon {
                margin: 0 5px;
                width: 24px;
            }
        }

        &.withFolderSelection {
            .formTabsContent {
                height: calc(100% - 150px);
            }
        }

        .formTabsOptions {
            height: 50px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            :global {
                .ant-btn {
                    margin: 0 5px;
                    width: 80px;
                }
            }
        }

        .noFileSelected {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-around;

            span {
                margin: 10px 0;
            }
        }
    }
}

.FileListContainer {
    width: 100%;
    height: 100%;
    padding: 10px 20px;

    :global {
        .ant-list-item {
            padding: 0;
        }
    }

    .EmptyWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        
        .emptyInfoTitle {
            line-height: 25px;
            font-size: 14px;
            font-weight: 600;
        }
    
        .emptyInfo {
            line-height: 25px;
            font-size: 12px;
            font-weight: 100;
        }
    
        .emptyImage {
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            max-width: 25%;
            max-height: 50%;
            margin-bottom: 10px;
        }
    }

    .fileBrowseSection {
        height: 80px;
        display: flex;
        flex-direction: column;
        margin: 0 10px;
        justify-content: space-around;
        :global {
            .ant-btn {
                width: 80px;
            }
        }
    }

    .fileListSection {
        height: calc(100% - 120px);
    }

    .listHeader {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #eeecea;
        padding: 10px;
    }

    .profileFormFileItem {
        width: 100%;
        height: 48px;
        padding: 5px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #f4f4f4;

            .itemOptions {
                display: flex;
            }
        }

        &.selectedItem {
            background-color: #eef2f6;
            border: 1px solid #007ad5;
        }

        .itemIcon {
            width: 32px;
            margin: 0 5px;
        }

        .itemStatusIcon {
            margin: 0 5px;
            width: 16px;
        }

        .itemInfo {
            flex: 9;
            display: flex;
            flex-direction: column;
            padding-right: 34px;
            max-width: calc(100% - 34px);

            .itemName {
                font-family: Segoe WP;
                font-size: 14px;
                color: #25241f;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .itemDescription {
                font-family: Segoe WP;
                font-size: 12px;
                color: #252421;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        .itemOptions {
            position: absolute;
            right: 0;
            display: none;
            flex-direction: row;
            justify-content: space-around;

            .optionItem {
                height: 24px;
                width: 24px;
                margin: 0 10px;
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }
}
