/// <reference path="../../stylesheets/main.scss" />
@import "mainstyles";

.flexStartup {
    width: 100%;
    min-height: 100%;
    overflow: hidden;
    padding: 50px 0;

    .borderRight {
        border-right: solid 1px #ececec;
    }

    .marginBottom {
        margin-bottom: 50px;
    }

    .startupPlugin {
        padding: 0 50px 0 50px;

        &.paddingBottom {
            padding-bottom: 40px;
        }

        h1.title {
            font-size: 16px;
            font-weight: 600;
            line-height: 19.2px;
            color: #252420;
            margin-bottom: 9px;
        }

        p.description {
            line-height: 16.8px;
            letter-spacing: 0.22px;
            color: #5f5e59;
            font-size: 14px;
            margin: 0 0 10px 0;

            @include themify($themes) {
                color: themed("ideStartupTextColor");
            }
        }

        a {
            color: var(--primary-color-6);

            &:hover {
                color: var(--primary-color-6);
                text-decoration: underline;
            }
        }

        &.noGutter {
            padding: 30px 0;

            h1.title {
                padding: 0 55px;
            }
        }

        :global {
            .ant-input-search .ant-input-affix-wrapper {
                margin: 0;
                background-color: #f4f2f2;
                .ant-input {
                    background-color: #f4f2f2;
                }
            }
            .ant-input-search-button {
                background-color: #f4f2f2;
            }
        }
    }
}

.openTabContent {
    p {
        font-size: 14px;
        color: #5f5e59;
        line-height: 1.2;
        letter-spacing: 0.22px;
        font-weight: 300;
        width: 100%;
        float: left;
        margin-top: 20px;
    }
}

.buttonList {
    margin-top: 10px;
    .listItemHeader {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        color: #252420;
        margin-bottom: 9px;
    }

    .listItem {
        width: 168px;
        padding: 20px 10px 0px 10px;
        border-radius: 4px;
        margin-bottom: 1px;
        cursor: pointer;
        transition: background-color 200ms ease;
        user-select: none;
        border: solid 1px #b2b4b5;

        &:hover {
            background-color: #f8f9fa;
            border-color: #d7d8d8;
        }

        &:active {
            background-color: #f1f2f4;
            border-color: var(--primary-color-6);
        }

        .listItemIcon,
        .listItemContent {
            float: left;
        }

        .listItemIcon {
            width: 100%;
            height: 32px;
        }

        .listItemContent {
            width: 100%;
            flex-flow: column;
            justify-content: center;
            display: flex;
            height: 40px;
            text-align: center;
            margin-top: 4px;

            h3 {
                margin: 0;
                text-overflow: ellipsis;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
                color: #333d46;
                font-weight: normal;
                font-size: 13px;
            }
        }
    }

    :global {
        .ant-list-item {
            padding: 0;
            margin: 0;
            float: left;
            margin-right: 8px;

            .ant-list-item-content {
                width: 100%;
            }
        }
    }
}

.recentItemList {
    margin-right: 15px;
    .listItemHeader {
        font-size: 16px;
        font-weight: 600;
        line-height: 19.2px;
        color: #252420;
        margin-bottom: 9px;
    }

    .listItem {
        width: 100%;
        padding: 10px;
        border-radius: 0;
        cursor: pointer;
        transition: background-color 200ms ease;
        user-select: none;
        background: #f8f9fa;

        &:hover {
            background-color: #f0f1f2;

            .pinButton {
                opacity: 1;
            }
        }

        &:active {
            background-color: #e3e3e3;
        }

        .listItemIcon,
        .listItemContent,
        .listItemTime {
            float: left;
        }

        .pinButton {
            width: 16px;
            height: 16px;
            margin-top: 12px;
            display: block;
            float: left;
            transition: all 100ms ease-out;
            opacity: 0;

            &.pinned {
                transform-origin: center;
                opacity: 1;
            }

            :global {
                .bcIcon {
                    transition: color 200ms ease;
                    cursor: pointer;

                    @include themify($themes) {
                        color: themed("ideStartupActionButtonColor");
                    }

                    svg {
                        vertical-align: top;
                    }
                }
            }

            &:hover {
                :global {
                    .bcIcon {
                        color: var(--primary-color-6);
                    }
                }
            }
        }

        .listItemIcon {
            width: 40px;
            height: 40px;
            margin: 0 10px;
            :global {
                .bcIcon {
                    width: 28px;
                }
            }
        }

        .listItemContent {
            width: calc(100% - 186px);
            flex-flow: column;
            justify-content: center;
            display: flex;
            height: 40px;

            h3,
            p {
                margin: 0;
                text-overflow: ellipsis;
                max-width: 100%;
                white-space: nowrap;
                overflow: hidden;
            }

            h3 {
                font-size: 14px;
                line-height: 24px;
                color: #25241f;
                font-weight: 400;
            }

            p {
                font-size: 12px;
                line-height: 16px;
                color: #252420;
                font-weight: 300;
            }
        }

        .listItemTime {
            width: 90px;
            margin-left: 10px;
            margin-right: 10px;
            text-align: right;
            color: #5f5e5a;
            font-size: 10px;
            font-weight: 400;
            height: 40px;
            line-height: 40px;
            max-width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
            display: inline-block;
            white-space: nowrap;
            letter-spacing: 0.41px;
        }

        &.pinable {
            .listItemContent {
                width: calc(100% - 186px);
            }
        }
    }

    :global {
        .ant-list-items {
            border-radius: 4px;
            overflow: hidden;
            box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
        }
        .ant-list-item {
            padding: 0;
            margin: 0;

            .ant-list-item-content {
                width: 100%;
            }
        }
    }
}
