/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

:global {
    @include themify($themes) {
        .ant-switch {
            font-size: 14px;
            font-variant: tabular-nums;
            -webkit-font-feature-settings: "tnum";
            font-feature-settings: "tnum";
            line-height: 1.5;
            color: rgba(0, 0, 0, 0.65);
            margin: 0;
            padding: 0;
            list-style: none;
            position: relative;
            display: inline-block;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            height: 20px !important;
            min-width: 42px !important;
            line-height: 20px !important;
            vertical-align: middle;
            border-radius: 100px;
            border: 2px solid themed("inputSelectSelectionBorderColor");
            background: #fff;
            cursor: pointer;
            -webkit-transition: all 0.36s;
            transition: all 0.36s;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            .ant-switch-handle {
                height: auto;
            }
        }

        .ant-switch-inner {
            color: transparent;
            font-size: 12px;
            margin-left: 24px;
            margin-right: 6px;
            display: block;
            line-height: 1.2;
        }

        .ant-switch-loading-icon,
        .ant-switch:after {
            position: absolute;
            width: 12px !important;
            height: 12px !important;
            left: 3px;
            top: 2px;
            border-radius: 18px;
            background-color: var(--icon-secondary-color) !important;
            content: " ";
            cursor: pointer;
            -webkit-transition: all 0.36s ease-in-out;
            transition: all 0.36s ease-in-out;
        }

        .ant-switch-loading-icon,
        .ant-switch-checked:after {
            background-color: var(--primary-color-6) !important;
        }

        .ant-switch-checked {
            background-color: var(--primary-color-6) !important;
            border: 2px solid var(--primary-color-6) !important;

            &:after {
                background-color: #fff !important;
            }

            .ant-switch-inner {
                color: var(--primary-color-6) !important;
            }
        }

        .ant-switch:after {
            -webkit-box-shadow: none !important;
            box-shadow: none !important;
        }

        .ant-switch:active:before,
        .ant-switch:active:after {
            width: 24px;
        }

        .ant-switch-loading-icon {
            background: transparent;
            z-index: 1;
            display: none;
            font-size: 12px;
        }

        .ant-switch-loading-icon svg {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            margin: auto;
        }

        .ant-switch-loading .ant-switch-loading-icon {
            display: inline-block;
            color: rgba(0, 0, 0, 0.65);
        }

        .ant-switch-checked.ant-switch-loading .ant-switch-loading-icon {
            color: var(--primary-color-6);
        }

        .ant-switch:focus {
            -webkit-box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
            outline: 0;
        }

        .ant-switch:focus:hover {
            -webkit-box-shadow: none;
            box-shadow: none;
        }

        .ant-switch-small {
            height: 16px;
            min-width: 28px;
            line-height: 14px;
        }

        .ant-switch-small .ant-switch-inner {
            margin-left: 18px;
            margin-right: 3px;
            font-size: 12px;
        }

        .ant-switch-small:after {
            width: 12px;
            height: 12px;
        }

        .ant-switch-small:active:before,
        .ant-switch-small:active:after {
            width: 16px;
        }

        .ant-switch-small .ant-switch-loading-icon {
            width: 12px;
            height: 12px;
        }

        .ant-switch-small.ant-switch-checked .ant-switch-inner {
            margin-left: 3px;
            margin-right: 18px;
        }

        .ant-switch-small.ant-switch-checked .ant-switch-loading-icon {
            left: 100%;
            margin-left: -13px;
        }

        .ant-switch-small.ant-switch-loading .ant-switch-loading-icon {
            -webkit-transform: scale(0.66667);
            -ms-transform: scale(0.66667);
            transform: scale(0.66667);
            font-weight: bold;
        }

        .ant-switch-checked {
            background-color: #fff;
        }

        .ant-switch-checked .ant-switch-inner {
            margin-left: 6px;
            margin-right: 24px;
        }

        .ant-switch-checked:after {
            left: calc(100% - 14px);
        }

        .ant-switch-checked .ant-switch-loading-icon {
            left: 100%;
            margin-left: -19px;
        }

        .ant-switch-loading,
        .ant-switch-disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }

        .ant-switch-loading *,
        .ant-switch-disabled * {
            cursor: not-allowed;
        }
    }
}