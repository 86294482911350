/// <reference path="../../../../../node_modules/@bimser/components/dist/stylesheets/main.scss" />
@import "mainstyles";

.panelMenuContainer {
    height: calc(100% - 10px);
    padding-top: 20px;
    margin: 5px;
    border-radius: 4px;
    background-color: rgba(250, 249, 248, 1);
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.16);

    :global {
        .ant-list-item {
            padding: 0px !important;
            flex-direction: column;
            flex-flow: wrap;

            @include themify($themes) {
                color: themed("menubarTextColor");
            }
        }

        .bcIcon {
            @include themify($themes) {
                color: themed("webLeftMenuItemIconColor");
            }

            transition: color $transition-duration $transition-timing-function;
            width: 16px;
            height: 16px;
            margin-right: 10px;
            display: flex;
            align-self: center;
            margin-top: 2px;
        }
    }

    .panelMenuItem {
        width: calc(100% - 20px);
        padding: 0px 10px 0px 10px;
        cursor: pointer;
        display: flex;
        position: relative;
        color: #000;
        transition: background-color 200ms ease;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:active {
            background-color: rgba(0, 0, 0, 0.1);
        }

        ul,
        li {
            list-style-type: none;
            float: right;
            margin-bottom: 0 !important;

            a {
                height: 16px !important;
                line-height: 13px !important;
                margin-top: 13px;
                font-size: 11px !important;
            }
        }

        .info {
            color: var(--primary-color-6);
            text-align: center;
            margin-left: auto;
        }

        :global {
            .ant-badge {
                // transform: translate(0, -50%);
                // top: 50%;

                .ant-badge-count {
                    height: 24px !important;
                    line-height: 24px !important;
                    text-align: center !important;
                    font-size: 13px !important;
                    font-weight: 900;
                    border-radius: 100%;
                    padding: 0 9px;
                    float: left;
                    color: #fff;
                    transition: opacity 200ms ease;
                    margin-left: 10px !important;
                    transform: translate(0, -50%);
                    top: 50%;
                    webkit-transform: translate(0, -50%);
                }
            }

            .subChildIconArea {
                width: 12px;
                margin-right: 10px;
                display: flex;
                align-items: center;

                .subChildFontIcon {
                    font-size: 10px;
                    color: #25241f;
                    font-weight: unset;
                }
            }
        }

        .text {
            line-height: 40px;
            float: left;
            white-space: nowrap;
            user-select: none;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-right: 10px;
            font-size: 14px;
            font-weight: 300;
        }

        .arrow {
            &.expanded {
                // :global {
                //     .subChildFontIcon {
                //         transform: rotate(90deg);
                //     }
                // }
            }
        }

        &.active {
            font-weight: bold;
            color: var(--primary-color-6);
            background-color: #eeedeb;

            .text {
                font-weight: 600 !important;
            }

            :global {
                .bcIcon {
                    color: var(--primary-color-6) !important;
                }
            }
        }

        &.hasChildren {
            font-weight: bold;
        }
    }

    .subListContainer {
        width: 100%;

        // @include themify($themes) {
        //     background-color: themed('webLeftMenuSubBackgroundColor');
        // }

        .panelMenuItem {
            margin: unset;
            padding: 5px 10px 5px 28px;

            .text {
                line-height: 32px !important;
            }

            &.active {
                .text {
                    font-weight: 600 !important;
                }
            }
        }
    }
}
