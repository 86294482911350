.dropdown {
    float: none !important;
    :global{
        .ant-btn {
            height: 30px !important;
            padding: 0 20px !important;
            background: #4a90e2 !important;
            width: auto !important;

            span {
                color: white !important;

                &:hover {
                    color: white !important;
                }
            }
        }
    }
}

.Container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    :global {
        .ant-btn{
            height: 30px !important;
            padding: 0 20px !important;
            background: #4a90e2 !important;
            width: auto !important;
            border-color: transparent !important;
            span {
                color: white !important;

                &:hover {
                    color: white !important;
                }
            }
        }
    }

    >div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        span {
            font-size: 12px !important;
        }
    }

    .DownArrow {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left-color: white !important;
        width: 32px !important;
        padding: 0 !important;
        svg{
            vertical-align: top;
            --icon-secondary-color: white !important;
        }
    }

    ul {
        box-shadow: 0 6px 16px 0 rgba(56, 63, 71, 0.3) !important;
        background: white;
        border-bottom: none;
        position: absolute;
        left: 0;
        top: 31px;
        z-index: 9999;
        width: 200px;
    }
}