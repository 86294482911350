.HRDrawerForm {
    :global {
        .ant-drawer-content-wrapper {
            width: 512px !important;
        }

        .ant-drawer-body {
            background-color: #faf9f8;
        }
    }

    .saveButton {
        border: none;
        background-color: #fff;

        &:hover {
            border: none;
        }
    }

    .formLookup {
        :global {
            .ant-input-group-addon {
                cursor: pointer;
            }
        }
    }
}

.companyComboboxDropdown {
    max-width: unset !important;

    &.hide {
        display: none;
    }
}