/// <reference path="../../stylesheets/main.scss"/>

@import 'mainstyles';

:global {
    @include themify($themes) {
        .ant-tooltip {
            &-content {
                .ant-tooltip-inner {
                    background-color: themed('tooltipBackgroundColor');
                    color: themed('tooltipTextColor');
                    border-radius: 2px;

                    .bcIcon {
                        line-height: 100%;
                        color: #fff;
                        height: 14px;
                        vertical-align: text-top !important;
                    }
                }
            }

            &-placement-right .ant-tooltip-arrow,
            &-placement-rightTop .ant-tooltip-arrow,
            &-placement-rightBottom .ant-tooltip-arrow {
                border-right-color: themed('tooltipBackgroundColor');
            }

            &-placement-left .ant-tooltip-arrow,
            &-placement-leftTop .ant-tooltip-arrow,
            &-placement-leftBottom .ant-tooltip-arrow {
                border-left-color: themed('tooltipBackgroundColor');
            }

            &-placement-top .ant-tooltip-arrow,
            &-placement-topLeft .ant-tooltip-arrow,
            &-placement-topRight .ant-tooltip-arrow {
                border-top-color: themed('tooltipBackgroundColor');
            }

            &-placement-bottom .ant-tooltip-arrow,
            &-placement-bottomLeft .ant-tooltip-arrow,
            &-placement-bottomRight .ant-tooltip-arrow {
                border-bottom-color: themed('tooltipBackgroundColor');
            }
        }
    }
}
