.inputNumberStyles {
    &.textalignRight {
        :global {
            .ant-input-number-input-wrap {
                .ant-input-number-input {
                    text-align: right !important;
                    padding-right: 26px;
                }
            }
        }
    }
    &.textalignLeft {
        :global {
            .ant-input-number-input-wrap {
                .ant-input-number-input {
                    text-align: left !important;
                }
            }
        }
    }
    &.textalignCenter {
        :global {
            .ant-input-number-input-wrap {
                .ant-input-number-input {
                    text-align: center !important;
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        :global{
            .ant-input-number-handler-wrap{
                opacity: 1 !important;
            }
        }
    }
}
