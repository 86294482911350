.ComponentContainer {
    .ComponentContent {
        height: 100%;
        &.isModal {
            height: 80vh;
        }
    }

    .Header {
        display: flex;
        flex-direction: row;
        align-items: center;

        .Title {
            max-width: calc(100% - 250px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .Ribbon {
            max-width: 250px;
            width: auto;
        }

        .CloseBtn{
            cursor: pointer;
        }
        :global {
            .BCRibbonBar {
                margin-left: auto;
                height: 30px;

                .ms-CommandBar {
                    height: 30px !important;

                    .ms-OverflowSet {
                        justify-content: flex-end;
                        height: 30px;

                        .ms-Button {
                            &:hover {
                                background-color: rgba(0, 0, 0, 0.1);
                            }
                            padding: 0px !important;

                            .ms-Button-textContainer {
                                font-size: 13px;
                                font-weight: normal;

                                .ms-Button-label {
                                    height: 28px;
                                    line-height: 28px;
                                    margin: 0 12px 0 0;
                                }
                            }

                            .ms-Icon {
                                height: 14px !important;
                                line-height: 14px !important;
                                margin: 0 9px;
                            }

                            .bcIcon {
                                margin: 0 9px;

                                svg {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    :global {
        .ant-drawer-body {
            padding: 0;
        }
        .ant-drawer-close{
            display: none;
        }
    }
}
.eventRibbonDivider {
    min-width: 1px !important;
    width: 1px !important;
    height: 32px !important;
    background: rgba(0, 0, 0, 0.1);
}
