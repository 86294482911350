/// <reference path="../../stylesheets/main.scss"/>
@import 'mainstyles';

@mixin rotate-icon {
    .bcIcon {
        transform: rotate(180deg);
    }
}

:global {
    @include themify($themes) {
        .ant-notification-notice {
            background: themed('notificationBackgroundColor');
            padding: 10px !important;
            margin-bottom: 10px !important;

            &.successNotification{
                background-color: #E0F6DE !important;
                border: 1px solid #B8ECB4 !important;
            }
            &.severeWarningNotification{
                background-color: #FEDACD !important;
                border: 1px solid #FDB59C !important;
            }
            &.infoNotification{
                background-color: #F3F2F1 !important;
                border: 1px solid #DEDBD8 !important;
            }
            &.blockedNotification{
                background-color: #FDE8EA !important;
                border: 1px solid #FABFC5 !important;
            }
            &.errorNotification{
                background-color: #FDE8EA !important;
                border: 1px solid #FABFC5 !important;
            }
            &.warningNotification{
                background-color: #FFF4CF !important;
                border: 1px solid #FFEAA0 !important;
                @include rotate-icon;
            }
            &.warnNotification{
                background-color: #FFF4CF !important;
                border: 1px solid #FFEAA0 !important;
                @include rotate-icon;
            }

            .ant-notification-notice-with-icon {

                .ant-notification-notice-icon {
                    position: relative;
                    margin-left: 5px !important;
                    font-size: 16px !important;
                    line-height: 16px !important;
                    float: left;
                    display: flex;
                }

                .ant-notification-notice-message {
                    font-weight: 600 !important;
                    margin-bottom: 0 !important;
                }
    
                .ant-notification-notice-message, .ant-notification-notice-description {
                    word-break: break-word;
                    color: themed('notificationTextColor');
                    margin-left: 30px;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.32;
                    letter-spacing: normal;
                    text-align: left;
                    position: relative;
                    display: flex;
                    max-width: 80%;
                }
            }

            .ant-notification-notice-close {
                right: 10px !important;
                top: 5px !important;
                .ant-notification-close-x{
                    .ant-notification-close-icon {
                        font-size: 10px !important;
                        font-weight: bold;
                        color: #333d46;
                    }
                }
                .ant-notification-notice-close-x {
                    color: themed('notificationTextColor');
                }
            }
            
        }
    }
}
