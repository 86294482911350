/// <reference path="../../stylesheets/main.scss" />
@import "mainstyles";

.fileExplorerContainer {
    width: 100%;
    float: left;
    padding: 10px 0 10px 0;
    .title {
        margin-left: 4px;
    }

    .toolbarInputGroup {
        border: 1px solid #d9d9d9;
        overflow: hidden;
        border-radius: 2px;
    }

    .fileExplorerPathbar {
        margin: 0 !important;
        overflow: hidden;
        width: calc(100% - 96px);
        border-right: solid 1px #d9d9d9 !important;
        height: 32px;
    }

    .scrollbarContainer {
        float: left;
    }

    .fileExplorerToolbar {
        padding: 0 4px;
        width: 100%;
        float: left;
        margin-bottom: 20px;

        :global {
            .ant-input-search {
                background-color: #f8f9fa;
                height: 34px;
                &.ant-input-affix-wrapper-focused {
                    background-color: #fff;
                    .ant-input {
                        background-color: #fff;
                    }
                }
                .ant-input-group {
                    .ant-input-affix-wrapper {
                        height: 34px;
                    }
                }
            }
            .ant-input {
                box-shadow: none;
                border: none;
                outline: none;
            }

            .ant-btn {
                padding: 0;
                border: none !important;
                margin: 0;
                display: inline-flex;
                align-items: center;
                justify-content: center;

                .bcIcon {
                    color: #777;
                    height: 16px;
                    svg {
                        vertical-align: inherit;
                    }
                }
            }
        }

        .refreshBtn {
            float: right;
            :global {
                .bcIcon {
                    height: 16px;
                    --icon-secondary-color: #777;
                }
            }
        }

        .homeBtn {
            background: #fff;
        }

        .backBtn {
            background: #fff;
            border-right: 1px solid #d9d9d9 !important;
        }

        .refreshBtn,
        .backBtn {
            &:hover {
                background-color: #f0f0ef !important;
            }
            &:active {
                background-color: #eee !important;
            }
        }

        .mainDirectoryButton,
        .refreshPathButton,
        .parentPathButton {
            @include themify($themes) {
                color: themed("inputPlaceholderColor") !important;
                --icon-secondary-color: themed("inputPlaceholderColor");
            }
        }

        .mainDirectoryButton {
            width: 18px;
        }

        .refreshPathButton {
            width: 28px;
            height: 28px;
            margin-top: 4px;
        }

        .parentPathButton {
            transform: rotate(90deg);
            width: 18px;
        }

        .inputSearch {
            :global {
                .ant-input-wrapper {
                    .ant-input-group-addon {
                        border: 1px solid #d9d9d9;
                        border-left: 1px;
                        left: unset;
                        .ant-btn {
                            height: 30px;
                        }
                    }
                }
            }
        }
    }

    $break-small: 1100px;

    .fileExplorerItemContainer {
        display: flex;
        flex-flow: row wrap;

        .fileExplorerItem {
            cursor: pointer;
            user-select: none;
            text-align: center;
            margin: 4px;
            padding: 10px;
            transition: all 100ms ease;
            border: solid 1px transparent;
            box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
            background: #fff;

            @media screen and (max-width: $break-small) {
                flex: 0 1 calc(25% - 8px);
                width: calc(25% - 8px);
            }

            @media screen and (min-width: $break-small) {
                flex: 0 1 calc(12.5% - 8px);
                width: calc(12.5% - 8px);
            }

            .fileIcon {
                max-width: 50px;
                width: 100%;
                height: 50px;
                display: inline-block;
            }

            .fileName {
                display: inline-block;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                margin: 10px 0;
            }

            &.selected {
                background-color: var(--primary-color-o-1) !important;
                border-color: var(--primary-color-6);
            }

            &:active {
                background-color: var(--primary-color-o-2) !important;
            }
        }
    }

    :global {
        .ant-btn-group {
            .ant-btn {
                border-radius: 4px !important;
                margin-left: 2.5px !important;
                margin-right: 2.5px !important;
            }
        }
    }

    @include themify($themes) {
        .title {
            color: themed("solutionExplorerModalFormItemTitleColor");
        }

        .fileExplorerPathbar {
            background-color: #fff;
            div {
                float: left;
                span {
                    line-height: 32px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    max-width: 100px;
                    white-space: nowrap;
                    transition: all 200ms ease;
                    display: inline-block;
                    cursor: pointer;
                    user-select: none;

                    &:hover {
                        color: var(--primary-color-6);
                    }

                    &:active {
                        opacity: 0.6;
                    }
                }
                :global {
                    .bcIcon {
                        width: 8px;
                        height: 32px;
                        float: left;
                        margin: 0 10px;
                    }
                }
            }
        }

        .fileExplorerToolbar {
            li {
                :global {
                    .ant-btn {
                        border: solid 1px themed("fileExplorerToolbarItemBorderColor") !important;
                    }
                }
            }
        }

        .fileExplorerItem {
            .fileName {
                color: themed("fileExplorerItemFileNameTextColor");
            }

            &:hover {
                border-color: #9fa1a3;
            }
        }

        :global {
            .ant-btn-group {
                .ant-btn {
                    border: solid 1px themed("fileExplorerToolbarItemBorderColor") !important;
                }
            }
        }
    }
}
