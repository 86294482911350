:global {
    .ant-select-tree {
        padding: 4px 4px;

        li {
            margin: 0;

            span.ant-select-tree-switcher {
                vertical-align: text-bottom;
            }
        }
    }
}
