@import "../Components/dist/stylesheets/base/fonts-web-ui";

@import "mainstyles";

:global {
    .activeFlexPanel {
        .antListItem {
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1) !important;
            background-color: #fafafa !important;

            &:hover {
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26) !important;
                background-color: #ffffff !important;
            }
        }
    }

    .ms-OverflowSet-item:hover,
    .ms-OverflowSet-overflowButton:hover {
        border: 1px solid transparent !important;
        border-radius: 0px !important;
        background-color: initial !important;
    }

    .ms-OverflowSet-item {
        transition: none !important;
    }
}
.hasRibbon {
    height: calc(100% - 40px);
    :global {
        .ant-form {
            margin-top: 20px;
            .ant-form-item-label {
                text-align: left !important;
            }
            .ant-btn-sm {
                padding-left: 10px;
                padding-right: 10px;
                margin-right: 5px;
            }
            .ant-form-item {
                margin-bottom: 0;
                .ant-form-item-control {
                    .ant-form-item-children {
                        .ant-select-selection__choice__remove,
                        .anticon {
                            vertical-align: initial !important;
                        }
                        .ant-form-item-children-icon {
                            right: initial !important;
                        }
                        .ant-checkbox {
                            font-size: 13px;
                            .ant-checkbox-inner {
                                width: 15px;
                                height: 15px;
                                border: solid 1px #777;
                            }
                        }
                        .ant-checkbox-checked {
                            .ant-checkbox-inner {
                                border: solid 1px var(--primary-color-6) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.FlexPanelInnerListContainer {
    height: calc(100% - 59px);

    .boxSize70 {
        height: calc(70% - 10px);
    }

    .marginTop10 {
        margin-top: 10px;
    }

    .hasHeader,
    .hasFooter {
        height: calc(100% - 59px);
    }

    .width100 {
        width: 100%;
    }

    .subListItem {
        float: left;
        width: 100%;
        margin: 0 0 10px 30px;
    }

    .marginBottom0 {
        margin-bottom: 0px;
    }

    .menuBox {
        border-radius: 10px;
        height: 100%;

        .header {
            width: 100%;
            height: 59px;

            @include themify($themes) {
                border-bottom: solid 1px themed("dasboardBoxHeaderBorderColor");
            }

            .title {
                width: 100%;
                line-height: 59px;
                font-size: 18px;
                font-weight: 900;
                margin-left: 25px;

                @include themify($themes) {
                    color: themed("startupBoxHeaderTitleColor");
                }
            }
        }

        .content {
            width: 100%;
            height: 100%;

            :global {
                .ant-list-item {
                    padding: 0;

                    .ant-list-item-content {
                        flex-direction: column;
                        flex-flow: wrap;
                    }
                }
            }

            .listItem {
                margin: 5px 10px 5px 10px;
                padding: 0 8px 0 0px; // 0 8px 0 15px;
                border-radius: 6px;
                box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
                background-color: #ffffff;
                cursor: pointer;
                transition: all 200ms ease;
                transition-property: background-color, box-shadow;
                position: relative;
                border-left: solid 6px transparent;

                .actionButtons {
                    width: 30%;
                    opacity: 0;
                    transition: opacity 200ms ease;
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    transform: translate(0, -50%);

                    ul {
                        float: right;
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li {
                            :global {
                                .ant-btn {
                                    height: 20px;
                                    width: 80px;
                                    margin-bottom: 5px;

                                    span {
                                        display: block;
                                        font-size: 10px;
                                        font-weight: bold;
                                        text-align: center;
                                        color: #b4b4b4 !important;
                                    }
                                }

                                .ant-btn-primary {
                                    span {
                                        color: #ffffff !important;
                                    }
                                }
                            }
                        }
                    }
                }

                &:hover {
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                    background-color: #fafafa;

                    .listItemContainer {
                        .title,
                        .user,
                        .department {
                            color: #333d47;
                        }
                    }

                    &.hasButton {
                        &.info {
                            opacity: 0;
                        }

                        .actionButtons {
                            opacity: 1;
                        }
                    }
                }

                .listItemContainer {
                    float: left;
                    width: 100%;
                    line-height: 40px;
                    margin-left: 10px;

                    .title {
                        float: left;
                        width: 100%;
                        font-size: 14px;
                        font-weight: bold;
                        color: #777777;
                    }

                    .isViewed {
                        font-weight: 600 !important;
                    }

                    .user {
                        float: left;
                        font-size: 12px;
                        font-weight: normal;
                    }

                    .department {
                        float: left;
                        font-size: 12px;
                        font-weight: 300;
                        margin-left: 5px;
                    }
                }

                .leftInfo {
                    width: 15px;
                    height: 100%;
                    float: left;
                    border-radius: 6px 0px 0px 6px;
                }

                &.active {
                    background-color: #f5f5f5;
                    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);
                }

                &.activeBorder {
                    border-left-color: var(--primary-color-6);
                }

                .info {
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    transform: translate(0, -50%);
                    font-size: 12px;
                    font-weight: 900;
                    color: #4a90e2;
                    transition: opacity 200ms ease;
                }
            }
        }
    }
}

.ribbonBorderClass {
    @include themify($themes) {
        border-bottom: solid 1px themed("flexPanelActiveHeaderBorderColor");
        margin-top: 10px;
        height: 50px !important;
    }
}

.ThemedRibbonBottomBorder {
    @include themify($themes) {
        border-bottom: solid 1px themed("flexPanelActiveHeaderBorderColor");
    }
}
