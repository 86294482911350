:global {
    .ant-select {
        .ant-select-selection-item {
            .bcIcon {
                vertical-align: middle;
                width: auto;
                margin-top: 2px;
                margin-right: 5px;
            }
        }

        &.ant-select-open {
            .ant-select-arrow {
                .bcIcon {
                    transform: rotate(0);
                }
            }
        }

        .ant-select-clear {
            span {
                float: left;
            }
        }

        .ant-select-disabled.ant-select {
            &:not(.ant-select-customize-input) {
                .ant-select-selector {
                    color: rgba(0, 0, 0, 0.6) !important;
                }
            }
        }
    }

    .ant-select-dropdown {
        .ant-select-item {
            .ant-select-item-option-content {
                .bcIcon {
                    width: auto;
                    margin-top: 2px;
                    margin-right: 5px;
                }
            }
        }
    }
}

.ComboboxArrow {
    :global {
        .ant-select-selector {
            &:hover {
                z-index: initial !important;
            }

            .ant-select-selection-item {
                display: block;
                width: 100px;

                >span {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .ant-select-arrow {
            margin-top: 0;
            top: 50%;
            height: 12px;
            width: 12px;
            transform: translate(0, -50%);
            z-index: initial !important;

            .bcIcon {
                color: #777;
                transform: rotate(90deg);
                transition: all 200ms ease;
            }
        }
    }

    &.fromMobile {
        :global {
            .ant-select-clear {
                opacity: 1 !important;
                height: 30px;
                width: 30px;
                background: none;
                margin-top: -15px;

                .anticon-close-circle {
                    padding: 9px;
                }
            }
        }
    }
}

.ComboboxSelectItemContent {
    div {
        display: flex !important;
        align-items: center !important;

        span {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}