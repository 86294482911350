/// <reference path="../../stylesheets/main.scss" />

@import 'mainstyles';

.scrollbarThumb,
.scrollbarTrack {
    border-radius: 3.5px;
}

.scrollbarThumb {
    @include themify($themes) {
        background-color: themed('scrollbarThumbBackgroundColor');
    }

    &.horizontal {
        height: 8px !important;
    }

    &.vertical {
        width: 8px !important;
    }
}

.scrollbarTrack {
    @include themify($themes) {
        background-color: themed('scrollbarTrackBackgroundColor');
    }

    &.horizontal {
        height: 8px !important;
        bottom: 5px;
        left: 5px;
        right: 5px;
        z-index: 5;
    }

    &.vertical {
        width: 8px !important;
        right: 5px;
        top: 5px;
        bottom: 5px;
        z-index: 5;
    }
}