.BCDrawer {
    :global {
        .ant-drawer-close {
            padding: 23px;
        }

        .ant-drawer-content-wrapper {
            .ant-drawer-content{
                overflow: unset;
                background: #f4f4fa;
            }
        }
    }
    .drawerTitle {
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 30px;
    }
}
