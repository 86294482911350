/// <reference path="../../../../node_modules/@bimser/components/dist/stylesheets/main.scss" />
@import "mainstyles";

.openSelectionList {
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    float: left;
    background-color: rgb(250, 249, 248);
    margin: 5px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.16);
}

.CellWithIcon {
    display: flex;
    align-items: center;

    .Icon {
        width: auto;
        margin-right: 5px;
    }
}
