.DeployProgressContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 360px;
    padding: 10px;

    .DeployImage {
        height: 200px;
        width: 200px;
        margin-bottom: 20px;
    }

    .DeployStatus {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;

        .DeployStatusTitle {
            font-size: 15px;
            font-weight: 600;
            line-height: 24px;
        }

        .DeployStatusDescription {
            font-size: 12px;
            font-weight: 400;
            line-height: 24px;
        }
    }

    .CancelButton {
        height: 16px;
        width: 16px;
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
    }
}
