.ToggleButtonContainer {
    background: #f3f2f1;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px 10px;
    height: 2em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-radius: 2px;
    cursor: pointer;
    font-size: 13px;
    border: 1px solid #f3f2f1;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);

    :global {
        .bcIcon {
            height: 1.8em;
            width: 1.8em;
        }
    }

    svg {
        vertical-align: top !important;
    }

    &.sizeMedium {
        font-size: 16px;
    }

    &.sizeLarge {
        font-size: 18px;
    }

    > span {
        line-height: 2em;
        //color: white;
        user-select: none;
    }

    &.ToggleOn {
        background: #DFEDF9;
        border: 1px solid #0079D5;
    }
}
