/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

:global {
    @include themify($themes) {
        .ant-collapse {
            background: transparent;

            // .ant-collapse-content-box {
            //     float: left;
            //     width: 100%;
            // }

            &-item {
                background: transparent;
                border-color: themed("collapseHeaderBorderColor");
                margin-bottom: 1px;
                border: none;
                overflow: hidden;

                &:last-child {
                    border-bottom: none;
                }

                .ant-collapse-header {
                    .bcIcon.expandBtn {
                        display: block;
                        opacity: 0.5;
                    }
                    .bcIcon.collapseBtn {
                        display: none;
                        opacity: 0.5;
                    }
                }

                &-active {
                    .ant-collapse-header {
                        .bcIcon.expandBtn {
                            display: none;
                        }
                        .bcIcon.collapseBtn {
                            display: block;
                        }
                    }
                }
            }

            &-header {
                line-height: 32px !important;
                font-size: 14px;
                color: themed("collapseHeaderTextColor");
                padding: unset !important;
                .ant-collapse-header-text {
                    width: 100%;
                }
            }

            &-content {
                background-color: #fff;
                &-box {
                    padding: 0 0;
                }
            }
        }
    }
}

.header {
    width: 100%;
    height: 40px;
    user-select: none;
    background-color: #f6f6f6;

    p {
        float: left;
        max-width: 80%;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin: 0;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: normal;
        text-align: left;
        color: #25241f;
        margin-left: 17px;
        margin-top: 12px;
        @include themify($themes) {
            color: themed("collapseHeaderTextColor");
        }
    }

    :global {
        .bcIcon {
            width: 15px;
            height: 15px;
            float: right;
            transition: all 100ms ease;
            margin-top: 12.5px;
            margin-right: 12.5px;
            @include themify($themes) {
                color: themed("collapseHeaderIconColor");
            }

            svg {
                vertical-align: top;
            }
        }
    }
}
