/// <reference path="../../stylesheets/main.scss" />

@import "mainstyles";

.propertyInspector {
    width: 100%;

    .listItem {
        padding: 8px 20px;
        width: 100%;
        transition: background 200ms ease;
        overflow: hidden;

        &:hover {
            background: var(--primary-color-o-1);
        }

        .itemLabel {
            float: left;
            width: 140px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: start;
            label {
                &:nth-child(1) {
                    font-size: 13px;
                    margin-top: 1.5px;
                    margin: 0;
                    max-width: calc(100% - 10px);
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    float: left;
                    line-height: 32px;

                    @include themify($themes) {
                        color: themed("propertyInspectorLabelColor");
                    }
                }
                &:nth-child(2) {
                    line-height: 12px;
                }
            }

            :global {
                .bcIcon {
                    width: 16px;
                    margin: 0 5px;
                    display: inline-block;
                }
            }
        }

        .itemControl {
            float: left;
            width: calc(100% - 140px);

            /*text-align: right;*/
            :global {
                .ant-input-group {
                    position: unset !important;
                }

                button[role="switch"] {
                    margin-top: 6px;
                }
            }
            .dayContainer {
                display: inline-block;
                margin-right: 5px;
                span {
                    display: block;
                    text-align: center;
                }
            }
            .control {
                width: 100%;
            }

            .controlWithPromise {
                width: calc(100% - 35px);

                :global {
                    .ant-select-selector {
                        width: 100%;
                    }
                }
            }
            &.hasPopover {
                width: calc(100% - 161px);
            }
        }
    }

    &.propertyGroup {
        border: solid 1px #f0f0f0;
        margin: 5px 5px 15px 5px;
        width: calc(100% - 10px);
        position: relative;
        padding: 10px 0;
        transition: border 200ms ease;

        &:before {
            content: attr(data-title);
            position: absolute;
            top: -9px;
            left: 5px;
            background-color: #fff;
            color: #ccc;
            font-size: 12px;
            padding: 0 7px;
            transition: color 200ms ease;
        }

        &:hover {
            border: solid 1px #ddd;

            &:before {
                color: var(--primary-color-6);
            }
        }

        .listItem {
            padding: 8px 15px;
        }
    }
}

.propertySearchBox {
    margin: 10px;

    .propertySearchButton {
        width: auto !important;
    }
}

.informationBalloon {
    width: 100%;
}

.borderLabel {
    font-size: 12px;
    display: inline-block;
    text-align: center;
    float: left;
    margin: 0;
    width: 100%;

    @include themify($themes) {
        color: themed("propertyInspectorBorderLabelColor");
    }
}

.propertyColumn {
    width: calc(50% - 5px);
    margin: 0 10px 5px 0;
    display: inline-block;

    &:nth-child(2n + 2) {
        margin-right: 0;
    }
}

.clearButton {
    font-size: 6px;
    color: #fff;
    background: #bbb;
    border-radius: 100%;
    display: inline-block;
    width: 12px;
    height: 12px;
    line-height: 12px;
    margin: 5px 4px 0 4px;
    cursor: pointer;
    text-align: center;
}

.groupCombobox {
    :global {
        .ant-select-selection {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
}

:global {
    .propertyInpectorTooltip {
        .ant-tooltip-arrow {
            border-right-color: rgba(255, 255, 255, 0.8);
        }

        .ant-tooltip-inner {
            background: rgba(255, 255, 255, 0.8);
            color: #555;
            font-size: 12px;
        }
    }
}

.propertyInspectorMe {
    height: calc(100% - 58px);
}

.propertyTooltip {
    margin: 0;

    i {
        font-style: italic;
        opacity: 0.8;
        margin-right: 5px;
    }
}

.emptyInspector {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    font-family: Source Sans Pro, sans-serif;
    margin-top: 25px;

    .headerText {
        font-size: 14px;
        font-weight: bolder;
        display: block;
        text-align: center;
    }

    .subText {
        font-size: 12px;
        text-align: center;
        display: block;
    }

    img {
        width: 300px;
        height: 300px;
        object-fit: cover;
    }
}

.InputNumberAddOnContainer {
    &:hover {
        .inputNumber {
            border-color: var(--primary-color-5);
            border-right-width: 1px !important;
            :global {
                .ant-input-number-handler-wrap {
                    opacity: 1 !important;
                }
            }
        }
    }
    .inputNumber {
        width: 100%;
        :global {
            .ant-input-number-handler-wrap {
                right: 70px;
                border-right: 1px solid #d9d9d9;
            }
        }
    }

    .comboBox {
        width: 70px;
        margin: 0px 0px 0 -70px;
        position: absolute;
        border: none;
        padding: 1px 2px 2px 0px;

        :global {
            .ant-select-selector {
                border: none !important;
                box-shadow: none !important;
                height: 30px;
            }
        }
    }
}

.itemDropdown {
    width: 16px;
    float: left;
    margin: 8px 0 0 5px;
    :global {
        .bcIcon {
            cursor: pointer;
        }
    }
}

.dropdownTrigger {
    width: 10px;
    height: 10px;
    margin: 3px;
    border: solid 1px #d9d9d9;
    transition: border-color 200ms ease;
    cursor: pointer;
    &:hover {
        border-color: var(--primary-color-6);
    }
}

.dropdownOverlay {
    :global {
        .ant-popover-arrow {
            display: none;
        }
        .ant-popover-title {
            font-size: 12px;
            font-weight: bold;
            border-radius: 0;
        }
        .ant-popover-inner-content {
            padding: 5px;
            border-radius: 0;
        }
        .ant-btn {
            width: 100%;
            text-align: left;
            .bcIcon {
                margin-right: 10px;
            }
            span {
                font-size: 12px;
            }
        }
    }
}

.popoverDivider {
    margin: 4px 0;
}

.menu {
    border-right: none;
}

.inputSecurity {
    -webkit-text-security: disc !important;
}

.selectedFieldDivider {
    margin: 0 !important;
}

.addIcon {
    height: 22px !important;
}
