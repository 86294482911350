@import "mainstyles";

.datePickerStyles {
    &.textalignRight {
        :global {
            .ant-picker-input {
                input {
                    text-align: right !important;
                }
            }
        }
    }
    &.textalignLeft {
        :global {
            .ant-picker-input {
                input {
                    text-align: left !important;
                }
            }
        }
    }
    &.textalignCenter {
        :global {
            .ant-picker-input {
                input {
                    text-align: center !important;
                }
            }
        }
    }
}
