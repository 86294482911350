/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

.treeViewContainer {
    height: 100%;

    .treeViewToolbar {
        float: left;
        padding: 0 10px 14px 16px;
        width: 100%;
        overflow: hidden;
        border-bottom: 1px solid #efefef;

        .treeViewSearchBox,
        .treeViewerActionButtons {
            float: left;
        }

        .refreshButtonStyle {
            display: flex;
            .refreshIconStyle {
                height: 24px;
                width: 35px;
            }
        }

        &.refreshButtonWithoutSearch {
            height: 40px;
            padding: 5px 10px 5px 9px !important;

            .refreshButtonStyle {
                display: flex;
                height: 100%;
                align-items: center;
                .refreshIconStyle {
                    height: 24px;
                    width: 35px;
                }
            }

            &:hover {
                background-color: #efefef;
                transition-duration: 0.2s;
                transition-property: background-color;
            }
        }

        :global {
            .ms-OverflowSet-item,
            .ms-OverflowSet-overflowButton {
                border: solid 1px #ccc !important;
                border-radius: 5px;
                margin-right: 5px;
                margin-top: -4px;

                button {
                    transition: background-color 200ms ease;
                    outline: none !important;

                    .ms-Button-icon {
                        transition: color 200ms ease;

                        @include themify($themes) {
                            color: themed("toolbarTextColor");
                        }
                    }

                    &:hover,
                    &:focus {
                        color: var(--primary-color-6);

                        @include themify($themes) {
                            background: rgba(themed("toolbarButtonHoverColor"), 0.05);
                        }

                        .ms-Button-icon {
                            @include themify($themes) {
                                color: var(--primary-color-6);
                            }
                        }
                    }

                    &:active {
                        @include themify($themes) {
                            background: rgba(themed("toolbarButtonHoverColor"), 0.1);
                        }
                    }
                }
            }

            .ms-OverflowSet-overflowButton {
                margin-right: 0;
            }
        }
    }

    .treeViewNodes {
        padding: 5px 0px 0px 0px;
        height: 100%;
        clear: both;
        :global {
            .ant-tree {
                .ant-tree-list {
                    .ant-tree-list-holder-inner {
                        .ant-tree-treenode-selected {
                            background-color: var(--primary-color-2);
                            -webkit-transition-duration: 0.4s;
                            transition-duration: 0.4s;
                            -webkit-transition-property: background-color;
                            transition-property: background-color;
                            .ant-tree-node-selected {
                                background-color: unset;
                            }
                        }
                        .ant-tree-treenode {
                            &:hover {
                                background-color: #efefef;
                                -webkit-transition-duration: 0.4s;
                                transition-duration: 0.2s;
                                -webkit-transition-property: background-color;
                                transition-property: background-color;
                            }
                            .ant-tree-node-content-wrapper {
                                margin-left: -7px;
                            }
                        }
                        .ant-tree-node-content-wrapper:hover {
                            background-color: unset;
                        }
                    }
                }
            }
        }
    }

    &.hasActionButtons,
    &.hasSearchbar {
        .treeViewNodes {
            height: 100%;
        }
    }

    &.hasSearchbar {
        .treeViewSearchBox {
            width: calc(100% - 20px);
            margin: 0 10px;
        }
    }

    &.hasActionButtons {
        .treeViewerActionButtons {
            width: 100%;
        }
    }

    &.hasActionButtons.hasSearchbar {
        .treeViewSearchBox {
            margin: 0 0 0 10px;
        }
    }
}

:global {
    .ant-tree {
        background: unset;

        .ant-tree-treenode {
            width: 100%;
            height: 40px;
            padding: 4px 0px 0px 10px;
            .bcIcon {
                vertical-align: middle;
            }
        }

        .ant-tree-switcher {
            height: 33px;
            span.anticon {
                line-height: 33px;
            }
        }

        .ant-tree-node-content-wrapper {
            width: calc(100% - 30px);
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            span {
                user-select: none;
            }
            .ant-tree-title {
                span.label {
                    display: unset !important;
                    line-height: 32px;
                }
            }
        }
    }
}

.SearchedValueHighlight {
    & > span {
        color: #f50;
    }
}

.TreeviewRowArrowIcon {
    width: 32px;
    height: 32px;
    padding: 8px;
    position: relative;
    z-index: 99;
}

.TreeviewTreeNodeIcon {
    width: 32px;
    height: 32px;
    padding: 0px 8px 0 5px;
}
