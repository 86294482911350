/// <reference path="../../stylesheets/main.scss" />

@import "mainstyles";

.menubar {
    line-height: 43px !important;
    overflow: hidden;
    position: relative;
    z-index: 2;
    padding: 0 20px;

    :global {
        .ant-menu {
            background: transparent;
        }
        .ant-menu.ant-menu-horizontal {
            & > .ant-menu-item {
                .bcIcon {
                    display: none;
                }

                .menuItemShortcutText {
                    display: none;
                }
            }

            & > .ant-menu-submenu {
                & > .ant-menu-submenu-title {
                    .bcIcon {
                        display: none;
                    }

                    .menuItemShortcutText {
                        display: none;
                    }
                }
            }
        }
    }
}
