.smModalContent {
    padding: 0;
}

.SignatureModalContent {
    --treeItemHeight: 48px;
    --treeItemMargin: 5px;
    --cardHeight: calc(var(--treeItemHeight) - (2 * var(--treeItemMargin)));
    :global {
        .ant-modal-close {
            top: 8px !important;
        }
        .ant-modal-body {
            height: 400px;
        }

        .ant-avatar-string {
            font-size: 10px;
        }

        .ant-tree-node-content-wrapper {
            width: 100%;
            margin-left: 0 !important;
        }

        .ant-tree-treenode {
            height: var(--treeItemHeight);
            border-bottom: 1px solid #f3f2f1;
        }

        .ant-tree-switcher {
            display: none;
        }

        .ant-tree-treenode:hover {
            background-color: #eeedeb !important;
        }
    }

    .signatureContainer {
        .SignatureIcons {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }
        .signatureHeader {
            height: 44px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #ebebeb;
        }
        .signatureDescription {
            height: 48px;
            display: flex;
            border: 1px solid #b7ecb3;
            background-color: #e0f6de;
            border-radius: 2px;
            padding: 4px;
            .signatureDescriptionText {
                flex: 9;
                margin-left: 15px;
                line-height: 40px;
            }
            .signatureDescriptionMore {
                flex: 1;
                line-height: 44px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding-right: 10px;
            }
        }
        .signatureBody {
            height: 318px;
            width: 100%;

            .treeHeader {
                display: flex;
                height: 42px;
                line-height: 42px;
                border-bottom: 1px solid #e2e2e2;
                padding-left: 20px;
                .headerSigner {
                    width: calc(100% - 300px);
                }
                .headerDate {
                    width: 150px;
                }
                .headerStatus {
                    width: 50px;
                }
            }
            .treeBody {
                height: calc(100% - 42px);
                .signatureTreeContainer {
                    margin-right: 20px;

                    .signatureCard {
                        padding: 5px;
                        height: var(--cardHeight);
                        margin: var(--treeItemMargin);
                        margin-left: 10px;
                        display: flex;

                        .signatureInfo {
                            width: 100%;
                            display: flex;
                            .infoColumnName {
                                width: calc(100% - 275px) !important;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                            .infoColumnDate {
                                width: 280px;
                                position: absolute;
                                right: 0;
                                display: flex;
                                .infoDate {
                                    width: 150px;
                                }
                                .infoStatus {
                                    width: 50px;
                                }
                            }
                        }

                        .signatureDetails {
                            visibility: hidden;
                            .detailIcon {
                                height: 24px;
                                width: 24px;
                            }
                        }

                        &:hover {
                            .signatureDetails {
                                visibility: visible;
                            }
                        }
                    }
                }
            }
        }
    }
}
.SignatureValidationContainer {
    width: 560px;
    height: 400px;
    display: flex;
    flex-direction: row;
    .validationIcon {
        width: 100px;
        height: 70px;
    }
    .validationData {
        width: 460px;
        display: flex;
        flex-direction: column;
        .validationDescription {
            padding: 14px 0;
        }
        .SignatureValidationContent {
            height: 350px;
            border: 1px solid #a3a19f;
            background: #fff;
            .validationContent {
                display: flex;
                flex-direction: column;
                padding: 15px;
                height: 350px;
            }
        }
    }
}

.SignatureDetailsContent,
.SignatureValidationContent {
    display: flex;
    flex-direction: column;
    padding: 15px;

    span {
        border-bottom: 1px solid #f3f2f1;
        padding: 5px;
    }
    .invalidItem {
        color: red;
    }
}

.DropdownMenuItem {
    display: flex;
    align-items: center;
    width: 150px;

    .SignatureIcons {
        margin-top: 2px;
        margin-right: 10px !important;
    }
}

.SignatureRibbon {
    height: 44px;
    padding: 0px 10px;
    :global {
        .ms-OverflowSet,
        .ms-CommandBar {
            height: 44px !important;
        }

        .ms-Button {
            &:hover {
                background-color: #f3f2f1 !important;
            }
            .ms-Button-label {
                color: #6f6e6c !important;
            }
        }
    }
}

.SignatureRibbonSub {
    :global {
        .ms-ContextualMenu-item {
            height: 40px;
            .ms-ContextualMenu-link {
                height: 40px;
                &:hover {
                    background-color: #f3f2f1 !important;
                }
            }
            .bcIcon {
                margin-right: 10px;
            }
            .ms-ContextualMenu-itemText {
                line-height: 40px;
                color: #6f6e6c !important;
            }
            .is-disabled {
                .ms-ContextualMenu-itemText {
                    color: #9b9a97 !important;
                }
            }
        }
    }
}

.customEmpty {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .emptyImg {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        max-width: 140px;
        margin-bottom: 10px;
    }

    .emptyText {
        display: flex;
        flex-direction: column;
        align-items: center;
        .emptyTitle {
            color: #201f19;
            font-weight: 600;
            font-size: 13px;
        }
        .emptyDesc {
            font-size: 11px;
            font-weight: 400;
            color: #9b9a97;
        }
    }
}
