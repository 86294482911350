.picker {
    width: 100%;

    :global {
        .ant-picker-input {
            .ant-input {
                text-align: center;
            }
        }
    }
}
