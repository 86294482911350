.DigitalSignatureContainer {
    width: 100%;

    :global {
        .ant-spin-container {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .DSErrorMessage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 9;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .MessageContent {
            font-family: Segoe WP;
            font-size: 13px;
            font-weight: 600;
            padding: 10px;
        }

        .ErrorCode {
            font-family: Segoe WP;
            font-size: 12px;
            font-weight: 300;
            padding: 10px;
        }
    }

    .DSConfirmationMessage {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        z-index: 9;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-family: Segoe WP;

        .MessageTitle {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        .MessageContent {
            font-size: 13px;
            font-weight: 600;
            padding: 10px;
            margin-bottom: 20px;
        }

        .MessageButtons {
            width: 200px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            align-items: center;
        }
    }

    .DSWarningText {
        font-family: Segoe WP;
        font-size: 12px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        letter-spacing: 0.24px;
        text-align: left;
        color: #25241f;
        margin-top: 10px;
    }

    .DSContent {
        min-height: 561px !important;

        .DSPincode {
            padding: 10px 20px;
            border-left: 1px solid #d2d2d2;
        }

        .DSCardInfo {
            padding: 10px 30px;
            width: calc(100% - 221px);

            .CompactPinCode {
                border-bottom: 1px solid #ebebeb;
            }

            .DSOption {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                .DSOptionLabel {
                    font-family: Segoe WP;
                    font-size: 13px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.2;
                    letter-spacing: normal;
                    text-align: left;
                    color: #25241f;
                    margin-bottom: 10px;
                }

                .DSOptionContent {
                    width: 100%;
                    display: flex;
                    justify-content: center;

                    .OptionIcon {
                        width: 29px;
                        height: 32px;
                        border: 1px solid #d9d9d9;
                        background: #fff;
                        cursor: pointer;

                        &:hover {
                            background: #f2f2f2;
                        }
                    }
                    :global {
                        .ant-select {
                            width: calc(100% - 30px);
                        }
                    }
                }
            }

            .DSCertificateInfo {
                .DSCertInfoLabel {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;

                    .CertLabelText {
                        font-family: Segoe WP;
                        font-size: 13px;
                        font-weight: normal;
                        font-stretch: normal;
                        font-style: normal;
                        line-height: 1.2;
                        letter-spacing: normal;
                        text-align: left;
                        color: #25241f;
                    }
                }

                .DSCertInfoContent {
                    background-color: #fbfbfa;
                    border: 1px solid #e5e4e2;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;

                    .CertInfoItem {
                        display: flex;
                        align-items: center;
                        padding: 5px;
                        .CertInfoItemLabel {
                            width: 100px;
                            font-family: Segoe WP;
                            font-size: 13px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.2;
                            letter-spacing: normal;
                            text-align: left;
                            color: #25241f;
                        }
                        .CertInfoItemValue {
                            max-width: calc(100% - 100px);
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-family: Segoe WP;
                            font-size: 13px;
                            font-weight: normal;
                            font-stretch: normal;
                            font-style: normal;
                            line-height: 1.2;
                            letter-spacing: normal;
                            text-align: left;
                            color: #25241f;
                        }
                    }
                }
            }

            .DSOptions {
                padding: 10px;
                display: flex;
                flex-direction: column;
                :global {
                    .ant-checkbox-wrapper {
                        margin-top: 5px;
                    }
                }
                .optionItem {
                    display: flex;
                    padding: 5px 0;
                    .optionLabel {
                        flex: 3;
                    }
                    .optionContent {
                        flex: 6;
                    }
                }
            }
        }
    }

    &.CompactMode {
        width: 100%;
        height: 500px;
        position: relative;
        overflow: auto;

        .DSCardInfo {
            width: 100%;
            padding: 10px 0px;
        }
    }
}

@media (max-height: 823px) {
    .CompactMode {
        height: 255px !important;
        overflow: auto;
    }
}

.digitalSignatureModalStyles {
    :global {
        .ant-modal-content {
            .ant-modal-footer {
                display: none !important;
            }
        }
    }

    .CompactMode {
        height: 100% !important;

        .DSContent {
            min-height: auto !important;
        }
    }
}

.modalContentWrapperStyles {
    padding: 0px;
}