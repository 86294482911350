.eSignatureContainer {
    height: 364px;
    display: flex;
    flex-direction: column;
    :global {
        .ant-select {
            width: 100%;
        }
    }

    .optionItem {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 32px;
        line-height: 32px;
        margin-top: 5px;

        .optionLabel {
            flex: 1;
        }

        .optionContent {
            flex: 3;
        }
    }

    .subject {
        //height: 40px;
    }
    .signerListWrapper {
        height: 300px;

        .signerItem {
            width: 100%;
            margin: 5px;
            display: flex;
            flex-direction: row;
            padding: 5px 0;
            // box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
            // border-radius: 3px;
            :global {
                .ant-input {
                    margin-top: 5px;
                }
            }
            .signerLabel {
                flex: 1;
            }

            .signerContent {
                flex: 5;
                border-top: 1px solid #f3f2f1;
                display: flex;
                flex-direction: column;
                padding-left: 10%;
                margin-top: 12px;
                padding-top: 10px;
            }
        }
    }
}
