/// <reference path="../../stylesheets/main.scss" />

@import "mainstyles";

.contextMenu {
    box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.1);

    :global(.ant-menu) {
        min-width: 201px;

        li {
            span {
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: 0.28px;
                text-align: left;
                color: #252420;
                display: flex;
                align-items: center;
            }
        }

        @include themify($themes) {
            border-right: none;
            background-color: themed("menubarBackgroundColor");
            padding: 10px 0;
            // box-shadow: 0 6px 16px 0 themed("menubarDropdownShadowColor");
        }
    }

    :global {
        .ant-menu {
            .ant-menu-item-disabled,
            .ant-menu-submenu-disabled {
                opacity: 0.5;
            }
        }

        .splitLeftItem {
            width: calc(100% - 45px) !important;
            float: left;
        }
        .splitRightItem {
            float: left;
            width: 45px;
        }
        .bcIcon {
            margin-right: 12px !important;
        }
    }

    &::-webkit-scrollbar-track {
        border-radius: 3.5px;
        @include themify($themes) {
            background-color: themed("scrollbarTrackBackgroundColor");
        }
    }

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: #ededed;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 3.5px;
        @include themify($themes) {
            background-color: themed("scrollbarThumbBackgroundColor");
        }
    }
}

.subMenuContextMenu {
    :global {
        .ant-menu-submenu {
            .ant-menu-submenu-title{
                margin-top: 0px ;
            }
        }
    }
}