/// <reference path="../../../../node_modules/@bimser/components/dist/stylesheets/main.scss" />
@import 'mainstyles';

.IframeContainer {
    float: left;
    width: 100%;
    height: 100%;

    :global {
        iframe {
            .centerContainer {
                background-color: #fafafa;
            }

            .headerButton {
                display: none;
            }
        }
    }
}
