/// <reference path="../../stylesheets/main.scss"/>
@import 'mainstyles';

.hasCustomColor {
    :global {
        .ant-radio {
            & + span {
                color: inherit !important;
            }
        }
    }
}

:global {
    @include themify($themes) {
        .ant-radio {
            vertical-align: middle;

            & + span {
                color: var(--icon-secondary-color);
                white-space: pre-line;
            }
        }

        .ant-radio .ant-radio-inner {
            background-color: transparent;
            border: solid 2px var(--primary-color-6);
            width: 18px;
            height: 18px;
        }

        .ant-radio {
            &.ant-radio-checked {
                .ant-radio-inner {
                    background-color: transparent;
                    /*&:after {
                        left: 4px !important;
                    }*/
                }
            }
        }
    }
}

.horizantal {
    display: inline-block;
}

.vertical {
    display: block;
    line-height: 30px;
}
