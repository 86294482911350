@import "mainstyles";

@keyframes fade {
    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.UploadHelperWrapperButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    height: 46px;
}

.UploadHelperWrapper {
    background: transparent;
    z-index: 1000;
    line-height: 40px !important;

    :global {
        .ant-badge {
            margin: 10px;
            .ant-badge-count {
                top: 5px !important;
            }
        }
        .ant-popover-inner-content {
            padding: 12px 16px;
        }

        .ant-list-item {
            padding: 10px 16px;

            &:hover {
                background: #edf4fc !important;
            }
        }
    }
}

.MinimizedView {
    background: transparent;
    cursor: pointer;
    :global {
        .ant-progress-text {
            font-size: 11px;
            font-weight: bold;
            opacity: 1;
            animation: fade 0.2s linear;
        }
    }
}

.MaximizedView {
    height: 400px;
    width: 425px;
    background: white;

    .Header {
        height: 42px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #f2f2f2;
        padding: 0 10px;
        span {
            width: unset !important;
            font-weight: bold;
        }
    }

    .Content {
        height: calc(100% - 84px);
        margin: 0 -16px;

        :global {
            .ant-list-item {
                padding: 0;
            }
        }

        .UploadFileItem {
            //border: 1px solid #f2f2f2;
            border-radius: 5;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            width: 100%;
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            :global {
                .ant-menu {
                    width: 200px;
                    min-width: unset;
                }

                .ant-menu-item {
                    line-height: 30px;
                    height: 30px;
                    padding: 0 10px;
                    display: flex;
                    align-items: center;

                    .bcIcon {
                        margin-top: 0 !important;
                    }
                }
            }

            .Progress {
                width: 80%;
                display: flex;
                flex-direction: column;
                padding: 0 10px;

                .NameToolTip {
                    :global {
                        .ant-tooltip-content {
                            .ant-tooltip-inner {
                                background-color: rgba(51, 61, 71, 1) !important;
                            }
                        }
                    }
                }

                .FileName {
                    font-weight: bold;
                    text-overflow: ellipsis;
                    width: 280px;
                    overflow: hidden;
                }

                .UploadPath {
                    width: 280px;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-style: italic;
                }
                :global {
                    .ant-progress-status-success {
                        .ant-progress-bg {
                            background-color: #8c8d8f;
                        }
                    }
                    .ant-progress-text {
                        color: #8c8d8f;
                    }
                }
            }

            .Buttons {
                display: flex;
                flex-direction: row;
                width: 5%;
            }

            .More {
                cursor: pointer;
                display: none;
                transform: rotate(90deg);
            }

            .MoreOpened {
                display: inline-block;
            }

            &:hover {
                background-color: #edf4fc;
                .More {
                    display: inline-block;
                }
            }

            .Preparing {
                display: flex;
                flex-direction: row;
                > div {
                    width: unset;
                }
                span {
                    margin-right: 10px;
                }
            }

            .Done {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .Footer {
        height: 50px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        border-top: 1px solid #f2f2f2;
        padding: 10px 16px;

        :global {
            .ant-btn-group {
                .ant-btn {
                    line-height: initial !important;
                }
            }
            .ant-btn {
                width: 100px;
            }
            .ant-menu {
                background-color: white;
            }

            .bcIcon {
                cursor: pointer;
            }
        }
    }
}

.actions {
    float: right;
    display: flex;
    align-items: center;
    .button {
        float: left;
        width: 20px;
        height: 20px;
        cursor: pointer;
        border: solid 1px #b4b4b4;
        border-radius: 100%;
        margin-left: 5px;
        line-height: 16px;
        text-align: center;
        transition: all $transition-duration $transition-timing-function;
        transition-property: background-color, border-color;

        :global {
            .bcIcon {
                width: 12px;
                height: 18px;
                transition: all $transition-duration $transition-timing-function;

                @include themify($themes) {
                    color: themed("flexPanelActionIconColor");
                }

                svg {
                    float: left;
                }
            }
        }

        &.close {
            :global {
                .bcIcon {
                    width: 8px;
                }
            }
        }

        &.pinned {
            background-color: var(--golden-color);
            border: solid 1px var(--golden-color);

            :global {
                .bcIcon {
                    transform: rotate(-45deg);
                    color: #fff;
                }
            }
        }

        &.pinned,
        &.maximized {
            @include themify($themes) {
                border-color: themed("flexPanelActionColor");
            }

            :global {
                .bcIcon {
                    @include themify($themes) {
                        color: themed("flexPanelActionColor");
                    }
                }
            }

            &:hover {
                :global {
                    .bcIcon {
                        color: #fff;
                    }
                }
            }
        }

        &:hover {
            @include themify($themes) {
                border-color: var(--golden-color);
                background-color: var(--golden-color);
            }

            :global {
                .bcIcon {
                    color: #fff;
                }
            }
        }

        &:active {
            @include themify($themes) {
                border-color: var(--golden-color-l-10);
                background-color: var(--golden-color-l-10);
            }

            :global {
                .bcIcon {
                    color: #fff;
                    opacity: 0.8;
                }
            }
        }
    }
}
