/// <reference path="../../../stylesheets/main.scss" />

@import "mainstyles";

.breadCrumbNavigationContainer {
  float: left;
  padding: 0 0 0 17px;
  height: 46px;
  line-height: 46px;
  position: relative;
  z-index: 1;

  .navItem {
    float: left;
    width: 50px;
    height: 46px;
    line-height: 46px;
    cursor: pointer;
    text-align: center;
    line-height: 100%;
    transition: background-color $transition-duration $transition-timing-function;
    user-select: none;

    :global(.bcIcon) {
      width: 16px;
      height: 46px;
      line-height: 100%;
      vertical-align: middle;
      transition: all 0.1s ease;
      transition: color $transition-duration $transition-timing-function;
    }

    &.disable {
      cursor: default;
      background-color: transparent !important;
      opacity: 0.3;
    }

    &.home {
      background: transparent;
      padding: 0 10px !important;

      &.disable {
        background-color: transparent;
      }

      :global(.bcIcon) {
        width: 18px;
        height: 46px;
      }
    }

    &.left,
    &.right,
    &.home {
      &:not(.disable) {
        &:hover {
          background-color: rgba(255, 255, 255, 0.3) !important;
        }

        &:active {
          background-color: rgba(255, 255, 255, 0.6) !important;
        }
      }
    }
  }
}

.breadcrumbContainer {
  float: left;
  width: calc(100% - 190px - 155px);
  height: 100%;
  position: relative;
  z-index: 1;

  @include breakpoint(sm) {
    width: 100%;
    padding: 0 15px;
  }

  .breadcrumb {
    width: 100%;
    margin: 0 !important;
  }
}
