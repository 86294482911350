/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

:global {
    @include themify($themes) {
        .ant-calendar-picker {
            min-width: unset !important;

            .ant-calendar-picker-icon {
                &:after {
                    color: themed("inputColor");
                }
            }

            .ant-calendar-picker-clear {
                color: themed("inputColor");
                background: themed("inputBackgroundColor");
            }
        }
        .ant-picker-range {
            .ant-picker-clear {
                right: 30px;
            }
        }
    }

    .ant-calendar .ant-calendar-ok-btn {
        color: #fff !important;
    }

    .ant-picker-ranges .ant-picker-ok {
        button {
            > span {
                text-transform: capitalize;
            }
        }
    }
}

.dateRangePickerStyles {
    &.textalignRight {
        :global {
            .ant-picker-input {
                input {
                    text-align: right !important;
                }
            }
        }
    }
    &.textalignLeft {
        :global {
            .ant-picker-input {
                input {
                    text-align: left !important;
                }
            }
        }
    }
    &.textalignCenter {
        :global {
            .ant-picker-input {
                input {
                    text-align: center !important;
                }
            }
        }
    }
}
