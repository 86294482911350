:global {

    .ant-popover-placement-bottom,
    .ant-popover-placement-bottomLeft,
    .ant-popover-placement-bottomRight {
        padding-top: 0 !important;
    }
}

.hrFormMLPopover {
    width: 390px;
    height: 200px;
    padding-right: 10px;
    overflow: hidden;



    .pdR5 {
        padding-right: 5px;
    }

    .popoverRow {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        label {
            font-size: 12px;
            width: 100%;
            margin: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}