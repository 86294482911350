.userSelectContentWrapper {
    display: flex;
    justify-content: flex-start;
    font-family: Segoe WP;
    min-height: 107px;
    align-items: center;

    .contentMessage {
        margin: 10px 0;
        font-size: 13px;
        font-weight: normal;
        color: #25241f;
    }

    .loginAccountCombobox {
        width: 100% !important;
        margin-top: 5px;
    }

    .userAutoComplete {
        :global {
            .ant-select-selection {
                height: 32px !important;
            }
        }
    }
}

.autoCompleteItem {
    .itemText {
        margin-left: 10px;
        color: #25241f;
        font-size: 14px;
        font-weight: 300;
    }
}
.groupTitle {
    font-family: Segoe WP;
    font-size: 16px;
    font-weight: 600;
    color: #252422;
}
.autoCompleteDropdown {
    :global {
        .ant-select-dropdown-menu-item {
            &:hover {
                background-color: #f4f4f4 !important;
            }
        }
    }
}
