@import "mainstyles";

.SecurityContainer {
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    flex-direction: row;
    background-color: #faf9f8;
    margin: 5px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.16);

    .SecurityToolbar {
        height: 49px;
        border-bottom: 2px solid #f2f2f2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 10px;

        .searchInput {
            -webkit-transition: width 1s ease-in-out;
            -moz-transition: width 1s ease-in-out;
            -o-transition: width 1s ease-in-out;
            transition: width 1s ease-in-out;

            &:hover {
                width: calc(100% - 185px) !important;
            }

            :global {
                .ant-input-prefix {
                    left: 0px;
                }
            }
        }

        .searchInputEnable {
            width: calc(100% - 185px) !important;
        }

        .searchInputDisable {
            width: 47px !important;
        }

        .securityRibbonBar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 32px !important;

            :global {
                .ms-FocusZone {
                    padding: 0;
                    height: 32px !important;

                    .ms-OverflowSet {
                        height: 32px;

                        [class*="overflowButton-"] {
                            margin-left: auto;
                            justify-self: flex-end;
                        }
                    }
                }
            }
        }

        :global {
            .ant-input-affix-wrapper {
                height: 32px;
                width: 100%;

                .ant-input {
                    color: #777777 !important;
                }
            }

            .ant-btn {
                font-family: "Source Sans Pro", sans-serif;
                color: #777777;
                padding: 0 !important;
                box-shadow: none !important;
                display: flex;
                align-items: center;

                span {
                    color: #777777 !important;
                }
            }
        }
    }

    .SecurityLeftWrapper {
        width: 40%;

        :global {
            .loadingOverlay {
                height: calc(100% - 55px);
            }

            .ide {
                margin: 0;
            }
        }

        .TreeWrapper {
            padding-top: 20px;

            .TreeItem {
                width: 100%;
                display: flex;

                span {
                    font-family: "Source Sans Pro", sans-serif;
                    font-size: 14px;
                    margin-left: 5px;
                    color: #777;
                }

                span:first-of-type {
                    width: calc(100% - 122px);
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .modifyIconSet {
                    visibility: hidden;
                }

                &:hover .modifyIconSet {
                    visibility: visible;
                }
            }

            li {
                border-bottom: 2px solid #f8f8f8;
                line-height: 50px;

                ul {
                    background: #f8f8f8;
                }

                :global {
                    .ant-tree-node-content-wrapper {
                        background: unset !important;
                        line-height: 50px;
                        height: 50px;
                        padding-left: 10px;
                        width: calc(100% - 24px);

                        .ant-tree-title {
                            width: 100%;
                        }
                    }

                    .ant-tree-switcher {
                        background: unset !important;
                        line-height: 50px;
                        height: 50px;
                        padding-left: 10px;
                    }
                }
            }

            :global {
                .ant-tree-switcher {
                    i {
                        font-size: 14px;
                    }
                }

                .ant-tree-switcher_close {
                    .bcIcon {
                        transform: rotate(0deg);
                    }
                }
            }
        }
    }

    .PermissionTabTitle {
        padding: 20px 20px 0 20px;

        >span {
            color: #777777;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 14px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.21;
            letter-spacing: normal;
            text-align: left;
            max-height: 65px;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            display: -webkit-box;
            word-break: break-all;
            margin-bottom: 10px;
        }
    }

    .PermissionTabText {
        text-align: left;
        line-height: 25px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        background: rgba(74, 144, 226, 0.2);
        border-radius: 2px;

        >span {
            margin-left: 20px;
            color: #777777;
            margin-left: 20px;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 14px;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.21;
            letter-spacing: normal;
            text-align: left;
        }
    }

    .SecurityRightWrapper {
        width: 60%;
        height: 100%;
        border-left: 2px solid #f8f8f8;

        :global {
            .ant-tabs {
                height: 100%;

                .ant-tabs-tab {
                    padding: 11px 16px 11px 0px !important;
                }

                .ant-tabs-content {
                    .ant-tabs-tabpane {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .ant-tabs-nav-wrap {
                    background: #fff;
                    padding-left: 0px;
                    border-bottom: 2px solid #f2f2f2;
                    margin-bottom: 0;
                    height: 49px;
                }
            }
        }

        .Skeleton {
            width: calc(100% - 20px);
            height: calc(100% - 20px);
            padding-top: 20px;
            padding-left: 20px;
        }

        .TabContent {
            height: calc(100% - 60px);
            width: 100%;
            margin-left: 5px;
            padding-left: 10px;
            padding-top: 10px;
            margin-top: -5px;

            .Footer {
                height: 50px;
                margin-left: 10px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                :global {
                    .ant-btn {
                        margin-right: 10px;
                    }
                }
            }
        }

        .Footer {
            :global {
                .BCRibbonBar {
                    .ms-FocusZone {
                        .ms-OverflowSet {
                            // height: 50px;
                            display: -webkit-box;
                            display: -ms-flexbox;
                            display: flex;
                            -webkit-box-orient: horizontal;
                            -webkit-box-direction: normal;
                            -ms-flex-direction: row;
                            flex-direction: row;
                            -webkit-box-pack: justify;
                            -ms-flex-pack: justify;
                            justify-content: space-between;
                            -webkit-box-align: center;
                            -ms-flex-align: center;
                            /* align-items: center; */
                            -ms-flex-wrap: wrap;
                            /* flex-wrap: wrap; */
                            /* width: 37%; */
                            padding: 0 10px 0 10px;
                            bottom: 26px;

                            .ms-OverflowSet-item {
                                .ant-btn {
                                    margin-right: 10px;
                                }
                            }
                        }
                    }
                }
            }

            // :global {
            //     .ms-OverflowSet-item {
            //         .ant-btn {
            //             margin-right: 0 !important;
            //         }
            //     }
            // }
        }

        .RightTreeWrapper {
            height: calc(100% - 120px);
            display: flex;
            flex-grow: 1;
            margin-bottom: 38px;

            >div {
                margin-right: 20px;

                .TreeItem {
                    span {
                        font-family: "Source Sans Pro", sans-serif;
                        font-size: 14px;
                        margin-left: 5px;
                        color: #777;
                    }
                }

                :global {
                    .ant-tree {
                        .ant-tree-treenode {
                            line-height: 32px;

                            .ant-tree-node-content-wrapper:hover {
                                background-color: rgba(74, 144, 226, 0.08) !important;
                                color: rgb(74, 144, 226);
                            }

                            .ant-tree-node-content-wrapper {
                                background-color: unset;
                                line-height: 28px;
                                height: 32px;
                                width: 100%;
                                margin-left: -27px;
                                padding-left: 26px;

                                .ant-tree-title {
                                    width: 100%;
                                    display: flex;

                                    >div {
                                        width: 24%;
                                        position: absolute;
                                        right: 0px;
                                        margin-right: 5%;
                                        display: flex;
                                    }
                                }
                            }

                            .ant-tree-switcher {
                                background: unset;
                                line-height: 28px;
                                height: 32px;
                            }
                        }
                    }
                }

                :global {
                    .ant-tree-switcher {
                        height: 11px;

                        i {
                            font-size: 14px;
                        }
                    }

                    .ant-tree-switcher_close {
                        .bcIcon {
                            transform: rotate(0deg);
                        }
                    }
                }
            }
        }
    }
}

.PopoverModal {
    :global {
        width: 503px;
        height: 441px;

        .ant-popover-content {
            height: 100%;
            width: 100%;

            .ant-popover-inner {
                height: 100%;
                width: 100%;

                >div {
                    width: 100%;
                }

                .ant-popover-title {
                    font-family: "Source Sans Pro", sans-serif;
                    font-size: 14px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.21;
                    letter-spacing: 0.17px;
                    text-align: left;
                    color: #4c91df !important;
                }

                .ant-popover-inner-content {
                    width: 502px;
                    height: 391px;
                    display: inline-block;
                    /*div {
            height: 265px;
          }*/
                }
            }
        }
    }
}

.passiveColor {
    color: #777777 !important;
}

.activeColor {
    color: #4c91df !important;
}

.boldFont {
    font-weight: bold !important;
}

.normalFont {
    font-weight: normal !important;
}

.activeCursor {
    cursor: pointer;

    a {
        margin: 0 5px 0 5px;
    }
}

.passiveCursor {
    cursor: no-drop;

    a {
        margin: 0 5px 0 5px;
    }
}

.permissionText {
    :global {
        font-family: Source Sans Pro;
        font-size: 14px !important;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(72% - 12px) !important;
    }
}

.permissionSelectedItem {
    :global {
        font-family: Source Sans Pro;
        font-size: 14px !important;
        width: 65% !important;
        padding-left: 10px;
    }
}

.permissionSelectBox {
    :global {
        width: 65% !important;
        font-family: Source Sans Pro;
        float: left;

        .comboboxModified {
            width: 24px !important;
            height: 24px !important;

            svg {
                vertical-align: middle !important;
            }
        }

        .ant-select-dropdown-menu {
            padding-left: 0 !important;

            .ant-select-dropdown-menu-item {
                padding-left: 10px !important;
            }
        }
    }
}

.whyClass {
    :global {
        width: 30% !important;
        font-family: Source Sans Pro;
        font-size: 12px;
        text-align: end;
    }
}

.WhyModalContent {
    background-color: #f8f8f8 !important;
    height: 265px;
    margin-left: -16px;
    margin-top: -12px;
    padding-left: 50px;
    padding-top: 19px;
    width: inherit;

    .whyContent {
        height: 204px;
        display: inline-block;
        float: left;
        width: 100%;

        :global {
            .loadingOverlay {
                .ant-spin-nested-loading {
                    .ide {
                        height: calc(95% - 10px);

                        .ant-tree-switcher {
                            display: none;
                        }

                        $border: #ddd;
                        $ident: 20px;
                        $left: -($ident);

                        ul {
                            margin-left: $ident;
                        }

                        .ant-tree-child-tree {
                            li {
                                list-style-type: none;
                                margin: 10px 0 10px 0px;
                                position: relative;

                                &:before {
                                    content: "";
                                    position: absolute;
                                    top: 5px;
                                    left: $left;
                                    border-left: 1px dotted $border;
                                    border-bottom: 1px dotted $border;
                                    width: $ident;
                                    height: 15px;
                                }

                                &:first-of-type:before {
                                    content: "";
                                    position: absolute;
                                    top: -10px;
                                    left: -20px;
                                    border-left: 1px dotted #ddd;
                                    border-bottom: 1px dotted #ddd;
                                    width: 20px;
                                    height: 30px;
                                }

                                &:after {
                                    position: absolute;
                                    content: "";
                                    top: 20px;
                                    left: $left;
                                    border-left: 1px dotted $border;
                                    border-top: 1px dotted $border;
                                    width: $ident;
                                    height: 100%;
                                }

                                &:last-child:after {
                                    display: none;
                                }

                                span {
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.WhyModalTitle {
    :global {
        margin-top: 23px;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 12px !important;
        font-weight: 900 !important;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.17;
        letter-spacing: normal;
        text-align: left;
        color: var(--primary-color-6) !important;
    }
}

.WhyModalSubTitle {
    margin-top: 23px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px !important;
    font-weight: 900 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #4a90e1 !important;
}

.WhyModalSubTitle {
    margin-top: 23px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px !important;
    font-weight: 900 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: var(--primary-color-6) !important;
    padding-left: 34px;
}

.WhyModalSubContent {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    padding-left: 65px;
    padding-right: 43px;
    text-align: left;
    color: #777777 !important;

    span {
        color: #4a90e1 !important;
        margin-left: 10px;
    }
}

.Members {
    padding-left: 10px;
    padding-top: 7px;
    height: calc(100% - 40px);

    .membersTitleContainer {
        display: flex;
        justify-content: space-between;

        .memberUserNameTitle {
            width: 43%;
            padding-left: 10px;
        }

        .memberExplainTitle {
            width: 43%;
        }

        .MemberHeaderColumn {
            font-family: "Source Sans Pro", sans-serif;
            font-size: 10px !important;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 30px;
            letter-spacing: normal;
            text-align: left;
            color: #333d46 !important;
            width: 60% !important;
        }

        .MemberHeaderIcon {
            font-family: "Source Sans Pro", sans-serif;
            font-size: 10px !important;
            font-weight: 900;
            font-stretch: normal;
            font-style: normal;
            line-height: 31px;
            letter-spacing: normal;
            text-align: left;
            color: #333d46 !important;
            width: 14% !important;
        }
    }

    .MemberItems {
        flex-direction: column;
        height: 100%;

        .membersRow {
            display: flex;

            &:hover {
                background-color: rgba(74, 144, 226, 0.08);

                .membersIcons {
                    visibility: visible;
                }
            }

            .membersIcons {
                visibility: hidden;
                display: flex;
                width: 14%;
                justify-content: center;

                :global {
                    .bcIcon {
                        margin-right: 5px;

                        &:hover {
                            background-color: rgba(74, 144, 226, 0.08);
                        }
                    }
                }
            }

            .ItemColumn {
                font-family: "Source Sans Pro", sans-serif;
                font-size: 13px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                letter-spacing: normal;
                text-align: left;
                color: #777777 !important;
                line-height: 31px;
                width: 43% !important;
                margin-bottom: 0px !important;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
        }
    }
}

.avatarColumn {
    :global {
        width: 20%;
        float: left;
        text-align: center;

        div {
            border-radius: 50%;
            width: 78px;
            height: 78px;
            border: 1px solid #b9b9b9;
            overflow: hidden;
            display: inline-block;
            position: relative;
            background-color: white;

            .ant-avatar-circle {
                display: inline-block;
            }

            .change {
                background-color: #4a90e2;
                position: absolute;
                top: 78px;
                left: -13px;
                width: 100px;
                font-family: "Source Sans Pro", sans-serif;
                color: white;
                transition: top 200ms;
                cursor: pointer;
            }

            &:hover {
                .change {
                    top: 49px;
                }
            }
        }
    }

    .label {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 13px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #4a90e2;
    }
}

.contentColumn {
    width: calc(80% - 30px);
    margin-left: 30px;
    float: left;
    display: inline-block;
    padding-bottom: 150px;

    .label {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.21;
        letter-spacing: normal;
        text-align: left;
        color: #777777;
    }

    :global {
        .ant-select {
            width: 100%;
            margin-bottom: 160px;

            .ant-select-selection--multiple {
                height: 100% !important;

                .ant-select-selection__rendered {
                    .ant-select-selection__placeholder {
                        margin-left: 0px;
                        font-family: "Source Sans Pro", sans-serif;
                        font-size: 14px;
                    }

                    .ant-select-selection__choice {
                        .ant-select-selection__choice__content {
                            .bcIcon {
                                width: 23px !important;
                                height: 22px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .inputElement {
        padding-left: 6px;
        font-family: "Source Sans Pro", sans-serif;
        color: #777777 !important;
    }
}

.subText {
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.21;
    letter-spacing: normal;
    text-align: left;
    color: #777777 !important;
    margin-bottom: 28px !important;
}

.defaultButtonHoverWrap {
    :global {
        .ant-popover-content {
            .ant-popover-inner {
                .ant-popover-inner-content {
                    text-align: center;

                    button {
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}

.contexActualSaveBtn,
.contexActualAssignBtn,
.contexActualRefreshBtn,
.contexActualUndoBtn {
    margin: 5px !important;
    box-shadow: none;
}

:global {
    .ms-ContextualMenu-Callout {
        min-width: unset !important;
    }
}

.SearchItemIcon {
    width: 35px !important;
    height: 24px !important;
}

.groupModal {
    :global {
        .ant-form-item-explain {
            display: none;
        }

        textarea.ant-input {
            height: 32px !important;
        }
    }
}

.overlayClassName {
    height: calc(100% - 5px);
}