.DmViewerContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .viewerHeader {
        height: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        :global {
            .BCRibbonBar {
                margin-left: auto;
                width: calc(100% - 200px);
                height: 32px;
                .ms-CommandBar {
                    height: 32px !important;
                    .ms-OverflowSet {
                        justify-content: flex-end;
                        height: 32px;
                        .ms-Button {
                            height: 32px;
                            padding: 0 5px;
                            &:hover {
                                background-color: #f2f1f0;
                                color: unset !important;
                            }
                        }
                        .ms-OverflowSet-item {
                            height: 32px;
                        }
                    }
                }
            }
        }
    }

    .viewerContent {
        height: calc(100% - 40px);

        &.dragOver {
            border: 2px dashed var(--primary-color-6);
        }

        .viewerTypeSelection {
            height: 40px;
            display: flex;
            align-items: center;

            .viewerTypeCombobox {
                width: 150px;
                margin-left: 5px;
            }
        }

        .bcViewerWrapper {
            height: 100%;
            border-radius: 4px;
            overflow: hidden;

            &.hasViewerTypeSelection {
                height: calc(100% - 58px);
            }
        }
    }

    &.disabledHeader {
        .viewerContent {
            height: 100%;
        }
    }

    .viewerSkeleton {
        width: 100%;
        height: 100%;
    }
}
