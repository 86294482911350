.radioWrapper {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px !important;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-flex;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  height: 100%;
  align-items: center;


  span {
    color: themed("inputColor");
  }
}

.radioInput {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  cursor: pointer;
  opacity: 0;
}

.radioInner {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  border-radius: 100px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: transparent;
  border: solid 2px var(--primary-color-6);
  width: 18px;
  height: 18px;
}

.radioInner:after {
  position: absolute;
  top: 3px;
  left: 3px;
  display: table;
  width: 8px;
  height: 8px;
  background-color: #1890ff;
  border-top: 0;
  border-left: 0;
  border-radius: 8px;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  content: ' ';
}

.radioInput:checked + .radioInner:after {
  -webkit-transform: scale(0.875);
  -ms-transform: scale(0.875);
  transform: scale(0.875);
  opacity: 1;
  -webkit-transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}


.radioWrapper:hover .radio,
.radio:hover .radioInner,
.radioInput:focus + .radioInner {
  border-color: var(--primary-color-6);
}

.radioInput:focus + .radioInner {
  -webkit-box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
  box-shadow: 0 0 0 3px rgba(24, 144, 255, 0.08);
}

.radio {
  font-size: 14px;
  font-variant: tabular-nums;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.65);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;
  outline: none;
  display: inline-block;
  position: relative;
  line-height: 1;
  vertical-align: sub;
  cursor: pointer;
}

span.radio + * {
  padding-left: 8px;
  padding-right: 8px;
}

.disabled {
  filter: grayscale(1);
  cursor: not-allowed;
}