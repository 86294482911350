.progressMaterializecss {
    position: relative;
    height: 2px;
    display: block;
    width: 100%;
    background-color: var(--primary-color-2);
    overflow: hidden;
    .indeterminate {
        background-color: var(--primary-color);
        &:before {
            content: "";
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminate 4.2s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
        }
        &:after {
            content: "";
            position: absolute;
            background-color: inherit;
            top: 0;
            left: 0;
            bottom: 0;
            will-change: left, right;
            animation: indeterminateShort 4.2s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
            animation-delay: 2.30s;
        }
    }
}

@keyframes indeterminate {
    0% {
        left: -35%;
        right: 100%;
    }
    60% {
        left: 100%;
        right: -90%;
    }
    100% {
        left: 100%;
        right: -90%;
    }
}

@keyframes indeterminateShort {
    0% {
        left: -200%;
        right: 100%;
    }
    60% {
        left: 107%;
        right: -8%;
    }
    100% {
        left: 107%;
        right: -8%;
    }
}

.progressContainer {
    width: 100%;
}
