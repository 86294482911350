/// <reference path="../../node_modules/@bimser/components/dist/stylesheets/main.scss" />
@import "mainstyles";

html {
    box-sizing: border-box;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    font-family: "Segoe WP", sans-serif;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.container {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    @include themify($themes) {
        background-color: themed("webContainerBackgroundColor");
    }
}

::-moz-selection {
    background: var(--primary-color);
}

::selection {
    background: var(--primary-color);
}
