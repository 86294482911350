/// <reference path="../../stylesheets/main.scss"/>
@import "mainstyles";

.AddonButtonContainer {
    width: 100%;
    overflow: hidden;
    display: flex;

    .ribbonBar,
    .addon {
        float: left;
    }

    .ribbonBar {
        display: flex;
        align-items: center;
        margin: 0 0 0 8px;

        :global {
            .ant-btn {
                padding-left: 0px;
                padding-right: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    background-color: rgb(242, 241, 240) !important;
                }
            }
        }
    }

    .addon {
        width: 100%;
        :global {
            .ant-input-search .ant-input-affix-wrapper {
                margin: 0;
                background-color: #f4f2f2;
                .ant-input {
                    background-color: #f4f2f2;
                }
            }
            .ant-input-search-button {
                background-color: #f4f2f2;
            }
        }
    }
}
