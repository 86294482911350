/// <reference path="../../stylesheets/main.scss"/>

@import 'mainstyles';

.ModalFrameContainer {
    width: 100% !important;
    height: 100vh !important;

    div {
        height: 100% !important;
        padding: 0 !important;
    }

    :global {

        .ant-modal-content {
            height: calc(100vh - 90px) !important;
            width: calc(100% - 100px) !important;
            float: right !important;
            margin: 78px 10px 0 0 !important;
        }

        .ant-modal {
            width: unset !important;
        }

        .ant-modal-body {
            height: 100% !important;
        }

        .ant-spin-nested-loading {
            height: 100% !important;
        }

        .ant-spin-container {
            height: 100% !important;
        }

        .ant-spin-container > div {
            position: relative !important;
            overflow: hidden !important;
            width: auto !important;
            height: 100% !important;
            max-height: unset !important;
        }

        .ant-spin-container > div > div {
            position: relative !important;
            overflow: hidden !important;
            margin-right: 0 !important;
            margin-bottom: 0 !important;
            height: 100% !important;
            max-height: unset !important;
        }
    }
}
