.inputMaskStyles {
    &.textalignRight {
        text-align: right !important;
    }
    &.textalignLeft {
        text-align: left !important;
    }
    &.textalignCenter {
        text-align: center !important;
    }
}
