.phoneDropdown {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;

    width: auto !important;
    :global {
        .ant-select-selector {
            background-color: #f0f0f0 !important;
            border-right: none !important;
        }
        .ant-select-dropdown {
            min-width: 200px !important;
        }
    }
}

.maskInput {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-left: none;
}

.Wrapper {
    display: flex;
    width: auto;
    height: auto;

    &:hover {
        :global {
            .ant-select-selector {
                border-color: #2495e0 !important;
            }
            .ant-input {
                border-color: #2495e0 !important;
            }
        }
    }

    &.onFocus {
        :global {
            .ant-input {
                border-color: #2495e0 !important;
                box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
                outline: 0 !important;
            }
            .ant-select-selector {
                border-color: #2495e0 !important;
                box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2) !important;
                outline: 0 !important;
            }
        }
    }
}
