/// <reference path="../../stylesheets/main.scss" />
@import "mainstyles";

.init {
  cursor: default;
}

.listboxContainer {
  cursor: default;
}

.searchBox {
  width: 100%;
  margin-bottom: 10px;
}

.checkboxGroupContainer {
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: solid 1px #ddd;
  width: 100%;
  float: left;
  overflow: hidden;
}

/*.checkBoxGroupContent {
  :global {
    .ant-checkbox-group {
      display: inline-flex;
    }
  }
}*/

.horizontal {
  display: inline-flex;
}

.vertical {
  display: flex;
  line-height: 30px;
}
