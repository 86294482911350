.body {
  display: flex;
  height: 100%;
  .selectionArea {
    display: none;
    position: absolute;
    z-index: 99;
    background-color: var(--primary-color-o-4);
    border: solid 1px var(--primary-color-o-8);
  }
}

.hasBottomDock {
  height: calc(100% - 50px) !important;
}

.dropZone {
  flex: 0 0 auto;
  height: 10px;
  transition: 200ms all;
  & > &:nth-of-type(2n) {
    display: none;
  }
  &.horizontalDrag {
    width: 10px;
    height: auto;
  }
  &:not(.horizontalDrag).isLast {
    flex: 1 1 auto;
  }
  &.canDrop {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='6' height='6' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(30,215,96,.1)'/%3E%3Cpath d='m0 10h40v2h-40z' fill='rgba(253, 253, 253,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
    background-color: rgba(30, 215, 96, 0.1);
    transition: all 400ms ease;
  }
  &.active {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='100%25' width='100%25'%3E%3Cdefs%3E%3Cpattern id='doodad' width='6' height='6' viewBox='0 0 40 40' patternUnits='userSpaceOnUse' patternTransform='rotate(135)'%3E%3Crect width='100%25' height='100%25' fill='rgba(227, 239, 248,1)'/%3E%3Cpath d='m0 10h40v2h-40z' fill='rgba(253, 253, 253,1)'/%3E%3C/pattern%3E%3C/defs%3E%3Crect fill='url(%23doodad)' height='200%25' width='200%25'/%3E%3C/svg%3E ");
    border: solid 1px #60ace4;
    transition: all 100ms ease;
  }

  &.magnet {
    &:not(.fullHeight) {
      &::before,
      &::after {
        content: "";
        display: block;
        opacity: 0.3;
        width: 100%;
        height: 25px;
      }
      &::before {
        margin-top: -20px;
      }
    }
  }

  &.highlight {
    background: var(--primary-color-o-1);
  }
  &.fullHeight {
    min-height: 44px;
  }
  &.fullHeight.isInputGroup {
    min-height: 25px;
  }
  &.fillHeight {
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
  }
}

.component {
  border: dashed 1px silver;
  min-height: 40px;
  padding: 5px;
  &.isInputGroup {
    padding: 0;
    min-height: 25px;
    border: dashed 1px transparent;
  }
}

.column {
  border: 1px solid rgba(0, 0, 0, 0.1);
  flex: 1 1 100%;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.02);
  &.isInputGroup {
    border: 1px solid transparent;
    padding: 0;
  }
}

.columns {
  display: flex;
  padding: 10px 0;
  &.removePadding {
    padding: 0;
  }
  &.extraSmall {
    padding: 5px 10px;
    flex-direction: column;
    row-gap: 5px;
  }
}

.base {
  padding: 0 10px;
  cursor: move;
}

.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: relative;

  .page {
    flex: 1 1 auto;
    border: solid 1px transparent;

    &.isInputGroup {
      padding: 0 !important;
    }

    &.selected {
      border: dashed 1px var(--primary-color-6);
    }
    &.locked {
      background: rgba(255, 225, 0, 0.05);
    }
  }
}

.bottomDock {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  border-top: solid 1px #eee;
  background: #fff;
  .formUnparentedItems {
    width: 100%;
    float: left;
    height: 100%;
    padding: 0 6px;
    .horizontalContainer {
      white-space: nowrap;
      height: 100%;
      width: auto;
      .itemContainer {
        display: inline-block;
        height: 30px;
        line-height: 20px;
        font-size: 12px;
        padding: 5px 10px;
        border: dashed 1px #eee;
        margin: 9.5px 4px;
        cursor: pointer;
        transition: background-color 150ms;
        user-select: none;
        &:hover {
          background-color: #fafafa;
        }
        &:active {
          background-color: #eee;
        }
        &.selected {
          border-color: #0079d5;
        }
        :global {
          .bcIcon {
            width: 16px;
            vertical-align: bottom;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.addSectionBtn {
  height: 40px;
  margin-bottom: 80px;
  cursor: pointer;
  transition: all 200ms ease;
  opacity: 0.5 !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.02);

  :global {
    .bcIcon {
      transition: all 200ms ease;
      opacity: 0.5;
    }
    .col {
      height: 100%;
    }
  }
  &:hover {
    background-color: #f9f9f9;
    border-color: #adacaa;
    opacity: 1 !important;
  }
  &:active {
    background-color: #f3f3f3;
    border-color: #adacaa;
  }
}

.section {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.02);

  &.isInputGroup {
    background: transparent;
    border: none;
  }

  .columns {
    .column {
      display: flex;
      flex-flow: column;
      min-width: 0; // This was a trick that stops flexbox overflowing after 2 days hopeless research
      .component {
        display: flex;
        align-items: center;
        flex: 0 1 auto;
      }
      .dropZone {
        z-index: 4;
        &:last-child {
          flex: 1 0 auto;
        }
        &:not(:last-child) {
          flex: 0 0 10px;
        }
      }
    }
  }
  &:last-of-type {
    .columns {
      .column:not(.isInputGroup) {
        min-height: 400px;
      }
    }
  }

  &.empty {
    min-height: 400px;
    display: flex;
    flex-direction: column;
    .dropZone {
      flex: 1 1 auto;
    }
  }
}

.section,
.column,
.component {
  position: relative;
  opacity: 1;
  & > .actionMenu {
    position: absolute;
    z-index: 9;
    display: none;
    padding-left: 30px;
    padding-right: 30px;
    overflow: hidden;
    cursor: pointer;

    &.collapsed {
      span {
        display: none;
      }
      .actionMenuContent {
        display: none;
      }
    }

    &.isSection {
      top: -52px;
      left: 50%;
      transform: translate(-50%, 0);
      padding-top: 30px;

      .actionMenuContent {
        border-radius: 3px 0 0 0;
        border-width: 1px 0 0 1px;
      }
      .toggleBtn {
        border-radius: 0 3px 0 0;
      }

      &.collapsed {
        .toggleBtn {
          border-radius: 3px 3px 0 0;
        }
      }
    }

    &.isColumn {
      top: 0;
      left: 0;
      padding: 0;
      .actionMenuContent {
        border-radius: 0 0 0 0;
        border-width: 0 0 1px 0;
      }
      .toggleBtn {
        border-radius: 0 0 3px 0;
      }
      &.collapsed {
        .toggleBtn {
          border-radius: 0 0 3px 0;
        }
      }
    }

    &.isComponent {
      top: -51px;
      right: -30px;
      padding-top: 30px;
      .actionMenuContent {
        border-radius: 3px 0 0 0;
        border-width: 1px 1px 0 1px;
      }
      .toggleBtn {
        border-radius: 0 3px 0 0;
      }

      &.collapsed {
        .toggleBtn {
          border-radius: 3px 3px 0 0;
        }
      }
    }

    .toggleBtn {
      background-color: var(--primary-color);
      border: none;
      outline: none;
      cursor: pointer;
      width: 21px;
      height: 21px;
      float: left;
      overflow: hidden;
      transition: all 200ms ease;

      &:hover {
        background-color: var(--primary-color-4);
      }

      svg {
        fill: #fff;
      }

      :global {
        .bcIcon {
          vertical-align: top;
        }
      }
    }

    .actionMenuContent {
      background-color: #eaeaea;
      border: solid 1px #afaeac;
      float: left;

      span {
        font-size: 10px;
        font-weight: 600;
        color: rgba(58, 58, 56, 0.75);
        float: left;
        padding: 0 20px 0 5px;
        line-height: 20px;
      }
      ul {
        margin: 0;
        padding: 0;
        float: left;
        height: 20px;
        li {
          float: left;
          list-style-type: none;
          height: 20px;
          button {
            border: none;
            outline: none;
            background-color: transparent;
            cursor: pointer;
            width: 20px;
            height: 20px;
            &:hover {
              background-color: rgba(0, 0, 0, 0.1);
            }

            :global {
              .bcIcon {
                vertical-align: top;
              }
            }
          }
        }
      }
    }
  }

  &.locked {
    cursor: default;
  }

  &.hidden {
    opacity: 0.5;
  }

  &.isDragging {
    opacity: 0;
  }

  &.selected {
    border-color: #0079d5;
    .actionMenu {
      .actionMenuContent {
        background-color: #f1f7fc;
        border-color: #0079d5;
      }
    }
  }

  &:hover {
    & > .actionMenu {
      display: block;
    }
  }
}
