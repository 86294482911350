.hrFormMLPopover {
    width: 390px;
    height: 261px;
    padding-right: 10px;

    .popoverRow {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        :global {
            .ant-input-affix-wrapper {
                height: 24px;
                font-size: 12px;
                margin-top: 7px;
            }

            label {
                font-size: 12px;
                width: 100%;
                margin: 0;
            }
        }
    }
}

.addonAfter {
    display: flex;
    position: relative;

    // > div {
    //     background-color: #f9f9f9;
    //     border-color: #e0e0e0;
    //     border-width: 1px;
    //     border-style: solid;
    //     height: 100% !important;
    //     position: absolute;
    //     right: 0;
    //     padding: 2px;
    // }

    > textarea {
        max-width: calc(100% - 22px);
    }
}
