/// <reference path="../../stylesheets/main.scss"/>
@import "mainstyles";

.hasCustomColor {
  :global {
    .ant-radio {
      & + span {
        color: inherit !important;
      }
    }
  }
}

:global {
  @include themify($themes) {
    .ant-radio {
      vertical-align: middle;

      & + span {
        color: var(--icon-secondary-color);
        white-space: pre-line;
      }
    }

    .ant-radio .ant-radio-inner {
      background-color: transparent;
      border: solid 2px var(--primary-color-6);
      width: 18px;
      height: 18px;
    }

    .ant-radio {
      &.ant-radio-checked {
        .ant-radio-inner {
          background-color: transparent;
        }
      }
    }
  }
}

.radioGroupContent {
  display: flex;
  :global {
    .ant-radio-group {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }
}

.horizontal {
  display: inline-flex;
  align-items: center;
  :global {
    span.ant-radio {
      top: 0;
    }
  }
}

.vertical {
  display: flex;
  margin-right: 0px;
  margin-bottom: 10px;
  align-items: center;
  :global {
    span.ant-radio {
      top: 0;
    }
  }
}

.searchBox {
  width: 100%;
  margin-bottom: 10px;
}

.disabled {
  filter: grayscale(1);
}
