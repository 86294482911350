.empty {
    cursor: pointer;
}

:global {
    .ant-input-search-icon::before {
        display: none;
    }
}

.inputSearchStyles {
    &.textalignRight {
        :global {
            .ant-input-wrapper {
                .ant-input-affix-wrapper {
                    input {
                        text-align: right !important;
                    }
                }
            }
        }
    }
    &.textalignLeft {
        :global {
            .ant-input-wrapper {
                .ant-input-affix-wrapper {
                    input {
                        text-align: left !important;
                    }
                }
            }
        }
    }
    &.textalignCenter {
        :global {
            .ant-input-wrapper {
                .ant-input-affix-wrapper {
                    input {
                        text-align: center !important;
                    }
                }
            }
        }
    }
}
