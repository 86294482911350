.diagramStateViewerContainer {
    position: absolute;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 20px);
    z-index: 2;
    height: 40px;
    background: #ffffff;
    box-shadow: 6px -6px 15px -6px rgba(50, 52, 56, 0.05), -6px -6px 15px -6px rgba(56, 63, 71, 0.02),
        inset 0px -20px 20px -20px rgba(37, 38, 40, 0);

    .label {
        font-family: Segoe WP, sans-serif !important;
        font-size: 12px;
        margin-left: 10px;
        display: inline-block;
        color: #777;
    }

    :global {
        .ant-slider .ant-slider-track {
            background-color: transparent;
        }

        .ant-slider:hover .ant-slider-track {
            background-color: transparent;
        }

        .ant-slider-step:hover {
            background: #8db9ed;
        }
    }

    .stateViewerLabel {
        width: auto;
        margin-top: 11px;
        font-size: 14px;
    }

    .stateViewerStrongLabel {
        width: auto;
        margin-top: 6px;
        font-size: 18px;
    }

    .stateViewerContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 450px);
    }

    .zoomPanelContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 450px;
    }

    .scaleInput {
        width: 80px;
        margin: 5px;
        vertical-align: text-bottom;
    }

    .sliderContainer {
        width: 200px;
        display: inline-block;
    }

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .changeScaleButtons {
        vertical-align: text-bottom;
        cursor: pointer;
        margin-left: 5px;
        height: 40px;
        margin-right: 5px;
    }

    .changeScaleButtons:hover {
        color: #8db9ed;
    }

    .icon {
        vertical-align: text-bottom;
        cursor: pointer;
        margin-left: 5px;
        height: 40px;
    }
}
