/// <reference path="../../stylesheets/main.scss" />
@import 'mainstyles';

:global {
    .ant-spin-nested-loading {
        width: 100%;
        height: 100%;

        .ant-spin-container {
            width: 100%;
            height: 100%;
        }
    }
}

.loadingOverlay{
    width: 100%;
    height: 100%;

    &.show{
        pointer-events: none;
    }
}
