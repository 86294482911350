:global {
    .ant-input-group.ant-input-group-compact textarea.ant-input {
        height: auto;
    }
}

.textAreaStyles {
    &.textalignRight {
        text-align: right !important;
    }
    &.textalignLeft {
        text-align: left !important;
    }
    &.textalignCenter {
        text-align: center !important;
    }
}
