:global {
    .ant-dropdown {
        box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.1);
        .ant-menu-item {
            .ant-menu-title-content,
            .ant-dropdown-menu-title-content {
                display: flex;
                align-items: center;
            }
        }
    }
}

.dropdownStyle {
    max-height: 256px !important;
    overflow-y: auto !important;
    overflow-anchor: none !important;

    :global {
        .ant-dropdown-menu-title-content {
            display: flex;
            align-items: center;

            .bcIcon {
                width: auto;
                margin-right: 5px;
            }
        }
    }
}

.iconWithText {
    display: inline-flex !important;
    align-items: center;
}

.readonlyCursor {
    cursor: pointer !important;
}
