/// <reference path="../../stylesheets/main.scss"/>
@import "mainstyles";

.sider {
    z-index: 10;

    @include themify($themes) {
        background-color: themed("webLeftMenuBackgroundColor");
        box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
    }
}

.header {
    padding: 0;
    height: 46px !important;
    line-height: 46px !important;
    background: rgba(255, 255, 255, 0.3) !important;

    @include breakpoint(sm) {
        height: 110px;
        z-index: 2;
    }
}

.content {
    height: 100%;
}

:global {
    .ant-layout-sider-zero-width-trigger {
        display: none;
    }
    .ant-layout {
        background-repeat: repeat-x;
        background-position: center top;
        background-color: transparent;
    }
}
