.DmFileSelectorContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    .DmFileSelectorFooter {
        display: flex;
        .ExtensionLabel {
            text-align: left;
            width: auto;
            line-height: 32px;
            margin-right: 10px;
        }
        .ExtensionDropdown {
            width: 200px;
            margin-right: auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .PaginationContainer {
            margin-right: auto;
            align-self: center;
        }
        .FooterButton {
            width: 120px;
            margin-left: 10px;
        }
    }
}
