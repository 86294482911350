.badge {
    vertical-align: sub;

    :global {
        .ant-badge-count {
            background: var(--primary-color-6);
            font-size: 10px;
            line-height: 14px;
            height: 14px;
            min-width: 14px;
            border-radius: 100%;
            padding: 0;
            box-shadow: 0 0 0 1px var(--primary-color-6);
            margin-top: 2px;
        }
    }
}
