/// <reference path="../../../stylesheets/main.scss" />
@import "mainstyles";

.flexPanelContainer {
    float: left;
    width: 100%;
    height: 100%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    padding: 16px 8px;

    &.fromMobile {
        padding: 0px !important;
    }

    .flexPanelSize1 {
        width: calc(100% / 3 * 1 - 16px);
    }

    .flexPanelSize2 {
        width: calc(100% / 3 * 2 - 16px);
    }

    .flexPanelSize3 {
        width: calc(100% / 3 * 3 - 16px);
    }

    .flexPanel {
        z-index: 1;
        position: relative;
        display: inline-block;
        height: 100%;
        margin: 0 8px;
        white-space: normal;
        overflow: hidden;
        animation-duration: 0.5s;
        animation-delay: 0s;
        border-radius: 8px;
        background-color: rgba(242, 242, 244, 1);
        -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
            background-color 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out, height 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
            background-color 0.5s ease-in-out;
        -o-transition: width 0.5s ease-in-out, height 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
            background-color 0.5s ease-in-out;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
            background-color 0.5s ease-in-out;
        box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);

        &.fromMobile {
            background-color: transparent !important;
            box-shadow: none !important;

            :global {
                .loadingOverlay {
                    height: calc(100% - 5px) !important;
                }
            }
        }

        .hide {
            display: none !important;
        }

        .header {
            width: 100%;
            float: left;
            height: 46px;
            display: flex;
            justify-content: flex-end;
            flex-wrap: nowrap;
            padding: 7.5px 7.5px 0 15px;
            line-height: 38.5px;

            :global {
                .ant-divider-vertical {
                    min-width: 1px !important;
                    width: 1px !important;
                    height: 36px !important;
                    background: rgba(0, 0, 0, 0.1);
                    margin-top: 2px;
                }

                .BCRibbonBar {
                    margin-left: auto;
                    height: 32px;

                    .ms-CommandBar {
                        height: 32px !important;

                        .ms-OverflowSet {
                            justify-content: flex-end;
                            height: 32px;

                            .ms-Button {
                                height: 32px;
                                padding: 0 5px;
                                &:hover {
                                    background-color: #f2f1f0;
                                    color: unset !important;
                                }
                                padding: 0px !important;

                                .ms-Button-textContainer {
                                    font-size: 13px;
                                    font-weight: normal;

                                    .ms-Button-label {
                                        height: 32px;
                                        line-height: 32px;
                                        margin: 0 10px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .events {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-left: auto;
            }

            .title {
                float: left;
                font-size: 16px;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 80%;
                font-weight: 600;
                transition: all 100ms $transition-timing-function;
                transition-property: color;
                white-space: nowrap;
            }

            .description {
                float: left;
                width: 100%;
                font-size: 12px;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 100%;

                @include themify($themes) {
                    color: themed("flexPanelHeaderDescriptionColor");
                }
            }

            .actions {
                display: flex;

                @media only screen and (max-width: 600px) {
                    .button {
                        &:not(.close) {
                            display: none !important;
                        }
                    }
                }

                .button {
                    min-width: 36px;
                    height: 36px;
                    cursor: pointer;
                    line-height: 30px;
                    text-align: center;
                    transition: all $transition-duration $transition-timing-function;
                    transition-property: background-color, border-color;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    :global {
                        .bcIcon {
                            width: 12px;
                            height: 18px;
                            transition: all $transition-duration $transition-timing-function;

                            @include themify($themes) {
                                color: themed("flexPanelActionIconColor");
                            }

                            svg {
                                float: left;
                            }
                        }
                    }

                    &.close {
                        :global {
                            .bcIcon {
                                width: 8px;
                            }
                        }

                        &:hover {
                            :global {
                                .ms-Icon {
                                    color: #fff !important;
                                }
                            }
                        }

                        &:active {
                            :global {
                                .ms-Icon {
                                    color: #fff !important;
                                }
                            }
                        }
                    }

                    &.pinned {
                        background-color: var(--golden-color);
                        border: solid 1px var(--golden-color);

                        :global {
                            .bcIcon {
                                transform: rotate(-45deg);
                                color: #fff;
                            }
                        }
                    }

                    &.pinned,
                    &.maximized {
                        @include themify($themes) {
                            border-color: themed("flexPanelActionColor");
                        }

                        :global {
                            .bcIcon {
                                @include themify($themes) {
                                    color: themed("flexPanelActionColor");
                                }
                            }
                        }

                        &:hover {
                            :global {
                                .bcIcon {
                                    color: #fff;
                                }
                            }
                        }
                    }

                    &:hover {
                        border-color: #f2f1f0;
                        background-color: #f2f1f0;
                    }
                    &:active {
                        border-color: #ecebea;
                        background-color: #ecebea;
                    }
                }
            }
        }

        .content {
            height: calc(100% - 46px);

            float: left;
            width: 100%;
            :global {
                .ant-spin-nested-loading {
                    float: left;
                }
            }

            &:not(.fromMobile) {
                .contentScroll {
                    > div:first-child {
                        margin-right: -18px !important;
                        margin-bottom: -18px !important;
                    }
                }
            }

            &.fromMobile {
                height: 100% !important;
                background-color: transparent !important;
            }
        }

        .contentWithHiddenHeader {
            height: 100% !important;
        }

        &.pinned {
            z-index: 2;
            position: relative;
        }

        &.active {
            z-index: 2;
            background-color: rgba(229, 228, 234, 1);

            .header {
                .title {
                    color: var(--primary-color-6) !important;
                }
            }
        }

        &.startupPanel {
            background-color: transparent !important;
            box-shadow: none !important;
            height: 100%;
            width: 100%;
            margin: 0;
            .content {
                height: 100%;
                [class*="hasDashboard"] {
                    [class*="CSP_form"] {
                        padding-left: 8% !important;
                        padding-right: 8% !important;
                    }
                }
            }
        }
    }

    :global {
        .ms-OverflowSet-item,
        .ms-OverflowSet-overflowButton {
            & > div {
                transition: all 200ms ease-in;
                &:not(.ant-divider) {
                    border: solid 1px transparent;
                    border-radius: 4px;
                    &:hover {
                        background-color: #f2f1f0 !important;
                        border: solid 1px #ddd;
                    }
                    &:active {
                        background-color: #ecebea !important;
                    }
                }
            }
        }
    }
}

:global {
    .animated {
        -webkit-animation-duration: 0.5s;
        animation-duration: 0.5s;
        //-webkit-animation-fill-mode: both;
        //animation-fill-mode: both;
        // Buradaki both key'i backwards' a çekilmesinin nedeni animation bittigi anda panel üzerinde kalan transform css' contextMenu'yu bozmasıdır.
        // animation-fill-mode 'nin çalışma mantığı şu şekildedir.
        // both => element üzerinde first keyframe'in css'leri olur en başta ve animation bittiginde last keyframe'in css'leri element üzerinde kalır.
        // backwards => element üzerinde first keyframe'in css'leri olur en başta ve animation bittiginde element'in kendi default css'leri yüklenir. animation ile gelenler gider.
        // forwards => element üzerinde default css'leri olur en basta ve animation bittiginde last keyframe'in css'leri element üzerinde kalır.
        -webkit-animation-fill-mode: backwards;
        animation-fill-mode: backwards;
    }

    @-webkit-keyframes :global(hideFlexPanelOutRightAnimation1x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(100% / 3 + 10px);
        }
    }

    @keyframes :global(hideFlexPanelOutRightAnimation1x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(100% / 3 + 10px);
        }
    }

    @-webkit-keyframes :global(hideFlexPanelOutRightAnimation2x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(100% / 3 * 2 + 10px);
        }
    }

    @keyframes :global(hideFlexPanelOutRightAnimation2x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(100% / 3 * 2 + 10px);
        }
    }

    @-webkit-keyframes :global(hideFlexPanelOutRightAnimation3x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(100% + 10px);
        }
    }

    @keyframes :global(hideFlexPanelOutRightAnimation3x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(100% + 10px);
        }
    }

    .hideFlexPanelOutRightAnimation1x {
        -webkit-animation-name: hideFlexPanelOutRightAnimation1x;
        animation-name: hideFlexPanelOutRightAnimation1x;
        pointer-events: none;
    }

    .hideFlexPanelOutRightAnimation2x {
        -webkit-animation-name: hideFlexPanelOutRightAnimation2x;
        animation-name: hideFlexPanelOutRightAnimation2x;
        pointer-events: none;
    }

    .hideFlexPanelOutRightAnimation3x {
        -webkit-animation-name: hideFlexPanelOutRightAnimation3x;
        animation-name: hideFlexPanelOutRightAnimation3x;
        pointer-events: none;
    }

    @-webkit-keyframes :global(hideFlexPanelOutLeftAnimation1x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(-100% / 3 + 8px);
        }
    }

    @keyframes :global(hideFlexPanelOutLeftAnimation1x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(-100% / 3 + 8px);
        }
    }

    @-webkit-keyframes :global(hideFlexPanelOutLeftAnimation2x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(-100% / 3 * 2 + 8px);
        }
    }

    @keyframes :global(hideFlexPanelOutLeftAnimation2x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(-100% / 3 * 2 + 8px);
        }
    }

    @-webkit-keyframes :global(hideFlexPanelOutLeftAnimation3x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(-100% + 8px);
        }
    }

    @keyframes :global(hideFlexPanelOutLeftAnimation3x) {
        from {
            margin-left: 0;
        }

        to {
            visibility: hidden;
            margin-left: calc(-100% + 8px);
        }
    }

    .hideFlexPanelOutLeftAnimation1x {
        -webkit-animation-name: hideFlexPanelOutLeftAnimation1x;
        animation-name: hideFlexPanelOutLeftAnimation1x;
        pointer-events: none;
    }

    .hideFlexPanelOutLeftAnimation2x {
        -webkit-animation-name: hideFlexPanelOutLeftAnimation2x;
        animation-name: hideFlexPanelOutLeftAnimation2x;
        pointer-events: none;
    }

    .hideFlexPanelOutLeftAnimation3x {
        -webkit-animation-name: hideFlexPanelOutLeftAnimation3x;
        animation-name: hideFlexPanelOutLeftAnimation3x;
        pointer-events: none;
    }

    @-webkit-keyframes :global(showFlexPanelInLeftAnimation1x) {
        from {
            margin-left: calc(-100% / 3 + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @keyframes :global(showFlexPanelInLeftAnimation1x) {
        from {
            margin-left: calc(-100% / 3 + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @-webkit-keyframes :global(showFlexPanelInLeftAnimation2x) {
        from {
            margin-left: calc(-100% / 3 * 2 + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @keyframes :global(showFlexPanelInLeftAnimation2x) {
        from {
            margin-left: calc(-100% / 3 * 2 + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @-webkit-keyframes :global(showFlexPanelInLeftAnimation3x) {
        from {
            margin-left: calc(-100% + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @keyframes :global(showFlexPanelInLeftAnimation3x) {
        from {
            margin-left: calc(-100% + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    .showFlexPanelInLeftAnimation1x {
        -webkit-animation-name: showFlexPanelInLeftAnimation1x;
        animation-name: showFlexPanelInLeftAnimation1x;
    }

    .showFlexPanelInLeftAnimation2x {
        -webkit-animation-name: showFlexPanelInLeftAnimation2x;
        animation-name: showFlexPanelInLeftAnimation2x;
    }

    .showFlexPanelInLeftAnimation3x {
        -webkit-animation-name: showFlexPanelInLeftAnimation3x;
        animation-name: showFlexPanelInLeftAnimation3x;
    }

    @-webkit-keyframes :global(showFlexPanelInRightAnimation1x) {
        from {
            margin-left: calc(100% / 3 + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @keyframes :global(showFlexPanelInRightAnimation1x) {
        from {
            margin-left: calc(100% / 3 + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @-webkit-keyframes :global(showFlexPanelInRightAnimation2x) {
        from {
            margin-left: calc(100% / 3 * 2 + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @keyframes :global(showFlexPanelInRightAnimation2x) {
        from {
            margin-left: calc(100% / 3 * 2 + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @-webkit-keyframes :global(showFlexPanelInRightAnimation3x) {
        from {
            margin-left: calc(100% + 10px);
        }

        to {
            margin-left: 8px;
        }
    }

    @keyframes :global(showFlexPanelInRightAnimation3x) {
        from {
            margin-left: calc(100% + 10px);
        }

        to {
            visibility: hidden;
            margin-left: 8px;
        }
    }

    .showFlexPanelInRightAnimation1x {
        -webkit-animation-name: showFlexPanelInRightAnimation1x;
        animation-name: showFlexPanelInRightAnimation1x;
    }

    .showFlexPanelInRightAnimation2x {
        -webkit-animation-name: showFlexPanelInRightAnimation2x;
        animation-name: showFlexPanelInRightAnimation2x;
    }

    .showFlexPanelInRightAnimation3x {
        -webkit-animation-name: showFlexPanelInRightAnimation3x;
        animation-name: showFlexPanelInRightAnimation3x;
    }

    @-webkit-keyframes :global(fadeIn) {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    @keyframes :global(fadeIn) {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }

    .fadeIn {
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
    }

    @-webkit-keyframes :global(fadeOut) {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    @keyframes :global(fadeOut) {
        from {
            opacity: 1;
        }

        to {
            opacity: 0;
        }
    }

    .fadeOut {
        -webkit-animation-name: fadeOut;
        animation-name: fadeOut;
    }

    @-webkit-keyframes :global(closeFlexPanelAnimation) {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(calc(100vw), 0, 0);
        }
    }

    @keyframes :global(closeFlexPanelAnimation) {
        from {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }

        to {
            visibility: hidden;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(calc(100vw), 0, 0);
        }
    }

    .closeFlexPanelAnimation {
        -webkit-animation-name: closeFlexPanelAnimation;
        animation-name: closeFlexPanelAnimation;
    }

    @-webkit-keyframes :global(addFlexPanelAnimation) {
        from {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    @keyframes :global(addFlexPanelAnimation) {
        from {
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
            visibility: visible;
        }

        to {
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
        }
    }

    .addFlexPanelAnimation {
        -webkit-animation-name: addFlexPanelAnimation;
        animation-name: addFlexPanelAnimation;
    }

    .resizeFlexPanelAnimation {
        -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        -o-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }

    .clickMaximizeFlexPanelAnimation {
        -webkit-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        -moz-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        -o-transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
    }
}

.viewerSkeleton {
    width: 250px;
    height: 20px;
    margin-top: 4px;
}

.popoverTitleContainer {
    margin-top: 1px;
    .popoverTitleIcon {
        float: left;
        width: 20px;
        height: 20px;
        margin-top: 2px;
        margin-right: 7px;
    }
    .popoverTitleText {
        color: black;
        float: left;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 80%;
        font-weight: 600;
        transition: all 100ms ease-in;
        transition-property: color;
        white-space: nowrap;
    }
    .popoverTitleCloseIcon {
        float: right;
        width: 31px;
        height: 31px;
        cursor: pointer;
        margin-top: -6px;
        margin-right: -16px;
        &:hover {
            background-color: #f0f0f0;
        }
    }
}

.popoverStyles {
    :global {
        .ant-popover-content {
            .ant-popover-inner {
                .ant-popover-title {
                    border-radius: 0px !important;
                    border-bottom: none;
                    width: 293px;
                    height: 40px;
                    padding-bottom: 0px;
                    padding-left: 12px;
                    padding-right: 12px;
                }
                .ant-popover-inner-content {
                    border-radius: 0px !important;
                    width: 293px;
                    height: 60px;
                    padding-top: 0px;
                    padding-right: 12px;
                    padding-left: 12px;
                }
            }
        }
    }
}

.popoverContentStyles {
    display: flex;
    border-color: #e6e6e6;
    border-style: solid;
    border-width: 0.5px;

    .popoverIconContainer {
        width: 89px;
        height: 46px;
        &.popoverIconSelected {
            background-color: #e6e6e6;
            &:hover {
                background-color: #d9d9d9;
            }
        }
        &:not(.popoverIconSelected) {
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
}

.iconPanelSize {
    font-size: 12px;
    color: #5f5e59;
}

:global {
    .panelHeaderButton {
        width: 36px;
        height: 36px;
        display: flex;
        align-items: center;
        padding: 5px;
        border: 1px solid transparent;
        border-radius: 4px;
        cursor: pointer;
        box-shadow: none;
        transition: all 200ms ease-in;
        &:hover {
            background-color: #f2f1f0;
        }
        &:active {
            background-color: #ecebea;
        }
        &.popoverActive {
            border-color: #5092e3;
            background-color: #e0e6ee;
        }
    }
}
