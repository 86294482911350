/// <reference path="../../../../node_modules/@bimser/components/dist/stylesheets/main.scss" />
@import "mainstyles";

$headerHeight: 40px;
$footerHeight: 40px;

@media (min-width: 320px) and (max-width: 667px) {
	.DashboardContainer {
		.DashboardContent {
			.DashboardBoxFooter {
				justify-content: flex-start !important;
			}
		}
		.FullSizeBox {
			&:last-child {
				margin-left: 0 !important;
			}
		}
	}
}

.DashboardContainer {
	height: calc(100% - 12px);
	width: 100%;
	display: flex;
	flex-wrap: wrap;

	.FullSizeBox {
		flex: 1;
		height: 100%;
		min-height: 670px;
		width: calc(50% - 5px);
		//background-color: #faf9f8;
		margin: 0 10px 0 10px;
		display: flex;
		flex-direction: column;
		&:first-child {
			margin-right: 20px;
		}
		&:last-child {
			margin-left: 20px;
		}
		:global {
			.skeleton {
				margin: 0 10px;
			}
		}
	}

	.HalfSizeBox {
		flex: 0.5;
		height: 50%;
		overflow: hidden;

		&:last-child {
			.footer {
				padding-left: 10px !important;
			}
		}

		&.AnnouncementBox,
		.LastVisitedDocuments {
			display: block;
		}
	}

	@media (max-width: 667) {
		height: 100%;
	}

	.BoxContainer {
		height: 100%;

		&.withTabs {
			padding-top: 28px;
			& .withHeader.withFooter {
				height: calc(100% - #{$headerHeight} - #{$footerHeight} + 1px) !important;
			}
			:global {
				.ant-tabs-bar {
					border-bottom: none !important;
				}
			}
		}

		.header {
			width: 100%;
			height: $headerHeight;

			.title {
				width: 100%;
				line-height: $headerHeight;
				font-size: 16px;
				line-height: 28px;
				font-weight: 600;
				display: flex;
				align-items: center;

				@include themify($themes) {
					color: themed("startupBoxHeaderTitleColor");
				}
				.refreshApproves {
					width: 30px;
					height: 24px;
					margin-left: 5px;
					cursor: pointer;
					color: #3a3a38;

					&:hover {
						transform: scale(1.1);
					}
				}
			}

			.favoritesTitle {
				padding-left: 10px;
			}
		}

		.content {
			height: calc(100%);
			min-height: 225px;
			width: 100%;
			box-sizing: border-box;
			background-color: #faf9f8;
			box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
			//overflow: hidden;

			&.withHeader {
				height: calc(100% - #{$headerHeight});
			}

			&.withFooter {
				height: calc(100% - #{$footerHeight});
			}

			&.withHeader.withFooter {
				height: calc(100% - #{$headerHeight} - #{$footerHeight});
			}

			:global {
				.ant-list-item {
					padding: 0;
				}
			}

			.boxItem {
				height: 56px;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0 10px;
				box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
				background-color: #faf9f8;

				&:hover {
					background-color: #fff;
					box-shadow: 0px 8px 10px 0 rgba(0, 0, 0, 0.2);
					z-index: 9999;
				}

				.itemInfo {
					height: 52px;
					width: calc(100% - 132px);
					max-width: calc(100% - 127px);
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-left: 10px;
					cursor: pointer;
					line-height: 1.2;
					font-weight: 300;
					font-size: 14px;
					letter-spacing: 0.28px;
					color: #252421;

					.infoTitle {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.itemDate {
					width: 100px;
					max-width: 100px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					font-size: 12px;
					text-align: right;
				}
			}

			.listItem {
				margin: 5px 5px 5px 5px;
				padding: 10px 0;
				border-radius: 6px;
				background-color: #ffffff;
				box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
				cursor: pointer;
				transition: all 200ms ease;
				transition-property: background-color, box-shadow;
				position: relative;
				max-width: calc(100% - 10px);

				.events {
					width: 30%;
					opacity: 0;
					transition: opacity 200ms ease;
					position: absolute;
					top: 50%;
					right: 15px;
					transform: translate(0, -50%);

					ul {
						float: right;
						list-style: none;
						padding: 0;
						margin: 0;

						li {
							:global {
								.ant-btn {
									height: 20px;
									width: 80px;
									margin: 2.5px 0 2.5px 0;

									span {
										display: block;
										font-size: 10px;
										font-weight: bold;
										text-align: center;
										color: #b4b4b4 !important;
									}
								}

								.ant-btn-primary {
									span {
										color: #ffffff !important;
									}
								}
							}
						}
					}
				}

				&:hover {
					background-color: #f5f5f5;
					box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);

					.listItemContainer {
						.title,
						.user,
						.department {
							color: #333d47;
						}
					}

					&.hasButton {
						.info {
							opacity: 0;
						}

						.events {
							opacity: 1;
						}
					}
				}

				.listItemContainer {
					.title {
						float: left;
						width: 100%;
						font-size: 14px;
						font-weight: bold;
						color: #777777;
						line-height: 18px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.isViewed {
						font-weight: 600 !important;
					}

					.user {
						float: left;
						font-size: 12px;
						font-weight: normal;
					}

					.department {
						float: left;
						font-size: 12px;
						font-weight: 300;
						margin-left: 5px;
					}
				}

				.info {
					font-size: 10.5px;
					text-transform: uppercase;
					font-weight: 900;
					color: #4a90e2;
					transition: opacity 200ms ease;
					display: inline-block;
					line-height: 18px;
				}
			}
		}

		.footer {
			width: 100%;
			height: $footerHeight;
			// box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
			position: relative;
			display: flex;
			align-items: flex-end;
			justify-content: flex-end;

			@include themify($themes) {
				//background: themed('dasboardBoxFooterBackgroundColor');
			}

			.link {
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 0.14px;
				display: flex;

				@include themify($themes) {
					color: themed("startupBoxFooterLinkColor");
				}
			}

			.icon {
				margin-left: 15px;

				svg {
					@include themify($themes) {
						color: themed("startupBoxFooterIconColor");
					}
				}
			}
		}

		.announcementContent,
		.favoriteContent,
		.lastVisitedContent,
		.delegationContent {
			margin: 0 0 0 5px !important;
			width: calc(100% - 15px) !important;
			//overflow: hidden;
		}
	}
}

@media (min-width: 320px) and (max-width: 667px) {
	.startupContainer {
		padding: 16px 0 !important;
	}
}

.startupContainer {
	height: 100%;
	font-family: Segoe WP;
	padding: 30px 0;

	.startupColumn {
		height: 100%;
	}

	.hasHeader {
		height: calc(100% - #{$headerHeight});
	}

	.hasFooter {
		height: calc(100% - #{$footerHeight} - 36px);
	}

	.hasFooter.hasHeader {
		height: calc(100% - #{$headerHeight} - #{$footerHeight});
	}

	.width50 {
		width: 50%;
	}

	.width100 {
		width: 100%;
	}

	:global {
		.dColumn {
			min-height: auto !important;
		}
	}

	.twoColumn {
		:global {
			.ant-list-item {
				float: left;
				width: 50%;
			}
		}

		.title {
			height: 30px;
		}
	}

	.boxContent {
		flex-basis: 100% !important;
	}

	@media (max-width: 767px) {
		.boxContent {
			min-height: auto !important;
		}
	}

	.startupBox {
		overflow: hidden;
		//height: calc(100% - 16px);
		height: 100%;

		@include themify($themes) {
			//  box-shadow: 0 0 20px 0 themed('startupBoxShodowColor');
			//background-color: themed("startupBoxBackgroundColor");
		}

		&.FavoritesBox {
			.listItemContainer {
				padding: 11px 10px 11px 10px !important;
			}
		}

		&.LastVisitedDocumentsBox {
			.hasFooter {
				height: calc(100% - #{$footerHeight} - 36px);
			}
			.listItemContainer {
				padding: 10px 10px 9px 10px !important;
			}
		}

		.header {
			width: 100%;
			height: $headerHeight;

			@include themify($themes) {
				//border-bottom: solid 1px themed('dasboardBoxHeaderBorderColor');
			}

			.title {
				width: 100%;
				line-height: $headerHeight;
				font-size: 16px;
				line-height: 28px;
				font-weight: 600;
				display: block;
				text-indent: 10px;

				@include themify($themes) {
					color: themed("startupBoxHeaderTitleColor");
				}
			}

			.count {
				position: relative;
				top: -3px;
				left: 10px;
				height: 24px;
				width: 24px;

				:global {
					.ant-badge {
						width: 24px;
						height: 24px;

						sup {
							height: 24px;
							width: 24px;
							line-height: 24px;
							text-align: center;
							font-size: 13px;
							font-weight: 900;
						}
					}
				}
			}
		}

		.content {
			width: 100%;
			box-sizing: border-box;
			background-color: #faf9f8;
			box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
			overflow: hidden;

			@include breakpoint(sm) {
				flex-basis: 100% !important;
			}

			@include breakpoint(md) {
				flex-basis: 100% !important;
			}

			& > div > div {
				@include breakpoint(sm) {
					position: relative !important;
				}

				@include breakpoint(md) {
					position: relative !important;
				}
			}

			:global {
				.ant-list-item {
					padding: 0;
				}

				.ant-spin-container {
					// padding: 5px 5px;
					// @include themify($themes) {
					// 	background-color: themed("startupBoxBackgroundColor");
					// }
				}
			}

			.boxItem {
				height: 60px;
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				padding: 0 10px;
				box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
				background-color: #faf9f8;

				&:hover {
					background-color: #fff;
					box-shadow: 0px 8px 10px 0 rgba(0, 0, 0, 0.2);
					z-index: 9999;
				}

				.itemInfo {
					height: 52px;
					width: calc(100% - 132px);
					max-width: calc(100% - 127px);
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: center;
					margin-left: 10px;
					cursor: pointer;
					line-height: 24px;
					font-weight: 600;
					font-size: 14px;

					.infoTitle {
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}

				.itemDate {
					width: 100px;
					max-width: 100px;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					font-size: 12px;
					text-align: right;
				}
			}

			.listItem {
				margin: 5px 5px 5px 5px;
				padding: 10px 0;
				border-radius: 6px;
				background-color: #ffffff;
				box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
				cursor: pointer;
				transition: all 200ms ease;
				transition-property: background-color, box-shadow;
				position: relative;
				max-width: calc(100% - 10px);

				.events {
					width: 30%;
					opacity: 0;
					transition: opacity 200ms ease;
					position: absolute;
					top: 50%;
					right: 15px;
					transform: translate(0, -50%);

					ul {
						float: right;
						list-style: none;
						padding: 0;
						margin: 0;

						li {
							:global {
								.ant-btn {
									height: 20px;
									width: 80px;
									margin: 2.5px 0 2.5px 0;

									span {
										display: block;
										font-size: 10px;
										font-weight: bold;
										text-align: center;
										color: #b4b4b4 !important;
									}
								}

								.ant-btn-primary {
									span {
										color: #ffffff !important;
									}
								}
							}
						}
					}
				}

				&:hover {
					background-color: #f5f5f5;
					box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.26);

					.listItemContainer {
						.title,
						.user,
						.department {
							color: #333d47;
						}
					}

					&.hasButton {
						.info {
							opacity: 0;
						}

						.events {
							opacity: 1;
						}
					}
				}

				.listItemContainer {
					.title {
						float: left;
						width: 100%;
						font-size: 14px;
						font-weight: bold;
						color: #777777;
						line-height: 18px;
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}

					.isViewed {
						font-weight: 600 !important;
					}

					.user {
						float: left;
						font-size: 12px;
						font-weight: normal;
					}

					.department {
						float: left;
						font-size: 12px;
						font-weight: 300;
						margin-left: 5px;
					}
				}

				.info {
					font-size: 10.5px;
					text-transform: uppercase;
					font-weight: 900;
					color: #4a90e2;
					transition: opacity 200ms ease;
					display: inline-block;
					line-height: 18px;
				}
			}
		}

		.footer {
			width: 100%;
			height: $footerHeight;
			// box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.16);
			position: relative;
			padding-top: 10px;
			padding-left: 10px;

			@include themify($themes) {
				//background: themed('dasboardBoxFooterBackgroundColor');
			}

			.link {
				line-height: $footerHeight;
				font-size: 14px;
				font-weight: 600;
				letter-spacing: 0.14px;

				@include themify($themes) {
					color: themed("startupBoxFooterLinkColor");
				}
			}

			.icon {
				margin-left: 5px;

				svg {
					@include themify($themes) {
						color: themed("startupBoxFooterIconColor");
					}
				}
			}
		}
	}
}

:global {
	@media (min-width: 992px) and (max-width: 1360px) {
		.main-responsive {
			flex-wrap: wrap;
			flex-direction: inherit !important;

			> div {
				&:nth-child(1),
				&:nth-child(2) {
					flex: 0 0 50% !important;
					max-width: 50%;
				}

				&:nth-child(2) {
					padding-right: 0px !important;
				}

				&:nth-child(3) {
					flex: 0 0 100% !important;
					max-width: 100%;
					margin-top: 1.5rem !important;
				}
			}
		}
	}

	@media (max-width: 992px) {
		.main-responsive {
			> div {
				&:nth-child(1) {
					margin-bottom: 1.5rem !important;
				}

				&:nth-child(3) {
					margin-top: 1.5rem !important;
				}
			}
		}
	}
}

.favoritesBoxContainer,
.lastDocumentsBoxContainer,
.announcementBoxContainer {
	height: 100%;
	:global {
		.ant-list-empty-text {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
}

.approvesBoxContainer {
	height: 100%;

	:global {
		.ant-list-empty-text {
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
	}
	.approveItem {
		height: 56px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 0 20px 0 10px;
		// box-shadow: 0px 1px 3px -1px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
		background-color: #faf9f8;
		color: #5f5e5a;

		&:hover {
			background-color: #fff;
			box-shadow: 0px 8px 10px 0 rgba(0, 0, 0, 0.2);
			border: none !important;
			z-index: 9999;
			//transform: scale(1.01);
			.approveRibbon {
				visibility: visible;
				:global {
					.ms-OverflowSet-overflowButton {
						&:hover {
							background-color: #e2e0de !important;
							border-radius: 0 !important;
						}
					}
				}
			}
		}

		&.active {
			background-color: #fff;
			box-shadow: 0px 8px 16px 0 rgba(0, 0, 0, 0.1);
			.approveRibbon {
				visibility: visible;
				:global {
					.ms-OverflowSet-overflowButton {
						background-color: #e2e0de !important;
						border-radius: 0 !important;
					}
				}
			}
		}

		.approveRibbon {
			width: 25%;
			height: 56px;
			visibility: hidden;
			:global {
				.BCRibbonBar {
					height: 56px !important;
					.ms-CommandBar {
						padding-right: 0;
						padding-left: 0;
						height: 56px !important;
						.ms-OverflowSet {
							height: 56px !important;
						}
					}
				}
			}
		}

		.approveInfo {
			height: 52px;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-left: 10px;
			cursor: pointer;

			.projectCaption {
				font-weight: 300;
				font-size: 14px;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 1.2;
				letter-spacing: 0.28px;
				color: #252421;
			}

			.approveDescription {
				font-weight: 300;
				font-size: 12px;
				overflow: hidden;
				text-overflow: ellipsis;
				line-height: 1.5;
				color: #5f5e5a;
			}
		}

		.approveDate {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-size: 12px;
			text-align: right;
			color: #5f5e5a;
			font-weight: 400;
			letter-spacing: 0.41px;
			margin: 0 0 0 auto;
			padding-left: 10px;
		}
	}
}

.customRibbon {
	--font-family: Segoe WP;
	font-weight: 300 !important;

	:global {
		.ms-ContextualMenu-link {
			height: 40px;
			padding: 0 10px !important;
		}

		.ms-ContextualMenu-link:hover {
			background-color: #e2e0de !important;
			.ms-ContextualMenu-itemText {
				color: #777 !important;
			}
		}

		.ms-ContextualMenu-item {
			height: 40px;
			line-height: 40px;
		}

		.ms-ContextualMenu-itemText {
			margin-left: 10px !important;
		}

		.ms-ContextualMenu-list {
			font-family: Segoe WP;
			width: 220px;
		}
	}
}

.startupTabs {
	--font-family: Segoe WP;
	height: 100%;
	margin-top: 2px;

	:global {
		.ant-tabs-content {
			height: calc(100% - 39px);
			padding-top: 5px;
		}

		[role="tablist"] {
			margin: 0 !important;
			padding-left: 10px;
		}

		.ant-tabs-nav-container {
			margin: 0 10px 5px 10px !important;
		}

		.ant-tabs-tab {
			max-width: unset !important;
			padding: 4px 0 !important;
			font-size: 16px !important;
			font-weight: 600 !important;
			color: #5f5e5a !important;
			margin-right: 30px !important;
			margin-left: 0 !important;

			span {
				font-size: 16px;
			}
		}
		.ant-tabs-tab-active {
			color: #252421 !important;
			text-shadow: unset !important;
			font-weight: 600 !important;
		}
		.ant-tabs-top-bar {
			background-color: none !important;
			overflow: unset !important;
		}
		.ant-tabs-ink-bar {
			background-color: #0079d5;
			height: 2px !important;
		}
	}
}

.startupBoxEmptyWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;

	:global {
		.label {
			display: unset;
			cursor: pointer;
			text-decoration: underline;
		}
	}

	.emptyInfoTitle {
		line-height: 25px;
		font-size: 14px;
		font-weight: 600;
	}

	.emptyInfo {
		line-height: 25px;
		font-size: 12px;
		font-weight: 100;
	}

	.emptyImage {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
		max-width: 25%;
		max-height: 50%;
		margin-bottom: 10px;
	}
}

.announcementBoxEmpty {
	height: 130px !important;
}

.recentFilesBoxEmpty {
	height: 130px !important;
}

.favoritesBoxEmpty {
	max-height: 130px !important;
}
