.reportContainerStyles {
    height: 1000px;
    width: 100%;
}

.reportDesignerContent {
    height: 100%;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    border: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    z-index: 999999;

    .reportDesigner {
        height: calc(100% - 40px);

        :global {
            .dxrd-wizard-page {
                position: inherit;
                overflow-y: scroll;
                max-height: 350px;

                .dxrd-wizard-add-queries-page {
                    position: inherit;

                    .dx-treelist-text-wrapper {
                        display: flex;

                        .dx-treelist-text {
                            margin-left: 5px;
                        }
                    }
                }
            }

            .dxrd-wizard-navigation {
                margin: 0 20px;
            }
        }
    }
}

.WorkflowMenuHeader {
    float: left;
    width: 100%;
    padding: 9px 15px;
    height: 40px;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    align-items: center;
    background-color: rgb(240, 242, 245) !important;
    transition: height 100ms ease-in;
    padding-left: 0px;

    .WorkflowMenuTitle {
        float: left;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 80%;
        font-weight: 600;
        transition: all 100ms ease-in;
        transition-property: color;
        white-space: nowrap;
        color: var(--primary-color-6);
        margin-left: 15px;
    }

    .WorkFlowMenuEvents {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 70%;
        .Actions {
            display: flex;
            .ButtonClose {
                margin-top: 3px;
                width: 24px;
                height: 24px;
                cursor: pointer;
                margin-left: 5px;
                line-height: 24px;
                text-align: center;
                transition: all 200ms ease-in;
                transition-property: background-color, border-color;
                display: flex;
                justify-content: center;
                align-items: center;
                &:hover {
                    background-color: #d02e39 !important;
                }
            }
        }
    }

    :global {
        .ant-btn {
            &:hover {
                background-color: #e0dfdd !important;
            }
        }
    }
}
