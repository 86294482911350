.compactModeCheckbox {
    padding-top: 5px;
    padding-left: 10px;
}

.InputArea {
    margin-bottom: 5px;

    > label {
        > span {
            font-size: 13px;
            margin-right: 12px;
        }
    }

    &:nth-child(2) {
        width: 150px;

        > label {
            display: flex;

            > span {
                margin-right: 20px;
            }
        }
    }
}