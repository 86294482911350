.empty {
}

:global {
    .ant-alert {
        .ant-alert-message {
            font-size: 14px;
            font-weight: 600;
        }

        .ant-alert-description {
            font-size: 12px;
            line-height: 14px;
        }
    }
}