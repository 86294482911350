.treeView {
    :global {
        .ant-tree-node-content-wrapper {
            line-height: 33px;
            margin-left: 0 !important;
        }
    }
}

.codeEditorContainer {
    width: 100%;
    height: 200px;
}

.modalHeader {
    height: 75px;
    padding: 10px 0px 15px 30px !important;
    background: #fff;
    border-bottom: 1px solid #d2d1d0;
    position: relative;
    
    :global {
        .modal-title-text {
            width: unset;
            font-size: 18px;
            font-weight: 600;
            color: #25241f;
        }
    }

    .headerButtons {
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        .saveButton {
            float: right;
            display: flex;
            align-items: center;
            padding: 3px 13px;
            border: 1px solid transparent;
            margin-right: 5px;

            &:hover {
                border-radius: 5px;
                border: 1px solid var(--primary-color-5);
            }

            span {
                margin-left: 5px;
                font-family: Source Sans Pro, sans-serif;
                font-size: 14px;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.2;
                letter-spacing: 0.28px;
                color: #252420;
            }
        }
        .closeButton {
            float: right;
            border-left: 1px solid #d2d1d0;
            padding-left: 10px;
            padding-top: 5px;
            margin: 0px;

            i {
                font-size: 14px !important;
            }
        }
    }

    .subTitle {
        display: flex;
        align-items: center;
        span {
            font-size: 12px !important;
            color: #9f9f9f !important;
            display: flex;
            :global{
                .bcIcon{
                    margin: 0 5px;
                }
            }
        }
    }
}
