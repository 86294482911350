/// <reference path="../../stylesheets/main.scss"/>
@import 'mainstyles';

.hasCustomColor {
  width: 100%;

  :global {
    .ant-checkbox {
      & + span {
        color: inherit !important;
      }
    }
  }
}

:global {
  @include themify($themes) {
    .ant-checkbox {
      & + span {
        color: themed('inputColor');
      }

      &.ant-checkbox-indeterminate {
        .ant-checkbox-inner {
          transition: none;

          &:after {
            background-color: var(--primary-color-6);
            width: 8px;
            height: 8px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: none;
            transition: none;
          }
        }
      }
    }

    .ant-checkbox .ant-checkbox-inner {
      background-color: transparent;
      border: solid 2px var(--primary-color-6);
      width: 18px;
      height: 18px;
      border-spacing: initial !important;
    }

    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: var(--primary-color-6);
        }
      }
    }
  }
}
