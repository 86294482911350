/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

.deviceViewer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;

    @include themify($themes) {
        background: themed("deviceViewerBackgroundColor");
    }

    .toolbar {
        width: 100%;
        height: 50px;
        text-align: center;
        padding-top: 13px;

        @include themify($themes) {
            background-color: themed("deviceViewerToolbarBackgroundColor");
        }

        :global {
            .label {
                font-size: 12px;
                margin: 0 5px 0 15px;
                display: inline;
                &:first-child {
                    margin-left: 0;
                }
            }
        }

        .closeButton {
            width: 32px;
            height: 32px;
            position: absolute;
            top: 9px;
            right: 15px;
            cursor: pointer;
            opacity: 0.4;
            transition: opacity 200ms ease;

            @include themify($themes) {
                color: themed("deviceViewerCloseIconColor");
            }

            svg {
                vertical-align: baseline;
            }

            &:hover {
                opacity: 1;
            }

            &:active {
                opacity: 0.2;
            }
        }

        .dimensionInput {
            width: 80px;
            display: inline-block;
            margin: 0 5px;
        }

        .comboboxItem {
            width: 160px;
            display: inline-block;
            margin: 0 5px;
            text-align: left;
        }
    }

    .deviceTypeSelector {
        width: 100%;
        height: 15px;
        position: relative;

        @include themify($themes) {
            background: themed("deviceViewerDeviceTypeSelectorBgColor");
        }

        .deviceTypeSelectorButtons {
            transform-origin: 50% 0;

            .deviceTypeButton {
                height: 15px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
                cursor: pointer;

                @include themify($themes) {
                    border-right: solid 2px themed("deviceViewerDeviceTypeSelectorBorderColor");
                    border-left: solid 2px themed("deviceViewerDeviceTypeSelectorBorderColor");
                }

                &:hover {
                    @include themify($themes) {
                        background: themed("deviceViewerDeviceTypeSelectorBgHoverColor");
                    }
                }

                &.deviceType2560 {
                    width: 2560px;
                }

                &.deviceType1440 {
                    width: 1440px;
                }

                &.deviceType1024 {
                    width: 1024px;
                }

                &.deviceType768 {
                    width: 768px;
                }

                &.deviceType425 {
                    width: 425px;
                }

                &.deviceType375 {
                    width: 375px;
                }

                &.deviceType320 {
                    width: 320px;
                }
            }
        }

        .zoomValue {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, 0);
            font-size: 12px;
            line-height: 15px;
            @include themify($themes) {
                color: themed("deviceViewerDeviceTypeSelectorTextColor");
            }
        }
    }

    .contentResizer {
        width: calc(100% - 60px);
        height: calc(100% - 125px);
        margin: 30px;
        transform-origin: 50% 0;

        .content {
            box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px 0px;
            position: relative;
            margin: 0 auto;
            max-width: 100%;

            .resizeTooltip {
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 99;

                &:before {
                    content: attr(title);
                    display: block;
                    line-height: 15px;
                    padding: 0 10px;
                    background: rgba(0, 0, 0, 0.6);
                    color: #ddd;
                    font-size: 12px;
                }
            }

            .deviceViewerResizerHandlers {
                div {
                    &:hover,
                    &:active {
                        @include themify($themes) {
                            background-color: themed("deviceViewerResizerBorderColor");
                            border-color: themed("deviceViewerResizerBorderColor");
                        }
                    }
                }

                .right {
                    right: -20px !important;
                    width: 20px !important;

                    @include themify($themes) {
                        background-color: themed("deviceViewerResizerBackgroundColor");
                        border-left: solid 1px themed("deviceViewerResizerBorderColor");
                    }
                }

                .bottom {
                    bottom: -20px !important;
                    height: 20px !important;

                    @include themify($themes) {
                        background-color: themed("deviceViewerResizerBackgroundColor");
                        border-top: solid 1px themed("deviceViewerResizerBorderColor");
                    }
                }

                .left {
                    border-right: solid 1px transparent;
                    left: -20px !important;
                    width: 20px !important;
                }

                .bottomRight {
                    right: -20px !important;
                    bottom: -20px !important;

                    @include themify($themes) {
                        background-color: themed("deviceViewerResizerBackgroundColor");
                    }
                }

                .bottomLeft {
                    left: -20px !important;
                    bottom: -20px !important;
                }
            }
        }
    }
}
