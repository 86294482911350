/// <reference path="../../stylesheets/main.scss"/>
@import "mainstyles";

:global {
    @include themify($themes) {
        .ant-tabs {
            .ant-tabs-nav {
                margin: 0;
            }
            .ant-tabs-nav-list {
                .bcIcon {
                    width: 16px;
                    height: 16px;
                    margin-right: 8px;
                    transition: color 200ms ease;
                    vertical-align: middle;
                }
                .ant-tabs-tab {
                    padding-right: 5px;
                    padding-left: 3px;
                }
            }
            .ant-tabs-nav-operations {
                font-size: 16px;
            }

            & > .ant-tabs-content-holder {
                height: 100%;
                & > .ant-tabs-content {
                    height: 100%;
                }
            }
            &:not(.ant-tabs-left) {
                .ant-tabs-nav-list {
                    .ant-tabs-tab {
                        margin: 0 10px;
                    }
                }
            }
        }
    }
}

.scrollHeight {
    min-height: 150px;
}

.readOnlyCursor {
    :global {
        .ant-tabs-tab-disabled {
            cursor: pointer !important;
        }
    }
}