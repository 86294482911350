.treeEditor {
    :global {
        .ant-tree-treenode {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0px;

            .ant-tree-title {
                span {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .ant-tree-switcher {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
        }
    }
}