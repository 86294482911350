/// <reference path="../../../../node_modules/@bimser/components/dist/stylesheets/main.scss" />
@import "mainstyles";

.listEditor {
    .ribbonPanel {
        display: inline-block;
        float: right;

        :first-child {
            float: right;
        }

        :global {
            .ms-Button-label {
                font-family: Source Sans Pro, sans-serif;
                font-size: 13px;
            }

            .ms-Button-flexContainer {
                i {
                    background: transparent;
                }
            }
        }
    }

    .layoutHeader {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;

        .header {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row-reverse;
            .saveButton {
                float: right;
                display: flex;
                align-items: center;
                padding: 3px 13px;
                border: 1px solid transparent;
                margin-right: 5px;

                &:hover {
                    border-radius: 5px;
                    border: 1px solid var(--primary-color-5);
                }

                span {
                    margin-left: 5px;
                    font-family: Source Sans Pro, sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.2;
                    letter-spacing: 0.28px;
                    color: #252420;
                }
            }
            .closeButton {
                float: right;
                border-left: 1px solid #d2d1d0;
                padding-left: 10px;
                padding-top: 5px;
                margin: 0px;

                i {
                    font-size: 14px !important;
                }
            }
        }

        .titleBox {
            width: 100%;
            float: left;

            span {
                width: unset;
                font-size: 18px;
                font-weight: 600;
                color: #25241f;
            }
        }
    }

    .list {
        height: calc(100% - 100px);

        :global {
            .ant-input-search {
                margin-bottom: 10px !important;
            }

            .ant-list-item {
                padding: 0px !important;
            }
        }

        .title {
            font-family: Source Sans Pro, sans-serif;
            font-size: 14px;
            display: block;
            margin: 10px 0px;
            color: #25241f;
            i {
                margin-left: 10px;
                opacity: 0.5;
                font-size: 12px;
            }
        }

        .item {
            width: calc(100% - 15px);
            padding-left: 10px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: background-color 200ms ease;
            user-select: none;
            border-radius: 0px;
            box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
            border: solid 1px transparent;
            background: #fff;

            &:hover {
                border: solid 1px #a8a8a6;
                .moreIcon {
                    opacity: 1;
                }
            }

            .icon {
                float: left;
                width: 25px;
                height: 25px;
                margin-right: 10px;

                .custom {
                    width: 100%;
                    height: 100%;
                }
            }

            .serverText {
                color: var(--primary-color-6);
                display: inline-block;
                margin-right: 5px;
            }

            .listItemContent {
                float: left;
                width: calc(100% - 75px);
                flex-flow: column;
                justify-content: center;
                display: flex;
                height: 58px;

                div {
                    margin-top: -5px;
                }

                h3,
                p {
                    margin: 0;
                    text-overflow: ellipsis;
                    max-width: 100%;
                    white-space: nowrap;
                    overflow: hidden;

                    @include themify($themes) {
                        color: themed("ideStartupTextColor");
                    }
                }

                h3 {
                    font-size: 14px;
                    line-height: 24px;
                    font-weight: bold;
                }

                p,
                .serverText {
                    font-size: 12px;
                    line-height: 20px;
                }

                p {
                    display: inline-block;
                    margin-bottom: -5px;
                }
            }

            .removeButton {
                width: 18px;
                height: 18px;
            }

            .moreIcon {
                height: 100%;
                width: 45px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                opacity: 0;

                &:hover {
                    background: #f7f7f7;
                }
            }
        }

        .selected {
            background-color: var(--primary-color-o-1) !important;
            border: solid 1px #007ad5 !important;
        }
    }

    .viewer {
        width: 100%;
        height: 100%;

        .inspector {
            background: #fff;
            width: 100%;
            height: 100%;

            :global {
                .ant-tabs-top-content {
                    height: calc(100% - 56px);
                }

                .bc-property-inspector-item {
                    padding: 10px 15px !important;
                }

                .ant-tabs {
                    width: 100%;
                    .ant-tabs-bar {
                        margin: 0px;
                        padding-bottom: 16px;
                        border-bottom: 1px solid #eaeaea;
                    }
                }
            }
        }

        .disabledClick {
            opacity: 0.5;
        }

        .customPropertyItem {
            padding: 5px 10px !important;
        }

        .customPropertyItemFullWidth {
            width: 100% !important;

            textarea {
                margin-top: 5px !important;
            }
        }
    }

    .subTitle {
        display: flex;
        align-items: center;
        span {
            font-size: 12px !important;
            color: #9f9f9f !important;
            display: flex;
            :global {
                .bcIcon {
                    margin: 0 5px;
                }
            }
        }
    }
}

.removeTypeItem {
    width: 100%;
    display: flex;
    align-items: center;

    :global {
        .bcIcon {
            margin-top: 0 !important;
        }
    }
}