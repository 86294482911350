/// <reference path="../../stylesheets/main.scss"/>
@import "mainstyles";

.hasCustomColor {
    :global {
        .ant-transfer {
            &+span {
                color: inherit !important;
            }
        }
    }
}

:global {
    @include themify($themes) {
        .ant-transfer {
            width: calc(100% + 3px);
            overflow: auto;

            &+span {
                color: themed("inputColor");
            }
        }

        .ant-transfer .ant-transfer-inner {
            background-color: transparent;
            border: solid 2px var(--primary-color-6);
            width: 18px;
            height: 18px;
        }
    }

    .ant-transfer-list-search-action {
        line-height: unset !important;

        .anticon {
            vertical-align: -1px !important;
        }
    }
}

.customSelect {
    width: 100%;
    max-width: 120px;
}

.readOnlyCursor {
    :global {
        .ant-transfer-list-content-item-disabled {
            cursor: default;
        }

        .ant-checkbox-disabled {
            cursor: default;   
        }
    }
}