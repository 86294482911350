/// <reference path="../../../../node_modules/@bimser/components/dist/stylesheets/main.scss" />
@import "mainstyles";

.columnListContainer {
    p {
        font-size: 14px;
        color: #777;
        margin: 0;
    }

    :global {
        .ant-list-item {
            padding: 0px !important;
            border: none;
            margin: 5px 0;
        }
    }

    .columnListScrollContainer {
        min-height: 350px !important;
    }

    .columnList {
        min-height: 50px;
        border: none;
        margin-right: 30px;

        .columnListItem {
            width: 100%;
            transition: all 200ms ease;
            border-radius: 4px;
            border: 1px solid #ddd;
            padding-left: 10px !important;
            cursor: pointer;
            position: relative;

            &:hover {
                :global {
                    .bcIcon {
                        opacity: 1;
                    }
                }
            }

            &:active {
                background: rgba(0, 0, 0, 0.1);
                border: 1px solid var(--primary-color-6);
            }

            &.selected {
                background: rgba(0, 0, 0, 0.05);
                border: 1px solid var(--primary-color-6);

                :global {
                    .bcIcon {
                        opacity: 1;
                        color: var(--primary-color-6);
                    }
                }
            }

            b {
                &:empty {
                    display: none;
                }

                margin-right: 5px;
            }

            b,
            i {
                display: inline-block;
                line-height: 40px;
            }

            i {
                color: var(--primary-color-6);
                text-transform: capitalize;
                margin-left: 5px;
            }

            :global {
                .bcIcon {
                    cursor: pointer;
                    position: absolute;
                    right: 10px;
                    top: 5px;
                    opacity: 0;
                    transition: all 100ms ease;

                    &:hover {
                        color: var(--primary-color-6);
                    }
                }
            }
        }
    }
}

.rightSide {
    background-color: whitesmoke;
    padding-right: 0px !important;
    padding-left: 0px !important;

    .addedColumn {
        margin-left: 30px;

        b {
            margin-right: 5px;
            color: var(--primary-color-6) !important;
        }

        b,
        i {
            display: inline-block;
            line-height: 60px;
        }

        i {
            text-transform: capitalize;
            margin-left: 5px;
        }
    }

    .columnPropIns {
        height: calc(100% - 60px);

        .header {
            padding: 0 10px 0 20px !important;
        }

        :global {
            [role="tablist"] {
                margin: 0 0 0 30px !important;
            }

            .ant-collapse-header {
                padding: 12px 0 12px 20px !important;
            }

            .ant-collapse-content-box {
                padding: 0 10px 0 20px !important;
            }

            .ant-tabs {
                .ant-tabs-nav {
                    .ant-tabs-tab {
                        padding: 15px 0px !important;
                    }
                }
            }
        }
    }
}

.rightSide,
.leftSide {
    min-height: 550px;
}

.leftSideHeader {
    height: 75px;
    display: flex;
    align-items: center;
    padding: 0px 15px !important;
    background: #fff;
    border-bottom: 1px solid #d2d1d0;
}

.modalHeader {
    width: 100%;
}

.leftSide {
    background: #faf9f8;
    padding-top: 10px;
    padding-left: 15px !important;
    padding-right: 15px !important;

    .modalHeader {
        margin-bottom: 15px;

        &:after {
            content: "";
            display: block;
            border-bottom: solid 1px #ddd;
            margin-left: -30px;
        }

        h3 {
            font-weight: 900;
            font-size: 18px;
            display: inline;
            font-weight: 900;
            font-size: 18px;
            display: inline-block;
            line-height: 60px;
            margin: 0;

            @include themify($themes) {
                color: themed("modalTitleTextColor");
            }
        }

        :global {
            .ant-btn {
                background-color: transparent !important;
                border-color: transparent !important;
                box-shadow: none !important;
                border-left: 1px solid #ddd !important;
                border-radius: 0;
                margin-left: 25px;

                i {
                    vertical-align: text-bottom !important;
                    font-size: 18px !important;
                    color: #999 !important;
                }

                span {
                    font-size: 16px !important;
                    color: #999 !important;
                    font-weight: 600 !important;
                }

                &:hover {
                    i,
                    span {
                        color: var(--primary-color-6) !important;
                    }
                }
            }
        }
    }

    .actionButtons {
        float: right;
        margin-right: 30px;
        margin-top: 30px;

        :global {
            .ant-btn {
                padding: 0 10px;
                width: 95px;
                text-overflow: ellipsis;
                overflow: hidden;
                margin: 0 5px;
            }

            .ant-btn-ghost + .ant-btn-primary {
                margin-right: 0 !important;
            }

            .ant-btn-primary {
                margin-right: 10px;
            }
        }
    }
}

.rightSide:before,
.rightSide:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
}

.rightSide:before {
    z-index: 2;
    width: 1px;
    left: 0;
    background: #d2d1d0;
}

@media (max-width: 992px) and (min-width: 320px) {
    .rightSide:before {
        box-shadow: none !important;
    }
}

.ColumnsDesignerModalContainer {
    :global {
        .modalContentWrapper {
            padding: 0px !important;
        }
    }
}
