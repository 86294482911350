.TagGroup {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 8px;
    
    .SiteTagPlus {
        background: #fff;
        border-style: dashed;
        display: flex;
        align-items: center;
        margin: 0;
    }
    .EditTag {
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0;
    }
    .TagInput {
        width: 78px;
        vertical-align: top;
    }
    .TagIcon {
        display: block;
        margin-right: 4px;
    }
}
