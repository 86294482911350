.optionContent {
    :global {
        .ant-select-item-option-content {
            display: flex;
            align-items: center;
        }
    }
}

.bcMultipleComboboxWrapper {
    :global {
        .ant-select-selection-item-content {
            display: flex;
            align-items: center;
        }
    }
    &.fromMobile {
        :global {
            .ant-select-clear {
                opacity: 1 !important;
                height: 30px;
                width: 30px;
                background: none;
                margin-top: -15px;
                .anticon-close-circle {
                    padding: 9px;
                }
            }
        }
    }
}
