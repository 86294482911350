:global {
    .ant-input-group {
        & > .ant-input {
            height: 32px;
            &.ant-input-sm {
                height: 24px;
            }
            &.ant-input-lg {
                height: 40px;
            }
        }
        .ant-input-affix-wrapper {
            height: 32px;
            &.ant-input-affix-wrapper-sm {
                height: 24px;
            }
            &.ant-input-affix-wrapper-lg {
                height: 40px;
            }

            .ant-input {
                height: 22px;
                &.ant-input-lg {
                    height: 24px;
                }
            }
        }
    }
    .ant-input[disabled] {
        color: rgba(0, 0, 0, 0.6) !important;
    }
}

.inputStyles {
    &.textalignRight {
        text-align: right !important;
    }
    &.textalignLeft {
        text-align: left !important;
    }
    &.textalignCenter {
        text-align: center !important;
    }
}
