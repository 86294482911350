/// <reference path="../../stylesheets/main.scss" />

@import 'mainstyles';

.toolbar {
    z-index: 10000;

    & > li > :global(.ant-btn-group) > * {
        vertical-align: top;
    }

    &.bordered {
        :global {
            .ant-btn-group {
                @include themify($themes) {
                    border: solid 1px themed('toolbarButtonBorderColor');
                }

                border-radius: 4px;
            }
        }
    }

    .toolbarButton {
        border: none;
        background: transparent !important;
        border-radius: 0;

        @include themify($themes) {
            color: themed('toolbarTextColor');
        }

        &:hover, &:focus, &:active {
            background: transparent;
        }

        &.bordered {
            @include themify($themes) {
                border-right: solid 1px red;
                border-color: themed('toolbarButtonBorderColor') !important;
            }
        }

        .buttonContainer {
            padding: 0 2px;
            transition: background-color 200ms ease;
            border-radius: 5px;

            &:hover, &:focus {
                color: var(--primary-color-6);

                @include themify($themes) {
                    background: rgba(themed('toolbarButtonHoverColor'), .05);
                    color: themed('toolbarTextColor');
                }
            }

            &:active {
                @include themify($themes) {
                    background: rgba(themed('toolbarButtonHoverColor'), .1);
                }
            }
        }

        &:last-child {
            border: none !important;
        }
    }

    &.miniButtons {
        .toolbarButton {
            width: 30px;
            height: 30px;
            float: left;
            overflow: hidden;

            :global {
                .bcIcon {
                    width: 30px !important;
                    height: 30px !important;
                }
            }

            & > div {
                border-radius: 0 !important;
                width: 30px;
                padding: 0 !important;
            }
        }
    }

    :global {
        .ant-input, .ant-input-number .ant-input-number-input, .ant-select .ant-select-selection {
            @include themify($themes) {
                background: themed('toolbarInputBackgroundColor');
            }
        }

        .ant-btn {
            padding: 0;
        }

        .ant-btn-group {
            box-shadow: none !important;
            float: left;

            &:not(:last-child) {
                @include themify($themes) {
                    border-right: solid 1px themed('toolbarButtonBorderColor');
                }

                padding-right: 10px;
            }
        }
    }
}
