/// <reference path="../../stylesheets/main.scss" />

@import "mainstyles";

.menuItemShortcutText {
    margin-left: auto;
    padding-left: 45px;

    &.shortCutPlaceholder {
        width: 50px;
        min-height: 1px;
    }
}

.height100 {
    height: 100% !important;
}

.isMoreOverageItems {
    ul {
        overflow-y: auto;
        max-height: 95vh;
        margin-top: 2.5vh;

        /* width */
        &::-webkit-scrollbar {
            width: 5px;
            cursor: pointer;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #f8f8f8a2;
            cursor: pointer;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #d1d2d341;
            cursor: pointer;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
            cursor: pointer;
        }
    }

    li {
        .height100 {
            display: flex;
            align-items: center;
        }
    }
}

:global {
    .ant-menu {
        &.ant-menu-horizontal {
            border-bottom: 1px solid transparent;

            &>.ant-menu-item::after,
            &>.ant-menu-submenu::after {
                left: 0;
                right: 0;
            }

            &:not(.ant-menu-dark)>.ant-menu-item,
            &:not(.ant-menu-dark)>.ant-menu-submenu {
                padding: 0;
            }

            &>.ant-menu-submenu>.ant-menu-submenu-title {
                padding: 0 25px;
            }

            &>.ant-menu-item,
            &>.ant-menu-submenu {
                margin: 0;
            }
        }

        .ant-menu-submenu {
            .ant-menu-title-content {
                display: flex;
                align-items: center;

                >div {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .ant-menu-item {
            .ant-menu-title-content {
                display: flex;
                align-items: center;

                >div {
                    display: flex;
                    align-items: center;
                }
            }
        }

        .ant-menu-item-disabled {
            opacity: 0.4;
            .bcIcon {
                filter: grayscale(1);
            }
        }

        .ant-menu-item .bcIcon,
        .ant-menu-submenu .bcIcon {
            width: 16px;
            height: 16px;
            margin-right: 20px;
            color: #777;
            float: left;
            vertical-align: inherit;
            line-height: 100%;
        }

        .ant-dropdown-menu-item {
            .bcIcon {
                float: unset !important;
            }
        }
    }
}