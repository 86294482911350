/// <reference path="../../stylesheets/main.scss"/>
//@import 'mainstyles';

.togglePasswordVisibilityBtn {
    opacity: 0.5;
    cursor: pointer;
    transition: all 200ms ease;
    display: flex;

    &:hover {
        opacity: 1;
    }

    :global {
        .anticon {
            vertical-align: middle;
        }
    }
}

.passwordStyles {
    &.textSecurity {
        :global {
            input.ant-input {
                text-security: disc;
                -webkit-text-security: disc;
            }
        }
    }
    &.textalignRight {
        input {
            text-align: right !important;
        }
    }
    &.textalignLeft {
        input {
            text-align: left !important;
        }
    }
    &.textalignCenter {
        input {
            text-align: center !important;
        }
    }

    &.passwordStylesWithoutButton {
        &.textSecurity {
            -webkit-text-security: disc;
        }
        &.textalignRight {
            text-align: right !important;
        }
        &.textalignLeft {
            text-align: left !important;
        }
        &.textalignCenter {
            text-align: center !important;
        }
    }    
}
