/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

:global {
    @media (max-width: 992px) and (min-width: 320px) {
        .modalClass {
            width: 90% !important;
        }
    }
    
    @media (max-width: 600px) and (min-width: 320px) {
        .ant-modal {
            width: 95% !important;
            
            .ant-modal-content {
                min-width: 100% !important;
            }
        }
        
    }

    @media only screen and (min-width: 992px) {
        .modalClass {
            width: 1024px !important;
        }
    }

    .zoom-enter .ant-modal-close {
        display: none !important;
    }

    @include themify($themes) {
        .ant-modal-mask {
            background: themed("modalMaskBackgroundColor");
        }

        .ant-modal-body {
            padding: 0;
        }

        .ant-modal-wrap {
            text-align: center;

            &:before {
                content: "";
                display: inline-block;
                height: 100%;
                vertical-align: middle;
                width: 0;
            }

            .ant-modal {
                position: unset;

                &.zoom-enter {
                    .ant-modal-close {
                        display: none;
                    }
                }

                .ant-modal-content {
                    // position: unset;
                    background-color: themed("modalBackgroundColor");
                    min-width: 546px;
                    min-height: 304px;
                    box-shadow: 0 0 57px 0 rgba(0, 0, 0, 0.22);
                    border-radius: 2px !important;

                    .ant-modal-header {
                        border-radius: 2px 2px 0 0;
                        padding: 20px 15px;
                        margin-bottom: 5px;
                        border-color: themed("modalHeaderBorderColor");

                        .ant-modal-title {
                            .modal-title-text {
                                font-weight: 600;
                                font-size: 16px;
                                color: #25241f;
                                vertical-align: text-bottom;
                                text-overflow: ellipsis;
                                overflow: hidden;
                                width: 100%;
                                line-height: 20px;
                                display: block;
                                white-space: nowrap;
                            }
                        }
                    }

                    .ant-modal-footer {
                        padding: 30px;
                        border: none;
                        display: flex;
                        margin-left: auto;
                        justify-content: flex-end;
                        align-items: center;
                        width: 100%;

                        .ant-btn {
                            padding: 0 10px;
                            width: 120px;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            margin: 0 8px;
                        }

                        .ant-btn:last-child {
                            margin-right: 0 !important;
                        }

                        .ant-btn-ghost + .ant-btn-primary {
                            margin-right: 0 !important;
                        }

                    }

                    .ant-modal-close {
                        right: 15px !important;
                        top: 17px !important;

                        .ant-modal-close-x {
                            width: 22px !important;
                            height: 22px !important;
                            line-height: 22px !important;
                            opacity: 1;
                            transition: opacity 200ms ease;
                            font-size: 28px;

                            &:hover {
                                opacity: 0.5;
                            }

                            .bcIcon {
                                color: var(--icon-secondary-color) !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.modalContentWrapper {
    padding: 22px;
}

.modalStickyBar {
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    margin-top: 30px;
    padding: 0 30px;
}

.modalHeaderControls {
    margin-left: 20px;
    padding-left: 20px;
    display: inline-block;

    @include themify($themes) {
        border-left: solid 1px themed("modalHeaderBorderColor");
    }

    :global {
        .ant-btn {
            background: transparent !important;
            height: 22px;
            margin-right: 20px;
            border: none;
            padding: 0;
            display: inline-block;
            vertical-align: text-bottom;

            &:last-child {
                margin-right: 0;
            }

            .bcIcon {
                width: 22px;
                height: 22px;
                margin-right: 5px;
                float: left;

                svg {
                    vertical-align: middle;
                }
            }

            span {
                display: block;
                float: left;
                color: #777;
                height: 22px;
                line-height: 22px;
                vertical-align: middle;
            }
        }
    }
}
