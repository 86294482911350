// <reference path="../../stylesheets/main.scss"/>
@import "mainstyles";

.BCRibbonBar {
    height: 24px;
    float: left;
    width: 100%;

    :global {
        .ms-CommandBar {
            background: transparent;
            height: 24px !important;
            padding: 0;
        }

        li .ms-ContextualMenu-item.item-65 button {
            outline: none !important;
        }

        .ms-CommandBar .ms-Button {
            background: transparent;

            .bcIcon {
                width: 23px;
                height: 23px;
                transition: all 200ms ease;

                @include themify($themes) {
                    --primary-color-6: #{themed("ribbonBarIconColor")};
                }

                svg {
                    vertical-align: bottom;
                    height: 90%;
                }
            }

            &:hover {
                .bcIcon {
                    --primary-color-6: var(--primary-color);
                }
            }
        }

        .ms-OverflowSet {
            height: 24px;

            button {
                min-width: 26px !important;
            }

            .ms-OverflowSet-item,
            .ms-OverflowSet-overflowButton {
                border-radius: 3px;
                margin-right: 5px;
                border: 1px solid transparent;
                transition: border 200ms ease;

                &:hover {
                    box-shadow: none;
                    border: 1px solid #4a90e2;
                    border-radius: 3px;
                    background-color: rgba(74, 144, 226, 0.1);
                }

                button {
                    transition: background-color 200ms ease;
                    outline: none !important;

                    &.is-disabled {
                        pointer-events: initial;
                        cursor: not-allowed;
                        opacity: 0.5;
                        filter: grayscale(100%);
                    }

                    .ms-Button-flexContainer {
                        //flex-direction: column;
                    }

                    .bcIcon {
                        transition: color 200ms ease;

                        @include themify($themes) {
                            color: themed("toolbarTextColor");
                        }
                    }

                    &:hover:not(.is-disabled),
                    &:focus:not(.is-disabled) {
                        color: var(--primary-color-6);

                        .bcIcon {
                            color: var(--primary-color-6);
                        }
                    }

                    &:active {
                        @include themify($themes) {
                            background: rgba(themed("toolbarButtonHoverColor"), 0.1);
                        }
                    }
                }
            }

            .ms-OverflowSet-overflowButton {
                margin-right: 0;
            }
        }
    }
}

:global {
    .ms-ContextualMenu-Callout {
        border: none !important;
        margin: 1px;
        min-width: 250px;

        @include themify($themes) {
            box-shadow: 0 6px 16px 0 themed("menubarDropdownShadowColor");
        }

        .ms-ContextualMenu-list {
            &.is-open {
                .ms-ContextualMenu-item {
                    padding: 5px;
                    .ant-select {
                        width: 100% !important;
                    }
                }
            }
        }

        .ms-ContextualMenu {
            padding: 10px 0;

            @include themify($themes) {
                background-color: themed("menubarBackgroundColor");
            }

            button {
                transition: background-color 200ms ease;
                outline: none !important;

                &.is-disabled {
                    pointer-events: initial;
                    cursor: not-allowed;
                    opacity: 0.5;
                    filter: grayscale(100%);
                }
            }

            .ms-ContextualMenu-link {
                transition: all 200ms ease;

                * {
                    border: none !important;
                    outline: none !important;
                }

                &:hover {
                    @include themify($themes) {
                        background-color: themed("menubarBackgroundHoverColor");
                    }
                }

                .ms-ContextualMenu-itemText {
                    @include themify($themes) {
                        color: themed("menubarTextColor");
                    }

                    font-family: var(--font-family);
                    font-size: 14px;
                    font-weight: bold;
                    margin: 0;

                    &:hover {
                        color: var(--primary-color-6);
                    }
                }
            }
        }
    }
}
