@import "mainstyles";

.textViewerContent {
  height: 100% !important;
  position: relative;
  overflow: hidden;
}

.videoViewerContent {
  height: calc(100% - 20px) !important;
  position: relative;
  overflow: hidden;
}

.officeViewerContent {
  height: calc(100% - 20px) !important;
  position: relative;
  overflow: hidden;
}

.pdfViewerContainer {
  height: 100% !important;

  .pdfViewerContent {
    height: calc(100% - 32px);
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #f2f2f2;

    &.hideDocument {
      [class*="react-pdf"] {
        visibility: hidden;
      }
    }

    .pdfPasswordForm {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-content: center;

      .pdfPasswordFormInner {
        width: 300px;
        display: flex;
        flex-direction: column;

        .pdfPasswordLabel {
          margin-bottom: 10px;
        }

        .pdfPasswordButton {
          margin-top: 10px;
          width: 100%;
        }
      }
    }
  }
}

.tiffViewerContent {
  height: 100% !important;
  position: relative;
  overflow: hidden;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;

  img {
    max-height: 100%;
    margin: 3px auto;
  }
}

.imageViewerContent {
  height: 100% !important;
  position: relative;
  overflow: hidden;
  overflow-x: scroll;
  background-color: #f2f2f2;
  display: flex;
  align-items: center;

  img {
    margin: 3px auto;
  }
}

.pdfDocument {
  display: flex;
  align-items: center;
  flex-direction: column;

  svg {
    margin: 3px auto;
    border: 1px solid lightgray;
    border-radius: 2px;
  }

  .pdfPageNumber {
    position: absolute;
    font-size: 12px;
    bottom: 30px;
    left: 0;
    right: 0;
    text-align: center;
    color: gray;
  }

  :global {
    .react-pdf__Page__svg {
      margin: auto;
    }
  }
}

.groupDocsViewerContent {
  height: calc(100% - 20px) !important;
  position: relative;
  overflow: hidden;
}

.groupNoDocsViewerContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textViewerPrintableWrapper {
  display: none;

  .textViewerPrintableContent {
    font-family: Consolas, "Courier New", monospace;
    font-size: 14px;
    font-feature-settings: "liga" 0, "calt" 0;
    line-height: 19px;
    letter-spacing: 0px;
  }
}