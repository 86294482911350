/// <reference path="../../stylesheets/main.scss" />

@import 'mainstyles';

.init {
    cursor: default;
}

:global {
    .ant-list-empty-text {
        @include themify($themes) {
            color: themed('inputColor');
        }
    } 
    
    .ant-list-items{
        padding: 0 !important;
        margin: 0 !important;
    }
}