$resizer-space: 0px;
$holder-width: 8px;
$holder-border-width: 1px;

.content {

    &.top {
        padding: $resizer-space 0 0 0;
    }

    &.right {
        padding: $resizer-space 0 ;
    }

    &.bottom {
        padding: 0 0 $resizer-space 0;
    }

    &.left {
        padding: $resizer-space 0;
    }


    &.left, &.right {
        height: 100%;
        width: 100%;
    }

    &.top, &.bottom {
        width: 100%;
        height: 100%;
    }
}

.holder {
    position: absolute;
    z-index: 1;
    transition: border-color 200ms ease;
    user-select: none;

    &.left {
        left: 0;
        padding-right: $holder-width;
        border-left: solid $holder-border-width transparent;
    }

    &.right {
        right: 0;
        padding-left: $holder-width;
        border-right: solid $holder-border-width transparent;
    }

    &.bottom {
        padding-top: $holder-width;
        border-bottom: solid $holder-border-width transparent;
    }

    &.top {
        padding-bottom: $holder-width;
        border-top: solid $holder-border-width transparent;
    }

    &.left, &.right {
        cursor: col-resize;
        height: 100%;
        width: $holder-border-width;
    }

    &.top, &.bottom {
        cursor: row-resize;
        width: 100%;
        height: $holder-border-width;
    }

    &:hover, &:active {
        border-color: #aaa;
    }
}
