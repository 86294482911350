/// <reference path="../../stylesheets/main.scss" />

@import "mainstyles";

.toolboxContainer {
    height: 100%;

    :global {
        .BCRibbonBar {
            margin-top: 3px;
        }
    }
}
.lineContent {
    display: flex;
}

.toolboxItem {
    height: 48px;
    cursor: pointer;
    width: 100%;
    overflow: hidden;
    padding: 0px 10px 0px 20px;
    transition: all 150ms ease;
    margin-bottom: 1px;

    .text {
        float: left;
        font-size: 14px;
        font-weight: 600;
        user-select: none;
        max-width: calc(100% - 65px);
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        line-height: 48px;
        letter-spacing: normal;
        text-align: left;
        width: 100%;

        @include themify($themes) {
            color: themed("toolboxItemTextColor");
        }

        small {
            opacity: 0.6;
            font-style: italic;
            margin-left: 5px;
        }
    }

    :global {
        .bcIcon {
            width: 24px;
            height: 48px;
        }
    }

    .icon {
        float: left;
        margin-right: 15px;
        height: 48px;

        :global(.bcIcon) {
            width: 28px;
            height: 48px;
            vertical-align: text-bottom;

            @include themify($themes) {
                color: themed("toolboxItemIconColor") !important;
            }
        }
    }

    .moreIcon {
        float: left;
        transition: all 100ms ease;
        transform: rotate(90deg) !important;
        opacity: 0;
        visibility: hidden;
        box-shadow: none;
        float: right;
        margin-right: 10px;

        @include themify($themes) {
            color: themed("collapseHeaderTextColor");
        }

        svg {
            vertical-align: top;
        }

        &:hover {
            opacity: 1 !important;
        }
    }

    &:hover {
        background-color: #f0f1f2;

        .moreIcon {
            opacity: 0.5;
            visibility: visible;
        }
    }

    &.selected {
        background: "aliceblue"
    }

    &.passive {
        opacity: 0.3;
        :global {
            .bcIcon {
                --primary-color-6: var(--icon-secondary-color);
            }
        }
    }

    &.isDragging {
        opacity: 0.3;
    }

    &:active {
        background-color: #e0e0e0;
    }
}

.toolboxCollapse {
    margin: 0 0 10px 0;
}

.small {
    .toolboxItem {
        height: 40px;
        line-height: 40px;

        .icon {
            height: 40px;
        }

        .text {
            line-height: 40px;
        }

        :global {
            .bcIcon {
                width: 24px;
                height: 40px;
                margin-top: 0;
            }
        }
    }
}

.dragIconEmpty {
    width: 23px;
    margin-right: 10px;
}