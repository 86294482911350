:global {
    .ant-anchor-wrapper {
        min-width: 15vh;
    }
}
.activateBall {
    :global {
        .ant-anchor-ink-ball {
            display: inline-block !important;
        }
    }
}
.disabled {
    filter: grayscale(1);
}
