.ConfirmationBoxContainer {
    padding: 30px 0;
    padding-bottom: 0;

    :global {
        .ant-input {
            width: 80%;
            margin-left: 10%;
            margin-bottom: 10px;
        }
    }

    .ContentMessage {
        width: 100%;
        display: block;
        margin-bottom: 2rem;
        text-align: center;
    }

    .WarningMessage {
        width: 100%;
        display: block;
        text-align: center;
        margin-bottom: 1rem;
        background: #fffdbb;
        padding: 20px 0;
        min-height: 75px;
        line-height: 25px;

        .ConfirmationText {
            font-weight: bold;
            user-select: none;
            margin-left: 10px;
        }
    }
}

.ConfirmationBoxModal {
    :global {
        .ant-modal-footer .ant-btn-primary {
            &:disabled {
                opacity: 1;
                background-color: #bbbbbb;
                color: white;
            }
        }
    }
}
