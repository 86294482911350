.coloredBox {
    height: 32px;
    width: auto;
    color: #fff;
    transition: all 200ms ease;
    padding: 0 12px;
    line-height: 32px;
    border-radius: 2px;
    float: left;
    font-size: 12px;

    &.purple {
        background: #922a5c;
    }
}

.conjunctionBox {
    margin-right: 5px;
}

.statementContent {
    padding-left: 25px;
    position: relative;
    width: 100%;
    float: left;

    &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: 11px;
        height: 100%;
        border-left: solid 1px #ddd;
    }
}

.actionSelectBox {
    margin-left: 5px;
    :global {
        .ant-select-selection.ant-select-selection--multiple {
            outline: transparent;
            background: transparent;
        }

        .ant-select-sm .ant-select-selection--multiple .ant-select-selection__rendered li {
            margin-top: 6px;
        }

        .ant-select-selection--multiple .ant-select-selection__placeholder {
            margin-left: 0;
        }

        .ant-select-selector {
            background: #fff !important;
        }
    }

    &.disabled {
        opacity: 1 !important;
    }
}

.levelCollapseIcon {
    float: left;
    width: 17px;
    height: 32px;
    padding-right: 5px;

    &.collapsed {
        :global {
            .bcIcon {
                transform: rotate(0);
            }
        }
    }

    &.disabled {
        cursor: pointer !important;
        opacity: 0.7 !important;
    }

    &:hover {
        :global {
            .bcIcon {
                opacity: 1;
            }
        }
    }

    :global {
        .bcIcon {
            cursor: pointer;
            opacity: 0.7;
            height: 32px;
            width: 12px;
            transform: rotate(90deg);
        }
    }
}

.qb_condition_group_conjunction {
    :global {
        div.ant-select-selection--single {
            background-color: rgba(217, 83, 79, 0.3) !important;
        }
    }
}

.qb_condition_matching_modifier {
    float: left;
}

.qb_condition_operation {
    float: left;
}

.selectionBox {
    box-shadow: none !important;
    margin-right: 5px;
    padding: 0 3px;
    transition: all 200ms ease;

    span {
        color: #333 !important;
        font-weight: normal !important;
        font-size: 12px !important;
    }

    :global {
        .tableheader-sort-arrow,
        i.anticon {
            display: none !important;
        }
    }

    &.conjunction {
        background: #62009c !important;
        padding: 0 10px;
        width: auto;
        float: left;

        span {
            color: #fff !important;
        }

        &:hover {
            opacity: 0.9;
        }
    }

    &.matchingModifier {
        &:hover {
            background: var(--primary-color-1) !important;
        }
    }

    &.operation {
        &:hover {
            background: var(--primary-color-1) !important;
        }
    }
}

.addButton,
.deleteButton {
    border: none;
    box-shadow: none !important;
    width: 24px;
    padding: 0 2px;
    float: left;
}

.addButton {
    :global {
        .bcIcon {
            width: 15px !important;
            --icon-secondary-color: var(--primary-color-6);
        }
    }
}

.deleteButton {
    float: right;
    visibility: hidden;
    opacity: 0;
    transition: all 200ms ease;
    margin-left: 8px !important;

    :global {
        .bcIcon {
            transition: all 200ms ease;
            --icon-secondary-color: rgba(217, 82, 78, 1);
        }
    }

    &:hover {
        :global {
            .bcIcon {
                --icon-secondary-color: rgba(217, 82, 78, 0.5) !important;
            }
        }
    }
}

.selectionBoxOptions {
    :global {
        .ant-menu-item {
            margin: 2px 0 !important;
            height: 24px !important;
            line-height: 24px !important;
            .ant-menu-text-custom {
                font-weight: normal;
                font-size: 12px;
            }
        }
        .bcIcon {
            width: 24px !important;
            height: 16px !important;
            margin: 4px 5px 0 0 !important;
            float: left;
        }
        span {
            line-height: 24px;
            float: left;
        }
    }
}

.selectionTree {
    margin-right: 5px;
    float: left;

    :global {
        .ant-select {
            min-width: 100px;
            max-width: 137px;

            .ant-select-selection {
                height: 26px !important;
                background-color: #fff !important;

                .ant-select-selection__rendered {
                    line-height: 26px;
                    margin: 0 12px !important;

                    .ant-select-selection-selected-value {
                        padding: 0;
                        color: var(--icon-secondary-color);
                    }
                }
            }

            .ant-select-arrow {
                display: none;
            }
        }
    }
}

.selectionTreeIcon {
    :global {
        .ant-select {
            .ant-select-arrow {
                display: inline;
                transform: rotate(180deg);
                margin-top: -6px;
                pointer-events: auto;
            }
        }
    }
}

.conditionValueInput {
    width: 80px;
    margin-right: 5px;
    float: left;
}

.conditionItem {
    padding-left: 30px;
    width: 100%;
    float: left;
    position: relative;

    &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: 11px;
        height: 100%;
        border-left: solid 1px #ddd;
    }
}

.autoWidth {
    overflow: hidden;
    display: flex;
    width: auto !important;
}

.conditionItemContent {
    margin-bottom: 5px;
    width: 100%;
    float: left;
    transition: all 100ms ease;
    padding: 5px;
    border-radius: 2px;

    &.readonly {
        .readonlyText {
            color: #777;
            font-style: italic;

            b {
                font-weight: bold;
                color: #555;
                font-style: normal;
            }
        }
    }

    &:hover {
        background: var(--primary-color-1);

        .deleteButton {
            visibility: visible;
            opacity: 1;
        }
    }
}
