/// <reference path="../../stylesheets/main.scss"/>
@import 'mainstyles';

.hasCustomColor {
    :global {
        .ant-checkbox {
            & + span {
                color: inherit !important;
            }
        }
    }
}

:global {
    @include themify($themes) {
        .ant-checkbox {
            & + span {
                color: themed('inputColor');
            }
        }

        .ant-checkbox .ant-checkbox-inner {
            background-color: transparent;
            border: solid 2px var(--primary-color-6);
            width: 18px;
            height: 18px;
        }

        .ant-checkbox {
            &.ant-checkbox-checked {
                .ant-checkbox-inner {
                    background-color: transparent;
                }
            }
        }
    }
}

.horizantal {
    display: inline-flex;
    margin-left: 8px;
}

.vertical {
    height: 30px;
    line-height: 30px;
}
