/// <reference path="../../stylesheets/main.scss"/>

@import "mainstyles";

:global {


    .ant-picker-panel {
        min-width: 130px;
    }

    .ant-time-picker-clear {
        right: 30px;
        margin-top: -8px !important;
    }

    .ant-time-picker-input {
        margin: 0 2px;
        width: calc(100% - 4px);
    }

    .ant-time-picker-default {
        .ant-time-picker-input {
            height: 40px;
        }
    }

    .ant-time-picker-large {
        .ant-time-picker-input {
            height: 50px;
        }
    }

    .ant-time-picker-small {
        .ant-time-picker-input {
            height: 25px;
        }
    }

    .ant-time-picker-icon {
        margin-top: -8px !important;
    }

    @include themify($themes) {
        .ant-calendar-picker {
            .ant-calendar-picker-icon {
                &:after {
                    color: themed("inputColor");
                }
            }

            .ant-calendar-picker-clear {
                right: 20px;
                color: themed("inputColor");
                background: themed("inputBackgroundColor");
            }
        }
    }
}

.timePickerStyles {
    &.textalignRight {
        :global {
            .ant-picker-input {
                input {
                    text-align: right !important;
                }
            }
        }
    }
    &.textalignLeft {
        :global {
            .ant-picker-input {
                input {
                    text-align: left !important;
                }
            }
        }
    }
    &.textalignCenter {
        :global {
            .ant-picker-input {
                input {
                    text-align: center !important;
                }
            }
        }
    }
}
