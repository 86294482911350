.htmlTextBoxStyles {
    background-color: #fff;
    &.textalignRight {
        :global {
            .fr-box {
                .fr-wrapper {
                    .fr-element {
                        p {
                            text-align: right !important;
                        }
                    }
                    span {
                        text-align: right !important;
                    }
                }
            }
        }
    }
    &.textalignLeft {
        :global {
            .fr-box {
                .fr-wrapper {
                    .fr-element {
                        p {
                            text-align: left !important;
                        }
                    }
                    span {
                        text-align: left !important;
                    }
                }
            }
        }
    }
    &.textalignCenter {
        :global {
            .fr-box {
                .fr-wrapper {
                    .fr-element {
                        p {
                            text-align: center !important;
                        }
                    }
                    span {
                        text-align: center !important;
                    }
                }
            }
        }
    }
    :global {
        .fr-tribute,
        .tributeHighlight {
            color: cornflowerblue;
            cursor: pointer;
        }

        .fr-box {
            .fr-wrapper {
                .fr-element {
                    user-select: text !important;
                }
            }
        }
        .fr-toolbar {
            background: unset !important;
        }
        .fr-toolbar.fr-top {
            border-radius: 2px 2px 0 0;
        }
        .fr-second-toolbar {
            border-radius: 0 0 2px 2px;
        }
        .fr-command.fr-btn {
            height: 24px;

            svg {
                margin: 3px 2px;
                width: 16px;
                height: 16px !important;
            }
            img {
                margin: 3px 2px;
                width: 16px;
                height: 16px !important;
            }
        }
        .fr-counter {
            margin-bottom: 0 !important;
        }
        .fr-btn-grp.fr-float-left {
            float: none;
        }
        .fr-more-toolbar.fr-expanded {
            height: unset !important;
            display: flex;
            flex-wrap: wrap;
        }
        .fr-wrapper {
            border-radius: 2px 2px 0 0 !important;
        }
        .fr-bottom {
            padding-bottom: 0 !important;
            border-radius: 0 0 2px 2px !important;
            .fr-dropdown-menu {
                bottom: 105px !important;
            }
            .fr-btn-wrap {
                .fr-dropdown-menu {
                    bottom: 35px !important;
                }
            }
            .fr-newline {
                padding-top: 0 !important;
            }
        }
        .fr-top {
            .fr-dropdown-menu {
                transform: translateY(5px);
            }
            .fr-toolbar-open {
                padding-bottom: 0;
            }
        }

        .fr-popup {
            left: unset !important;
        }
        .fr-view {
            h1 {
                display: block;
                font-size: 2em;
            }
        }
        .fr-fullscreen {
            background-color: #fff;
        }
    }
}
