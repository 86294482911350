/// <reference path="../../stylesheets/main.scss"/>
@import "mainstyles";

.bcIcon {
    width: 100%;
    height: 100%;
    display: inline-block;

    @include themify($themes) {
        color: var(--icon-secondary-color);
    }

    .iconContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        svg {
            width: 100%;
            height: 100%;
            fill: currentColor;
            margin: auto;
            padding: 0;
            flex: 1;
        }
    }

    &.i8 {
        svg {
            width: 8px !important;
            height: 8px !important;
            min-width: 8px !important;
            max-width: 8px !important;
            min-height: 8px !important;
            max-height: 8px !important;
        }
    }
    &.i16 {
        svg {
            width: 16px !important;
            height: 16px !important;
            min-width: 16px !important;
            max-width: 16px !important;
            min-height: 16px !important;
            max-height: 16px !important;
        }
    }
    &.i24 {
        svg {
            width: 24px !important;
            height: 24px !important;
            min-width: 24px !important;
            max-width: 24px !important;
            min-height: 24px !important;
            max-height: 24px !important;
        }
    }
    &.i32 {
        svg {
            width: 32px !important;
            height: 32px !important;
            min-width: 32px !important;
            max-width: 32px !important;
            min-height: 32px !important;
            max-height: 32px !important;
        }
    }
    &.i48 {
        svg {
            width: 48px !important;
            height: 48px !important;
            min-width: 48px !important;
            max-width: 48px !important;
            min-height: 48px !important;
            max-height: 48px !important;
        }
    }
    &.i64 {
        svg {
            width: 64px !important;
            height: 64px !important;
            min-width: 64px !important;
            max-width: 64px !important;
            min-height: 64px !important;
            max-height: 64px !important;
        }
    }
}
