///<reference path="../../stylesheets/main" />
@import "mainstyles";

.colorPickerInput {
    :global {
        .ant-input-affix-wrapper {
            padding: 4px 5px;
        }
    }
}

.colorPickerPreview {
    border: solid 1px #9ea0a1;
    width: 21px;
    height: 21px;
    cursor: pointer;
}

.colorPickerIcon {
    cursor: pointer;
}

.colorClearBtn {
    width: 14px;
    height: 21px;
    cursor: pointer;
    transition: opacity 200ms ease;

    &:hover {
        opacity: 0.6;
    }
}

.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

:global {
    .sketch-picker {
        div.flexbox-fix:last-child {
            > div {
                width: 20px !important;
                height: 20px !important;
                span {
                    div {
                        border-radius: 0 !important;
                    }
                }
            }
        }
    }
    .picker-container.only-preset {
        .sketch-picker {
            padding: 0px 10px !important;
        }
        .sketch-picker > div {
            display: none !important;
        }
        .sketch-picker > div:last-child {
            display: flex !important;
            border-top: none !important;
        }
    }
}

.presetColors {
    :global {
        .sketch-picker div:not(:last-child) {
            display: none;
        }
    }
}
