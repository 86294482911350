.body {
  display: flex;
  height: 100%;
}

.component {
  min-height: 1px;

  &.small {
    padding: 1px 0;
  }

  &.middle {
    padding: 2.5px 0;
  }

  &.large {
    padding: 5px 0;
  }

  &.isInputGroup {
    padding: 0;
  }
}

.column {
  flex: 1 1 100%;
  padding: 10px;
  min-width: 0; // This was a trick that stops flexbox overflowing after 2 days hopeless research
}

.columns {
  display: flex;
  padding: 10px 0;

  &.isInputGroup {
    padding: 0;
  }

  &.extraSmall {
    padding: 5px 10px;
    flex-direction: column;
    row-gap: 5px;
  }
}

.base {
  padding: 0 10px;

  &.isInputGroup {
    padding: 0;

    &:not(:last-child) {
      padding-right: 5px;
    }
  }
}

.section {
  padding: 0;
}

.pageContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;

  .page {
    flex: 1 1 auto;
    padding: 15px;
    border-radius: 4px;

    &.isInputGroup {
      padding: 0;
    }
  }
}

.section,
.column,
.component {
  position: relative;
  opacity: 1;

  &:is(:empty) {
    display: none;
  }
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
  }

  .body {
    height: auto !important;
  }
}
