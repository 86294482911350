.modalContentWrapperMobilSignature {
    div:nth-of-type(2) {
        div:nth-of-type(1) {
            :global {
                .ant-select-dropdown {
                    min-width: 300px !important;
                }
            }
        }
    }
}

.MobileSignContainer {
    display: flex;
    flex-direction: column;
    height: 250px;
    justify-content: space-evenly;

    .mobileLoginBtn {
        margin-top: 10px;
    }

    .optionItem {
        display: flex;
        padding: 5px 0;
        height: 40px;
        line-height: 40px;
        .optionLabel {
            flex: 3;
            height: 40px;
        }
        .optionContent {
            flex: 6;
            height: 40px;
            :global {
                .ant-select {
                    width: 100%;
                }
            }
        }
    }

    .fingerPrint {
        height: 50px;
        line-height: 25px;
    }
}
