/// <reference path="../../stylesheets/main.scss"/>
@import 'mainstyles';

.label {
    display: inline-block;
    font-size: 12px;
    float: left;
    width: 100%;
    color: var(--primary-color-6);
    font-weight: bold;

    &.left {
        text-align: left;
    }

    &.center {
        text-align: center;
    }

    &.right {
        text-align: right;
    }

    &.ellipsis {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
