.SplitPane {
    position: initial !important;
}

.ResizerHorizontal {
    background: #000;
    opacity: .2;
    z-index: 1;
    height: 15px !important;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
    height: 15px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    cursor: row-resize;
    width: 100%;
}

.Resizer {
    &:global(.horizontal) {
        border-bottom: 6px solid rgba(200, 200, 200, 0.2);
        cursor: row-resize;
        width: 100%;
        &:hover {

        }
    }

    &:global(.vertical) {
        border-right: 6px solid rgba(200, 200, 200, 0.2);
        cursor: col-resize;
        height: 100%;
        &:hover {

        }
    }
}
